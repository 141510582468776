<template>
  <section
    :class="$style.container"
    :style="sectionStyle"
    data-luko-tracking="GivebackSchema"
  >
    <Title
      :class="$style.black_title"
      :title-params="primary.title"
      is-extra-title
    />
    <RichText
      :class="$style.sub_description"
      :text-params="primary.subDescription"
    />
    <RichText
      v-if="primary.topDescription && primary.topDescription.length"
      :class="$style.top_description"
      :text-params="primary.topDescription"
    />
    <template v-if="isAnimated">
      <ul :class="[[$style.medium], { [$style.is_playing]: isPlaying }]">
        <li>
          <div :class="[$style.first_description, $style.text_container]">
            <RichText
              :text-params="primary['70Description']"
              :class="$style.description"
            />
            <img src="/images/giveback/line-70.png" :class="$style.line" />
          </div>
        </li>
        <li>
          <video
            v-if="primary.animationVideo"
            ref="video-giveback"
            :class="$style.video"
            muted="muted"
          >
            <source :src="primary.animationVideo.url" type="video/mp4" />
          </video>
        </li>
        <li>
          <div
            v-observe-visibility="{
              callback: visibilityChanged,
              once: false,
            }"
            :class="$style.text_container"
          >
            <div :class="$style.second_description">
              <RichText
                :text-params="primary['30Description']"
                :class="$style.description"
              />
              <img src="/images/giveback/line-30.png" :class="$style.line" />
            </div>
            <div :class="$style.third_description">
              <RichText
                :text-params="primary.remainingDescription"
                :class="$style.description"
              />
              <img
                src="/images/giveback/line-remaining.png"
                :class="$style.line"
              />
            </div>
          </div>
        </li>
      </ul>
    </template>

    <ul :class="$style.small">
      <li>
        <RichText :text-params="primary['30Description']" />
      </li>
      <li>
        <RichText :text-params="primary['70Description']" />
      </li>
      <li>
        <RichText :text-params="primary.remainingDescription" />
      </li>
    </ul>
    <LkButton
      v-if="primary.ctaLink && primary.ctaLink.url"
      is-success
      :href="getFormatedLink(primary.ctaLink.url)"
    >
      {{ primary.ctaText }}
    </LkButton>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'GivebackSchema',
  components: {
    RichText,
    Title,
  },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      isPlaying: false,
    }
  },
  computed: {
    sectionStyle() {
      const backgroundImage = this.primary?.backgroundImage?.url
        ? `background-image:url(${this.primary?.backgroundImage?.url})`
        : ''

      const backgroundColor = this.primary.backgroundColor
        ? `background-color: ${this.primary.backgroundColor};`
        : ''
      return `${backgroundColor} ${backgroundImage}`
    },
    isAnimated() {
      return !!this.primary.animationVideo
    },
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$refs['video-giveback'].currentTime = 0
        this.$refs['video-giveback'].play()
        this.isPlaying = true
      } else {
        this.isPlaying = false
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 80px 0 0;
  padding: 0 20px 80px 20px;

  background: linear-gradient(180deg, rgba(235, 245, 243, 0) 15%, #ebf5f3 100%);

  background-repeat: no-repeat;

  background-position: bottom;
  background-size: contain;
}

.black_title {
  @include below(small) {
    text-align: center;
  }
}

.sub_description {
  @include typo-body;
  max-width: 840px;
  margin-bottom: 96px;

  text-align: center;

  p {
    margin-top: 16px;
    margin-bottom: 20px;
  }
}

.top_description {
  @include typo-headline;
  color: $mint-700;
  text-align: center;

  p {
    line-height: 32px;
  }

  strong {
    color: $mint-900;
  }

  @include below(medium) {
    display: none;
  }
}

ul.medium {
  display: flex;
  max-width: 1280px;
  min-height: 400px;

  li:nth-child(2) {
    width: 400px;
  }
  li:nth-child(1),
  li:nth-child(3) {
    width: 25vw;
  }

  @include below(medium) {
    display: none;
  }
}

.text_container {
  position: relative;

  height: 100%;
}

.description,
.line {
  transform: translate(0, 80px);
  opacity: 0;
}

.description {
  color: $mint-700;
  strong {
    color: $mint-900;
  }
}

.first_description {
  text-align: right;

  .description {
    position: absolute;
    right: 30px;
    bottom: 28%;
  }
  .line {
    position: absolute;
    bottom: 13%;
  }
}

.second_description {
  .description {
    position: absolute;
    bottom: 50%;
    left: 30px;

    transform: translate(0, 80px);
  }
  .line {
    position: absolute;
    bottom: 50%;
  }
}

.third_description {
  .description {
    position: absolute;
    bottom: 13%;
    left: 30px;
  }
  .line {
    position: absolute;
    bottom: 15%;
  }
}

ul.small {
  display: flex;
  flex-direction: column;

  li {
    max-width: 323px;
    margin: 0;
    padding: 16px 0 16px 16px;
    border-left: 4px solid $mint-300;
    &:nth-child(2) {
      border-left: 4px solid $mint-200;
    }
    &:nth-child(3) {
      border-left: 4px solid $mint-100;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }
  @include above(medium) {
    display: none;
  }
}

.is_playing {
  .description,
  .line {
    transform: translate(0, 0);
    opacity: 1;
  }

  .first_description {
    .description,
    .line {
      transition: opacity 0.8s 2.9s, transform 0.8s 2.9s;
    }
  }

  .second_description {
    .description,
    .line {
      transition: opacity 0.8s 1.3s, transform 0.8s 1.3s;
    }
  }
  .third_description {
    .description,
    .line {
      transition: opacity 0.8s 4.5s, transform 0.8s 4.5s;
    }
  }
}

.description,
.line {
  transition: opacity 0s 0s, transform 0s 0s;
}

.video {
  width: 100%;
}
</style>
