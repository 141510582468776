<template>
  <form
    :class="[[$style.container], { [$style.isDark]: isDark }]"
    data-luko-tracking="AddressForm"
  >
    <AddressBar
      :class="$style.addressInput"
      :text-value="prospectHomeAddress"
      :gmap-value="prospectHomeAddress"
      :placeholder="placeholder"
      :is-required="isRequired"
      @input="onAddressInput(...arguments)"
    />
    <LkButton
      :class="$style.addressSubmit"
      :is-light="isLight"
      @click="submitAddress"
    >
      {{ buttonText }}
    </LkButton>
  </form>
</template>

<script>
import AddressBar from '~/components/AddressBar'
import { prepare_address, prepare_partial_address } from '~/utils/address'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'

export default {
  name: 'AddressForm',

  components: {
    AddressBar,
  },

  mixins: [getOnboardingLink],

  props: {
    buttonText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    queryParams: {
      type: Array,
      default: () => [],
    },
    isLight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      address: null,
      postcode: null,
      city: null,
      country: null,
      address_obj: null,
    }
  },

  computed: {
    prospectHomeAddress() {
      return prepare_address(
        this.address,
        this.postcode,
        this.city,
        this.country
      )
    },

    isAddressValid() {
      return (
        this.address_obj &&
        this.address_obj.route &&
        this.address_obj.postalCode &&
        this.address_obj.postalCode.length === 5 &&
        this.address_obj.city &&
        this.address_obj.formattedAddress &&
        this.address_obj.country &&
        ['FR', 'ES', 'DE'].includes(this.address_obj.countryCode)
      )
    },

    isMri() {
      return !!this.$store.state.navCta.url?.includes('https://mri')
    },
  },

  mounted() {
    if (window && window.storage) {
      const storedValue = window.storage.get('address')
      if (!storedValue) return
      if (storedValue.country !== this.$store.state.currentDomain.toUpperCase())
        return window.storage.remove('address')

      this.address_obj = storedValue
      this.address = prepare_partial_address(
        storedValue.streetNumber,
        storedValue.route
      )
      this.postcode = storedValue.postalCode
      this.city = storedValue.city
      this.country = storedValue.country
    }
  },

  methods: {
    onAddressInput(value, isInitial) {
      if (value && !isInitial) {
        this.address_obj = value

        if (this.isAddressValid) {
          this.address = prepare_partial_address(
            value.streetNumber,
            value.route
          )
          this.postcode = value.postalCode
          this.city = value.city
          this.country = value.country
          this.countryCode = value.countryCode

          this.setAddress(value)

          return (window.location.href = this.getOnboardingLink(
            {
              address_main: this.address,
              city: this.city,
              country: this.country,
              country_code: this.countryCode,
              postcode: this.postcode,
              address_latitude: value.lat,
              address_longitude: value.lon,
            },
            { queryParams: this.queryParams, type: this.isMri ? 'mri' : 'home' }
          ))
        }
      } else {
        this.setAddress(value)
      }
    },

    setAddress(address) {
      if (address) {
        window.storage.set('address', address)
      } else {
        window.storage.remove('address')
      }
    },

    submitAddress(event) {
      event.preventDefault()
      this.setAddress(this.address_obj)

      window.location.href = this.getOnboardingLink(
        this.isAddressValid
          ? {
              address_main: this.address,
              city: this.city,
              country: this.country,
              country_code: this.countryCode,
              postcode: this.postcode,
            }
          : null,
        { queryParams: this.queryParams, type: this.isMri ? 'mri' : 'home' }
      )
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include above(small) {
    flex-direction: row;
  }

  .addressSubmit {
    margin-top: 10px;

    @include above(small) {
      margin-top: 0;
      margin-left: 8px;
    }
  }

  .addressInput {
    flex-grow: 1;
  }
}

.isDark {
  .addressInput {
    border-color: $bluko-600;

    color: white;

    background-color: $bluko-700;

    &::placeholder {
      color: white;
    }

    &:hover {
      border-color: $bluko-300;
    }

    &:focus {
      border-color: $bluko-300;

      box-shadow: 0 0 0 4px $bluko-500;
    }
  }
}

.addressSubmit {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;

  color: #fff;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  background-color: $bluko-500;
  cursor: pointer;
}
</style>
