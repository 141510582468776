// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsLetterDefault__container___2SEna{margin:80px 0 0;padding:24px 32px;border:1px solid #2d50e6;border-radius:8px}.NewsLetterDefault__title___dXeiI{color:inherit;font-weight:500;color:#2c2302;font-size:20px;line-height:32px}.NewsLetterDefault__description___1DPQD{color:inherit;font-weight:400;font-size:16px;line-height:24px;margin-top:4px;margin-bottom:24px;color:#6b6651}.NewsLetterDefault__form___3Caxu{display:flex;grid-gap:12px;gap:12px;align-items:flex-start}@media (max-width:719px){.NewsLetterDefault__form___3Caxu{flex-direction:column;align-items:unset}}.NewsLetterDefault__button___VigRN{flex-shrink:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "NewsLetterDefault__container___2SEna",
	"title": "NewsLetterDefault__title___dXeiI",
	"description": "NewsLetterDefault__description___1DPQD",
	"form": "NewsLetterDefault__form___3Caxu",
	"button": "NewsLetterDefault__button___VigRN"
};
module.exports = ___CSS_LOADER_EXPORT___;
