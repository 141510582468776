// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileThumbnail__container___1mZ_Y{display:inline-flex;align-items:center;justify-content:center;background-color:#2d50e6}.ProfileThumbnail__container___1mZ_Y,.ProfileThumbnail__image___1PWiN{border-radius:50% 50%}.ProfileThumbnail__image___1PWiN{width:100%;height:100%}.ProfileThumbnail__initial___3g4ne{color:#fff;font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "ProfileThumbnail__container___1mZ_Y",
	"image": "ProfileThumbnail__image___1PWiN",
	"initial": "ProfileThumbnail__initial___3g4ne"
};
module.exports = ___CSS_LOADER_EXPORT___;
