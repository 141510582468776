<template>
  <img
    :src="imageSrc"
    :class="[$style.default, { [$style.inlineSmall]: inlineSmall }]"
  />
</template>

<script>
export default {
  name: 'TrustpilotStars',

  props: {
    inlineSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    imageSrc() {
      if (this.inlineSmall)
        return require('~/assets/images/trustpilot-inline-small.png')
      return require('~/assets/images/trustpilot-inline-small.png')
    },
  },
}
</script>

<style lang="scss" module>
.default {
  width: 134px;
  height: 24px;
}
.inlineSmall {
  width: 134px;
  height: 24px;
}
</style>
