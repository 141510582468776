// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HighlightSectionDefault__container___3OtU7{padding:132px 32px 160px}@media (max-width:719px){.HighlightSectionDefault__container___3OtU7{padding:80px 16px}}.HighlightSectionDefault__title___2_pWB p{max-width:703px;margin:0 auto;font-weight:200;font-size:48px;line-height:58px;text-align:center}@media (max-width:719px){.HighlightSectionDefault__title___2_pWB p{font-size:32px;line-height:42px}}.HighlightSectionDefault__description___3qe0k{max-width:703px;margin:8px auto 0;text-align:center}.HighlightSectionDefault__itemsContainer___1gpa1{display:flex;flex-wrap:wrap;grid-gap:50px;gap:50px;justify-content:space-evenly;margin-top:64px}.HighlightSectionDefault__item___1Tw7k{flex:1;min-width:220px;max-width:400px;text-align:center}.HighlightSectionDefault__itemLink___3tZQe{display:inline-block;margin-top:24px}.HighlightSectionDefault__icon___3jvKN{width:56px;height:56px;margin-bottom:16px}.HighlightSectionDefault__itemTitle___3eTxi p{color:inherit;font-weight:500;font-size:28px;line-height:40px;margin-bottom:8px}@media (max-width:719px){.HighlightSectionDefault__itemTitle___3eTxi p{color:inherit;font-weight:500;font-size:20px;line-height:32px}}.HighlightSectionDefault__itemDescription___2Po3M p{color:#6b6651}.HighlightSectionDefault__tpContainer___NT6uB{margin-bottom:24px;text-align:center}@media (max-width:719px){.HighlightSectionDefault__tpContainer___NT6uB{display:block}}.HighlightSectionDefault__tpLogo___2TxzM{margin-right:20px}@media (max-width:719px){.HighlightSectionDefault__tpLogo___2TxzM{display:block;margin:0 auto 10px}}.HighlightSectionDefault__tpScore___1BOnK{display:inline}.HighlightSectionDefault__ctaContainer___1ioCR{margin-top:60px;text-align:center}@media (max-width:719px){.HighlightSectionDefault__cta___30SSn{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HighlightSectionDefault__container___3OtU7",
	"title": "HighlightSectionDefault__title___2_pWB",
	"description": "HighlightSectionDefault__description___3qe0k",
	"itemsContainer": "HighlightSectionDefault__itemsContainer___1gpa1",
	"item": "HighlightSectionDefault__item___1Tw7k",
	"itemLink": "HighlightSectionDefault__itemLink___3tZQe",
	"icon": "HighlightSectionDefault__icon___3jvKN",
	"itemTitle": "HighlightSectionDefault__itemTitle___3eTxi",
	"itemDescription": "HighlightSectionDefault__itemDescription___2Po3M",
	"tpContainer": "HighlightSectionDefault__tpContainer___NT6uB",
	"tpLogo": "HighlightSectionDefault__tpLogo___2TxzM",
	"tpScore": "HighlightSectionDefault__tpScore___1BOnK",
	"ctaContainer": "HighlightSectionDefault__ctaContainer___1ioCR",
	"cta": "HighlightSectionDefault__cta___30SSn"
};
module.exports = ___CSS_LOADER_EXPORT___;
