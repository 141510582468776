// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddressForm__container___V1EHK{display:flex;flex-direction:column;width:100%}@media (min-width:720px){.AddressForm__container___V1EHK{flex-direction:row}}.AddressForm__container___V1EHK .AddressForm__addressSubmit___2kcyI{margin-top:10px}@media (min-width:720px){.AddressForm__container___V1EHK .AddressForm__addressSubmit___2kcyI{margin-top:0;margin-left:8px}}.AddressForm__container___V1EHK .AddressForm__addressInput___LHWHu{flex-grow:1}.AddressForm__isDark___3KEOz .AddressForm__addressInput___LHWHu{border-color:#2441c5;color:#fff;background-color:#1b31a4}.AddressForm__isDark___3KEOz .AddressForm__addressInput___LHWHu::-moz-placeholder{color:#fff}.AddressForm__isDark___3KEOz .AddressForm__addressInput___LHWHu:-ms-input-placeholder{color:#fff}.AddressForm__isDark___3KEOz .AddressForm__addressInput___LHWHu::placeholder{color:#fff}.AddressForm__isDark___3KEOz .AddressForm__addressInput___LHWHu:hover{border-color:#829af2}.AddressForm__isDark___3KEOz .AddressForm__addressInput___LHWHu:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.AddressForm__addressSubmit___2kcyI{padding:12px 24px;border:none;border-radius:8px;color:#fff;font-size:16px;line-height:24px;text-decoration:none;background-color:#2d50e6;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "AddressForm__container___V1EHK",
	"addressSubmit": "AddressForm__addressSubmit___2kcyI",
	"addressInput": "AddressForm__addressInput___LHWHu",
	"isDark": "AddressForm__isDark___3KEOz"
};
module.exports = ___CSS_LOADER_EXPORT___;
