// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProcessArrowDefault__container___2w-0t{padding:128px 0 24px}.ProcessArrowDefault__inner___-lSWS{max-width:1280px;text-align:center}.ProcessArrowDefault__title___3zqhM{font-weight:200;font-size:48px;line-height:64px}.ProcessArrowDefault__description___9Cwvb p{color:inherit;font-weight:400;font-size:16px;line-height:24px;color:#6b6651}.ProcessArrowDefault__description___9Cwvb h3{color:inherit;font-weight:500;font-size:20px;line-height:32px;margin-bottom:8px}.ProcessArrowDefault__items___29czk{display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:60px}@media (min-width:1280px){.ProcessArrowDefault__items___29czk{flex-direction:row}}.ProcessArrowDefault__ctaButton___1pKzj{display:none;text-decoration:none}@media (min-width:1280px){.ProcessArrowDefault__ctaButton___1pKzj{display:inline-block;margin-top:48px}}.ProcessArrowDefault__item___1d1K4{max-width:320px;min-height:230px;margin:24px;padding:24px 32px;border:1px solid #eae9e3;border-radius:8px}.ProcessArrowDefault__icon___2Sr3B{margin-bottom:16px}.ProcessArrowDefault__itemTitle___1gpqX{color:inherit;font-weight:500;font-size:20px;line-height:32px;margin:0}.ProcessArrowDefault__link___FkB3R{display:flex;justify-content:center;margin-top:24px}.ProcessArrowDefault__link___FkB3R a{max-width:222px;text-align:left}.ProcessArrowDefault__arrow___2kAQc{margin:10px;transform:rotate(90deg)}@media (min-width:1280px){.ProcessArrowDefault__arrow___2kAQc{transform:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "ProcessArrowDefault__container___2w-0t",
	"inner": "ProcessArrowDefault__inner___-lSWS",
	"title": "ProcessArrowDefault__title___3zqhM",
	"description": "ProcessArrowDefault__description___9Cwvb",
	"items": "ProcessArrowDefault__items___29czk",
	"ctaButton": "ProcessArrowDefault__ctaButton___1pKzj",
	"item": "ProcessArrowDefault__item___1d1K4",
	"icon": "ProcessArrowDefault__icon___2Sr3B",
	"itemTitle": "ProcessArrowDefault__itemTitle___1gpqX",
	"link": "ProcessArrowDefault__link___FkB3R",
	"arrow": "ProcessArrowDefault__arrow___2kAQc"
};
module.exports = ___CSS_LOADER_EXPORT___;
