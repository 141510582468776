<template>
  <section :class="$style.container">
    <div :class="$style.inner">
      <PImage v-if="primary.logo" :image-object="primary.logo" :class="$style.logo" :style="logoStyle" />
      <figure :class="$style.figure">
        <RichText v-if="primary.text" :class="$style.text" :text-params="primary.text" />
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SubHeroBanner',

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
  computed: {
    logoStyle() {
      const logoSize = this.primary.logoSize
      return logoSize ? { width: `${logoSize}px`, height: `${logoSize}px` } : {}
    },
  },
}
</script>

<style lang="scss" module>
.container {
  background: $bluko-800;
}

.inner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 24px;

  @include below(medium) {
    flex-direction: column;

    text-align: center;
  }
}

.logo {
  width: 118px;
  margin-right: 40px;

  @include below(medium) {
    width: calc(min(30vw, 84px));
    margin: 0;
    margin-bottom: 32px;
  }
}

.figure {
  max-width: 530px;
}

.text {
  h2 {
    @include typo-headline-bold;
    color: white;
  }

  p {
    margin-top: 10px;

    color: $bluko-100;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    @include below(medium) {
      color: $bluko-200;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
