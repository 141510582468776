// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:719px){.HeroHome2021Q4__desktop___-jrpd{display:none}}@media (min-width:720px){.HeroHome2021Q4__mobile___3JyUB{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"desktop": "HeroHome2021Q4__desktop___-jrpd",
	"mobile": "HeroHome2021Q4__mobile___3JyUB"
};
module.exports = ___CSS_LOADER_EXPORT___;
