<template>
  <section data-luko-tracking="NewsLetter">
    <NewsLetterDefault :slice="slice" />
  </section>
</template>

<script>
import NewsLetterDefault from './default-slice'

export default {
  name: 'NewsLetter',
  components: { NewsLetterDefault },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
