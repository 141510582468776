<template>
  <div data-luko-tracking="CounterBanner">
    <CounterBannerDefault :slice="slice" />
  </div>
</template>

<script>
import CounterBannerDefault from './default-slice'

export default {
  name: 'CounterBanner',
  components: {
    CounterBannerDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
