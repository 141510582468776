<template>
  <section v-observe-visibility="isVisible" :class="$style.heroContainer">
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Breadcrumb v-if="primary.showBreadcrumb" :class="$style.breadcrumb" :is-dark="isDark" :custom-name="primary.title[0].text" />
        <div :class="$style.titleContainer">
          <Title :class="$style.title" :title-params="primary.title" />
          <span v-if="primary.tag" :class="$style.tag">{{ primary.tag }}</span>
        </div>
        <div v-if="primary.cobrandedLogo && primary.cobrandedLogo.url" :class="$style.cobranded">
          <p :class="$style.withText">
            {{ $t('with') }}
          </p>
          <PImage :image-object="primary.cobrandedLogo" />
        </div>
        <RichText :class="$style.description" :text-params="primary.description" />
        <CtaBox
          v-if="primary.cta_type !== 'None'"
          :class="$style.ctaContainer"
          :placeholder="primary.inputPlaceholder"
          :type="primary.ctaType"
          :text="primary.ctaText"
          :link="primary.ctaLink"
          :to-onboarding="primary.ctaToOnboarding"
          :apps="apps"
        />
        <RichText :class="$style.subDescription" :text-params="primary.subDescription" />
        <ul v-if="items.length" :class="$style.linkList">
          <li v-for="(item, linkIndex) in items" :key="linkIndex" :class="$style.linkItem">
            <PLink :link-object="item.link" is-highlight color="white">
              {{ item.text }}
            </PLink>
          </li>
        </ul>
      </div>
      <div :class="$style.viewContainer">
        <div :class="$style.frame">
          <PImage :class="[$style.desktopImage]" :image-object="primary.backgroundImage" sizes="sm:100vw" />
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import PImage from '~/components/PImage'
import Breadcrumb from '~/components/Breadcrumb'
import PLink from '~/components/PLink'

import { CtaBox } from './components'

export default {
  name: 'HeroLaunchDarkblue',

  components: {
    PImage,
    CtaBox,
    Breadcrumb,
    PLink,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items.filter((item) => item.text),
    }
  },

  computed: {
    ctaTarget() {
      return this.getFormatedLink(this.primary.ctaLink)
    },
    apps() {
      return [
        {
          image: this.primary.imageAppStore,
          link: this.primary.ctaAppStore,
        },
        {
          image: this.primary.imageGooglePlay,
          link: this.primary.ctaGooglePlay,
        },
      ]
    },
  },

  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  min-height: 615px;
  margin-top: -64px;
  padding: 46px 80px 0;
  padding-top: 64px;

  background-color: $bluko-800;

  @include below(medium) {
    padding: 24px;
    padding-top: 64px;
    overflow: hidden;
  }
}

.inner {
  position: relative;

  display: flex;
  gap: 30px;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  padding-top: 56px;

  @include below(medium) {
    flex-direction: column;
    gap: unset;
  }
  @include below(small) {
    padding-top: 24px;
  }
}

.desc {
  width: 50%;
  padding-bottom: 46px;
  @include below(medium) {
    width: 100%;
    max-width: none;
  }
}
.viewContainer {
  position: absolute;
  right: 0%;

  width: 45%;
  height: calc(100% - 55px);
  overflow-y: hidden;
  @include below(medium) {
    position: static;

    width: 100%;
    max-width: none;
    height: 340px;
    overflow-y: visible;
  }
}
.frame {
  width: 100%;
  height: 85%;
  min-height: 430px;
  margin: 0;
  border: 20px solid #2d50e6;
  border-bottom-left-radius: 300px;
  overflow-y: hidden;

  @include below(medium) {
    width: 100%;
    max-width: none;
    height: 300px;
    min-height: auto;
    margin-top: 16px;
    margin-bottom: -24px;
    border-bottom-left-radius: 150px;
  }
}
.desktopImage {
  position: absolute;
  bottom: 0;
  left: 50%;

  height: 450px;

  transform: translateX(-50%);

  @include below(medium) {
    bottom: -24px;

    height: 250px;
  }
}

.breadcrumb {
  margin-bottom: 16px;
}

.title {
  @include typo-mega-title;
  display: inline;
  h1 {
    display: inline;
    margin-top: 16px;

    color: white;
  }

  @include below(small) {
    font-size: 40px;
    line-height: 56px;
  }
}

.tag {
  display: inline-block;
  margin-top: 10px;

  padding: 8px 16px;
  border-radius: 20px;

  background-color: $gold-500;

  transform: translateY(-10px);
  @include typo-body;

  @include below(small) {
    display: none;
  }
}
.cobranded {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  margin-top: 16px;

  @include typo-headline;
}
.withText {
  @include typo-headline;
  color: $bluko-300;
}

.description p {
  @include typo-headline;
  margin-top: 16px;

  color: $bluko-200;
  line-height: 32px;
}

.ctaContainer {
  margin-top: 40px;
}
.subDescription {
  @include typo-body;
  margin-top: 16px;

  color: $bluko-300;
}

.linkList {
  margin-top: 40px;

  color: white;
}

.linkItem {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}
</style>
