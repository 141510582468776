// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleCtaWhite__container___37J95{padding:128px 32px;background-color:#fff}@media (max-width:719px){.SimpleCtaWhite__container___37J95{padding:80px 24px}}.SimpleCtaWhite__title___2uGsJ p{max-width:670px;margin:0 auto;font-weight:200;font-size:32px;line-height:42px;text-align:center}@media (max-width:719px){.SimpleCtaWhite__title___2uGsJ p{font-size:28px;line-height:34px}}.SimpleCtaWhite__description___25bmE{margin-top:15px}.SimpleCtaWhite__buttonContainer___gHFDX,.SimpleCtaWhite__description___25bmE{text-align:center}.SimpleCtaWhite__button___4H92W{margin:52px 0 0}@media (max-width:719px){.SimpleCtaWhite__button___4H92W{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "SimpleCtaWhite__container___37J95",
	"title": "SimpleCtaWhite__title___2uGsJ",
	"description": "SimpleCtaWhite__description___25bmE",
	"buttonContainer": "SimpleCtaWhite__buttonContainer___gHFDX",
	"button": "SimpleCtaWhite__button___4H92W"
};
module.exports = ___CSS_LOADER_EXPORT___;
