<template>
  <section :class="$style.container" data-luko-tracking="MediaCarrousel">
    <Wrapper
      v-observe-visibility="{
        callback: sectionOnScreen,
        intersection: {
          threshold: 0.8,
        },
      }"
      :class="$style.inner"
    >
      <transition-group name="cross" :class="$style.imageContainer" tag="div">
        <PImage
          :key="'front' + currentIndex + currentItem.url"
          :image-object="currentItem.imageFront"
          :class="$style.imageFront"
          class="front"
          sizes="xs:600px"
        />
        <PImage
          :key="'back' + currentIndex + currentItem.url"
          :image-object="currentItem.imageBack"
          :class="$style.imageBack"
          class="back"
          sizes="xs:100px"
        />
      </transition-group>
      <div :class="$style.descriptionContainer">
        <Title :class="$style.title" :title-params="primary.title" />
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="$style.item"
          @click="onClickItem(index)"
        >
          <LinearProgressBar
            :class="$style.progressBar"
            :callback="afterCount"
            :is-active="index === currentIndex && (isScrollDown || isVisible)"
          />
          <RichText
            :text-params="item.description"
            :class="[
              $style.description,
              {
                [$style.active]:
                  currentIndex === index && (isScrollDown || isVisible),
              },
            ]"
          />
        </div>
      </div>
    </Wrapper>
    <Wrapper
      v-if="primary.ctaText && primary.ctaLink"
      :class="$style.ctaContainer"
    >
      <ArrowButton
        v-if="primary.ctaLink"
        :href="getFormatedLink(primary.ctaLink)"
        :target="primary.ctaLink.target"
        is-outlined
      >
        {{ primary.ctaText }}
      </ArrowButton>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import LinearProgressBar from '~/components/LinearProgressBar'
import ArrowButton from '~/components/ArrowButton'

export default {
  name: 'MediaCarouselDesktop',
  components: {
    Title,
    RichText,
    LinearProgressBar,
    ArrowButton,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items.filter(
        (item) => item.imageFront.url && item.imageBack.url
      ),
      currentIndex: 0,
      isVisible: false,
      isScrollDown: false,
    }
  },

  computed: {
    currentItem() {
      return this.items[this.currentIndex]
    },
  },

  methods: {
    afterCount() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length
    },
    onClickItem(index) {
      this.currentIndex = index
    },
    sectionOnScreen(isVisible, entry) {
      this.isVisible = isVisible
      const { boundingClientRect, intersectionRect } = entry
      const isUpper = !(boundingClientRect.y < intersectionRect.y)
      this.isScrollDown = isUpper === isVisible
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 128px 32px 160px;
}

.inner {
  display: flex;
}
.title {
  @include typo-mega-title;
  margin-bottom: 36px;

  font-weight: 200;
}

.imageContainer {
  position: relative;

  flex: 1;

  text-align: center;
}

.imageFront {
  position: absolute;
  bottom: 10%;
  left: 0;
  z-index: 2;

  width: calc(min(480px, 40vw));
  height: calc(min(480px, 40vw));
}
.imageBack {
  position: absolute;
  bottom: 10%;
  left: 0;

  width: calc(min(480px, 40vw));
  height: calc(min(480px, 40vw));
}

.descriptionContainer {
  flex: 1;
  max-width: 452px;
  margin-bottom: 80px;
}
.description {
  color: $gray-400;

  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
  }
  &.active {
    h3 {
      color: black;
    }
    p {
      color: $gray-700;
    }
  }
}

.item {
  display: flex;
  margin-bottom: 40px;

  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}
.progressBar {
  flex-shrink: 0;
  margin-right: 36px;
}

.ctaContainer {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
</style>
<style lang="scss" scoped>
/* slide left */
.cross-enter {
  opacity: 0;
  &.front {
    transform: translateX(-100px);
  }
  &.back {
    transform: translateX(100px);
  }
}
.cross-enter-to {
  transform: initial;
  opacity: 1;
}
.cross-leave {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.cross-leave-to {
  position: absolute;

  opacity: 0;
  &.front {
    transform: translateX(100px);
  }
  &.back {
    transform: translateX(-100px);
  }
}
.cross-enter-active {
  transition: all 1s cubic-bezier(0.07, 0.6, 0.47, 1.04);
  transition-delay: 0.7s;
}

.cross-leave-active {
  position: absolute;

  transition: all 1s cubic-bezier(0.07, 0.6, 0.47, 1.04);
}
</style>
