// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroDefault__container___2gKQ5{max-width:inherit;min-height:700px;margin-top:-64px;padding:0 24px;background-color:#fcf7e8;background-repeat:repeat-x;background-position:0 100%;background-size:2048px 594px}@media (max-width:959px){.HeroDefault__container___2gKQ5{background-position-x:-270px;background-size:1024px 287px}}@media (min-width:2000px){.HeroDefault__container___2gKQ5{background-position-x:500px;background-position-y:bottom}}@media (min-width:3000px){.HeroDefault__container___2gKQ5{background-position-x:1000px;background-position-y:bottom}}.HeroDefault__inner___iC3Op{display:flex;min-height:630px;padding-top:120px}@media (min-width:720px){.HeroDefault__inner___iC3Op{min-height:680px;padding-top:144px}}.HeroDefault__desc___c4s-2{max-width:720px;margin:0 auto}@media (min-width:720px){.HeroDefault__desc___c4s-2{margin:0}}.HeroDefault__title___1jEBm h1{max-width:725px;margin-bottom:16px;color:#2c2302;font-weight:500;font-size:32px;line-height:48px}@media (min-width:720px){.HeroDefault__title___1jEBm h1{font-size:52px;line-height:64px}}.HeroDefault__subtitle___1vONu{max-width:650px;margin:0;color:#564f34}.HeroDefault__subtitle___1vONu p{font-size:20px;line-height:32px}@media (max-width:719px){.HeroDefault__subtitle___1vONu p{font-size:16px;line-height:24px}}.HeroDefault__addressBarContainer___3Whj7{margin-top:56px}.HeroDefault__trustpilot___2O2rP{color:inherit;font-weight:400;font-size:14px;line-height:20px;display:flex;grid-gap:8px;gap:8px;margin-top:24px;color:#96948a}.HeroDefault__trustpilot___2O2rP strong{color:#6b6651}.HeroDefault__tag___3f_Ko{display:inline-block;margin-top:10px;padding:8px 16px;border-radius:20px;background-color:#e9b857;transform:translateY(-10px);color:inherit;font-weight:400;font-size:16px;line-height:24px}@media (max-width:719px){.HeroDefault__tag___3f_Ko{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HeroDefault__container___2gKQ5",
	"inner": "HeroDefault__inner___iC3Op",
	"desc": "HeroDefault__desc___c4s-2",
	"title": "HeroDefault__title___1jEBm",
	"subtitle": "HeroDefault__subtitle___1vONu",
	"addressBarContainer": "HeroDefault__addressBarContainer___3Whj7",
	"trustpilot": "HeroDefault__trustpilot___2O2rP",
	"tag": "HeroDefault__tag___3f_Ko"
};
module.exports = ___CSS_LOADER_EXPORT___;
