<template>
  <div :class="$style.container">
    <div :class="$style.titleContainer">
      <CheckIcon :class="$style.checkIcon" class="icon-white" />
      <Title :title-params="primary.title" :class="$style.title" />
    </div>
    <RichText :text-params="primary.description" :class="$style.description" />
    <div :class="$style.ctaContainer">
      <LkButton v-if="primary.buttonCtaText" is-small :class="$style.button" :href="getFormatedLink(primary.buttonCta)">
        {{ primary.buttonCtaText }}
      </LkButton>
      <div v-else></div>
      <ArrowLink v-if="primary.linkCtaText && primary.linkCta && primary.linkCta.url" :link-object="primary.linkCta" :class="$style.link">
        {{ primary.linkCtaText }}
      </ArrowLink>
      <div v-else></div>
    </div>
  </div>
</template>

<script>
import CheckIcon from 'LkIcons/Check.vue'

import LkButton from '~/components/LkButton'
import ArrowLink from '~/components/ArrowLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'LukofferCtaDefault',
  components: { LkButton, CheckIcon, ArrowLink },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  margin-top: 128px;
  padding: 24px 32px;
  border-radius: 8px;

  background-color: $bluko-800;
}
.titleContainer {
  display: flex;
}
.description {
  padding-bottom: 32px;

  color: white;
  ul {
    padding-top: 0;

    list-style: none;
  }
  ul li {
    padding-left: 32px;
  }
  ul li::before {
    padding-left: 12px;

    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.959519C-0.0976311 1.35004 -0.0976311 1.98321 0.292893 2.37373L3.91912 5.99996L0.292893 9.62619C-0.0976311 10.0167 -0.0976311 10.6499 0.292893 11.0404C0.683417 11.4309 1.31658 11.4309 1.70711 11.0404L6.04044 6.70707C6.43096 6.31654 6.43096 5.68338 6.04044 5.29285L1.70711 0.959519C1.31658 0.568995 0.683417 0.568995 0.292893 0.959519Z' fill='%23FCE1D2'/%3E%3C/svg%3E%0A");
  }
}
.checkIcon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  padding: 8px;
  border-radius: 50%;

  background-color: $bluko-500;
}
.title {
  @include typo-headline-bold;
  color: $orange-100;
}
.ctaContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include below(small) {
    flex-direction: column-reverse;
    gap: 20px;
    align-items: start;
  }
}
.link {
  color: $bluko-200;
  font-size: 14px;
  line-height: 20px;
  path {
    fill: $bluko-200;
  }
}
.button {
  @include below(small) {
    width: 100%;
  }
}
</style>
