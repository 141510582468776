// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroPartner2021Q4__heroContainer___1Y5bw{min-height:615px;margin-top:-64px;padding:0 80px;background-color:#fff4ee}@media (max-width:959px){.HeroPartner2021Q4__heroContainer___1Y5bw{padding:64px 24px 24px}}.HeroPartner2021Q4__inner___1avAn{display:flex;justify-content:space-between;height:100%;padding-top:128px}@media (max-width:959px){.HeroPartner2021Q4__inner___1avAn{flex-direction:column;padding-top:24px}}.HeroPartner2021Q4__desc___1nv6q{width:50%;max-width:600px}@media (max-width:959px){.HeroPartner2021Q4__desc___1nv6q{width:100%;max-width:none}}.HeroPartner2021Q4__view___3wBof{width:40%;max-width:500px;height:438px;margin:0;border-top-left-radius:50%;border-top-right-radius:50%;overflow:hidden}@media (max-width:959px){.HeroPartner2021Q4__view___3wBof{display:flex;justify-content:center;width:100%;max-width:unset;height:80vw;max-height:400px;margin:50px auto 0}}.HeroPartner2021Q4__breadcrumb___jG5qc{margin-bottom:16px}.HeroPartner2021Q4__title___2qraH{margin-top:16px;font-weight:200;font-size:48px;line-height:59px;-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto}.HeroPartner2021Q4__title___2qraH,.HeroPartner2021Q4__title___2qraH h1{display:inline}@media (max-width:719px){.HeroPartner2021Q4__title___2qraH{font-size:40px;line-height:56px}}.HeroPartner2021Q4__tag___B_KBt{display:inline-block;margin-top:10px;padding:8px 16px;border-radius:20px;background-color:#e9b857;transform:translateY(-10px);color:inherit;font-weight:400;font-size:16px;line-height:24px}@media (max-width:719px){.HeroPartner2021Q4__tag___B_KBt{display:none}}.HeroPartner2021Q4__subtitle___2XymR p{color:inherit;font-weight:400;font-size:20px;margin-top:16px;color:#6b6651;line-height:32px}.HeroPartner2021Q4__ctaContainer___35M9r{margin-top:40px}.HeroPartner2021Q4__image___2vf5C{display:block;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "HeroPartner2021Q4__heroContainer___1Y5bw",
	"inner": "HeroPartner2021Q4__inner___1avAn",
	"desc": "HeroPartner2021Q4__desc___1nv6q",
	"view": "HeroPartner2021Q4__view___3wBof",
	"breadcrumb": "HeroPartner2021Q4__breadcrumb___jG5qc",
	"title": "HeroPartner2021Q4__title___2qraH",
	"tag": "HeroPartner2021Q4__tag___B_KBt",
	"subtitle": "HeroPartner2021Q4__subtitle___2XymR",
	"ctaContainer": "HeroPartner2021Q4__ctaContainer___35M9r",
	"image": "HeroPartner2021Q4__image___2vf5C"
};
module.exports = ___CSS_LOADER_EXPORT___;
