<template>
  <section :class="$style.container">
    <Wrapper>
      <div v-if="primary.trustpilotLogo" :class="$style.tpContainer">
        <img :src="score.logoImage.lightBackground" :class="$style.tpLogo" />
        <TrustpilotScore
          :score="score.stars"
          color="#2A8C7C"
          :class="$style.tpScore"
          animation
        />
      </div>
      <Title :title-params="primary.title" :class="$style.title" />
      <RichText
        :text-params="primary.description"
        :class="$style.description"
      />
      <div :class="$style.itemsContainer">
        <div
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          :class="$style.item"
          data-aos="fade-up"
        >
          <PImage
            :image-object="item.icon"
            sizes="xs:100px"
            :class="$style.icon"
          />
          <Title :title-params="item.title" :class="$style.itemTitle" />
          <RichText
            :text-params="item.description"
            :class="$style.itemDescription"
          />
          <PLink
            v-if="item.ctaText && item.ctaLink && item.ctaLink.url"
            is-highlight
            color="bluko"
            :href="getFormatedLink(item.ctaLink.url)"
            :class="$style.itemLink"
          >
            {{ item.ctaText }}
          </PLink>
        </div>
      </div>
      <div v-if="primary.ctaText" :class="$style.ctaContainer">
        <ArrowButton
          v-if="primary.ctaLink && primary.ctaLink.url && primary.ctaText"
          :href="getFormatedLink(primary.ctaLink.url)"
          :class="$style.cta"
        >
          {{ primary.ctaText }}
        </ArrowButton>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import TrustpilotScore from '~/components/TrustpilotScore'
import PLink from '~/components/PLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import ArrowButton from '~/components/ArrowButton'

export default {
  name: 'HighlightSectionDarkblue',
  components: {
    TrustpilotScore,
    PLink,
    ArrowButton,
  },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      score,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 132px 32px 160px;

  background-color: $bluko-800;
  @include below(small) {
    padding: 80px 16px;
  }
}
.title p {
  max-width: 703px;
  margin: 0 auto;

  color: white;
  font-weight: 200;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  @include below(small) {
    font-size: 32px;
    line-height: 42px;
  }
}
.description {
  max-width: 703px;
  margin: 8px auto 0;

  color: #d6e3fd;
  text-align: center;
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-evenly;
  margin-top: 64px;
}
.item {
  flex: 1;
  min-width: 220px;
  max-width: 400px;

  text-align: center;
}
.itemTitle {
  p {
    @include typo-title;
    margin-bottom: 8px;

    color: white;
    @include below(small) {
      @include typo-headline-bold;
      color: white;
    }
  }
}

.itemLink {
  display: inline-block;
  margin-top: 24px;
}
.icon {
  width: 56px;
  height: 56px;
  margin-bottom: 16px;
}
.itemDescription {
  h3 {
    @include typo-title;
    margin-bottom: 8px;

    color: white;
    @include below(small) {
      @include typo-headline-bold;
      color: white;
    }
  }
  p {
    color: #d6e3fd;
  }
}
.tpContainer {
  margin-bottom: 24px;

  text-align: center;
  @include below(small) {
    display: block;
  }
}
.tpLogo {
  margin-right: 20px;
  @include below(small) {
    display: block;
    margin: 0 auto 10px;
  }
}
.tpScore {
  display: inline;
}
.ctaContainer {
  margin-top: 60px;

  text-align: center;
}
.cta {
  @include below(small) {
    width: 100%;
  }
}
</style>
