// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleCtaDefault__container___tiVfi{padding:128px 32px;background-color:#122182}@media (max-width:719px){.SimpleCtaDefault__container___tiVfi{padding:80px 24px}}.SimpleCtaDefault__title___3O8Ip p{max-width:670px;margin:0 auto;color:#fff;font-weight:200;font-size:32px;line-height:42px;text-align:center}@media (max-width:719px){.SimpleCtaDefault__title___3O8Ip p{font-size:28px;line-height:34px}}.SimpleCtaDefault__buttonContainer___9ukgB{text-align:center}.SimpleCtaDefault__button___11jol{margin:52px 0 0}@media (max-width:719px){.SimpleCtaDefault__button___11jol{width:100%}}.SimpleCtaDefault__description___2MBBO{margin-top:15px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "SimpleCtaDefault__container___tiVfi",
	"title": "SimpleCtaDefault__title___3O8Ip",
	"buttonContainer": "SimpleCtaDefault__buttonContainer___9ukgB",
	"button": "SimpleCtaDefault__button___11jol",
	"description": "SimpleCtaDefault__description___2MBBO"
};
module.exports = ___CSS_LOADER_EXPORT___;
