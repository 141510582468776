// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CounterBannerDefault__container___1F8nX{background-color:#122182}.CounterBannerDefault__inner___1rD8t{display:grid;grid-template-columns:1fr;align-items:start;justify-items:center;padding:40px 24px}@media (min-width:720px){.CounterBannerDefault__inner___1rD8t{grid-template-columns:repeat(2,1fr);grid-gap:40px;gap:40px;padding:56px 72px}}@media (min-width:960px){.CounterBannerDefault__inner___1rD8t{grid-template-columns:repeat(4,1fr)}}.CounterBannerDefault__article___36xGJ{width:100%;margin-bottom:40px;text-align:center}@media (min-width:720px){.CounterBannerDefault__article___36xGJ{max-width:254px;margin-bottom:0;text-align:left}}.CounterBannerDefault__article___36xGJ:last-child{margin-bottom:0}.CounterBannerDefault__counter___3tpAc{color:inherit;font-weight:500;font-size:40px;line-height:56px;color:#fff;text-align:center}.CounterBannerDefault__description___2--s5 p{color:inherit;font-weight:400;font-size:20px;line-height:32px;margin-top:8px;color:#d6e3fd;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CounterBannerDefault__container___1F8nX",
	"inner": "CounterBannerDefault__inner___1rD8t",
	"article": "CounterBannerDefault__article___36xGJ",
	"counter": "CounterBannerDefault__counter___3tpAc",
	"description": "CounterBannerDefault__description___2--s5"
};
module.exports = ___CSS_LOADER_EXPORT___;
