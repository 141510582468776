<template>
  <div>
    <HeroDesktop :slice="slice" :class="$style.desktop" />
    <HeroMobile :slice="slice" :class="$style.mobile" />
  </div>
</template>

<script>
import HeroDesktop from './components/HeroDesktop.vue'
import HeroMobile from './components/HeroMobile.vue'

export default {
  name: 'HeroHome2021Q4',

  components: {
    HeroDesktop,
    HeroMobile,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss" module>
.desktop {
  @include below(small) {
    display: none;
  }
}
.mobile {
  @include above(small) {
    display: none;
  }
}
</style>
