// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__emailContainer___2PdTJ{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___2PdTJ .CtaBox__button___3up-B{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___2PdTJ{flex-direction:column;max-width:300px}.CtaBox__emailContainer___2PdTJ .CtaBox__emailField___oo95I{width:100%}}.CtaBox__appsContainer___qhMKS{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___qhMKS{justify-content:center}}.CtaBox__appImage___2VCY6{max-width:152px;border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___2VCY6{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}@media (max-width:959px){.CtaBox__ctaButton___3W2Sb{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"emailContainer": "CtaBox__emailContainer___2PdTJ",
	"button": "CtaBox__button___3up-B",
	"emailField": "CtaBox__emailField___oo95I",
	"appsContainer": "CtaBox__appsContainer___qhMKS",
	"appImage": "CtaBox__appImage___2VCY6",
	"ctaButton": "CtaBox__ctaButton___3W2Sb"
};
module.exports = ___CSS_LOADER_EXPORT___;
