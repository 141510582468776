<template>
  <section v-observe-visibility="isVisible" :class="[[$style.heroContainer]]">
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Title :class="$style.title" :title-params="primary.title" />
        <RichText :class="$style.subtitle" :text-params="primary.subtitle" />
      </div>
      <div :class="$style.view">
        <PImage
          :class="$style.image"
          :image-object="primary.backgroundImage"
          sizes="sm:120vw"
        />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import PImage from '~/components/PImage'
export default {
  name: 'HeroConfirm',

  components: {
    PImage,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },

  computed: {
    ctaTarget() {
      return this.getFormatedLink(this.primary.ctaLink)
    },
  },

  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  min-height: 500px;
  margin-top: -64px;
  padding: 46px 80px;

  background-color: #fff4ee;

  @include below(medium) {
    padding: 24px;
    padding-top: 64px;
  }
}

.inner {
  display: flex;
  gap: 40px;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding-top: 56px;

  @include below(medium) {
    flex-direction: column;
  }
}

.desc {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 450px;
  @include below(medium) {
    width: 100%;
    min-height: auto;
  }
}

.view {
  flex: 1;
  margin: 0;
}

.breadcrumb {
  margin-bottom: 16px;
}

.title {
  @include typo-mega-title;
  display: inline;
  margin-top: 16px;

  font-weight: 200;
  line-height: 59px;
  text-align: left;

  @include below(small) {
    font-size: 40px;
    line-height: 56px;
  }
}

.subtitle p {
  @include typo-headline;
  margin-top: 16px;

  color: $gray-700;
  line-height: 32px;
}

.ctaContainer {
  margin-top: 40px;
  @include below(small) {
    width: 100%;
    a {
      width: 100%;
    }
  }
}

.image {
  display: block;
  width: 100%;

  @include below(medium) {
    height: 400px;
  }
  @include below(small) {
    height: 50vw;
  }
}
</style>
