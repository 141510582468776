<template>
  <section
    :class="$style.container"
    :style="sectionStyle"
    data-luko-tracking="GivebackSchema"
  >
    <Title :class="$style.title" :title-params="primary.title" is-extra-title />
    <RichText
      :class="$style.sub_description"
      :text-params="primary.subDescription"
    />
    <template v-if="isAnimated">
      <div
        :class="[[$style.medium], { [$style.is_playing]: isPlaying }]"
        data-aos="fade-up"
      >
        <aside
          v-observe-visibility="{
            callback: visibilityChanged,
            once: false,
          }"
          :class="$style.videoContainer"
        >
          <video
            v-if="primary.animationVideo"
            ref="video-giveback"
            :class="$style.video"
            muted="muted"
          >
            <source :src="primary.animationVideo.url" type="video/mp4" />
          </video>
        </aside>
        <ul :class="$style.textContainer">
          <li :class="$style.thirtyDescription">
            <LinearProgressBar
              :class="$style.bar"
              :sec="1.2"
              :is-active="currentStep === 0"
              :callback="onFinishTimer"
              :delay="0.6"
            />
            <RichText
              :text-params="primary['30Description']"
              :class="[
                $style.description,
                { [$style.active]: [0, 3].includes(currentStep) },
              ]"
            />
          </li>
          <li :class="[$style.seventyDescription]">
            <LinearProgressBar
              :class="$style.bar"
              :sec="1"
              :is-active="currentStep === 1"
              :callback="onFinishTimer"
            />
            <RichText
              :text-params="primary['70Description']"
              :class="[
                $style.description,
                { [$style.active]: [1, 3].includes(currentStep) },
              ]"
            />
          </li>
          <li :class="$style.remainDescription">
            <LinearProgressBar
              :class="$style.bar"
              :sec="2"
              :is-active="currentStep === 2"
              :callback="onFinishTimer"
            />
            <RichText
              :text-params="primary.remainingDescription"
              :class="[
                $style.description,
                { [$style.active]: [2, 3].includes(currentStep) },
              ]"
            />
          </li>
        </ul>
      </div>
    </template>

    <ul :class="$style.small">
      <li data-aos="fade-up">
        <RichText :text-params="primary['30Description']" />
      </li>
      <li data-aos="fade-up">
        <RichText :text-params="primary['70Description']" />
      </li>
      <li data-aos="fade-up">
        <RichText :text-params="primary.remainingDescription" />
      </li>
    </ul>
    <Wrapper v-if="primary.ctaText" :class="$style.ctaContainer">
      <ArrowButton
        is-outlined
        :href="getFormatedLink(primary.ctaLink)"
        target="_blank"
      >
        {{ primary.ctaText }}
      </ArrowButton>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import LinearProgressBar from '~/components/LinearProgressBar'
import ArrowButton from '~/components/ArrowButton'

export default {
  name: 'GivebackSchema',
  components: {
    RichText,
    Title,
    ArrowButton,
    LinearProgressBar,
  },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      isPlaying: false,
      currentStep: -1,
    }
  },
  computed: {
    sectionStyle() {
      const backgroundImage = this.primary?.backgroundImage?.url
        ? `background-image:url(${this.primary?.backgroundImage?.url})`
        : ''

      const backgroundColor = this.primary.backgroundColor
        ? `background-color: ${this.primary.backgroundColor};`
        : ''
      return `${backgroundColor} ${backgroundImage}`
    },
    isAnimated() {
      return !!this.primary.animationVideo
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.$refs['video-giveback'].currentTime = 0
        this.$refs['video-giveback'].play()
        this.currentStep = 0
        this.isPlaying = true
      } else {
        this.isPlaying = false
      }
    },
    onFinishTimer() {
      this.currentStep++
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 160px 20px;

  background-color: #f7f5f3;
  @include below(medium) {
    margin: 0 0;
    padding: 80px 20px;

    background-color: white;
  }
}

.title {
  @include below(small) {
    text-align: center;
  }
  font-weight: 200;
}

.sub_description {
  @include typo-body;
  max-width: 840px;
  margin-bottom: 20px;

  color: #808080;

  text-align: center;

  p {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  @include below(medium) {
    max-width: 600px;
  }
}

.medium {
  display: flex;
  max-width: 1280px;
  min-height: 400px;

  li:nth-child(2) {
    width: 400px;
  }
  li:nth-child(1),
  li:nth-child(3) {
    width: 25vw;
  }

  @include below(medium) {
    display: none;
  }
}
.videoContainer {
  flex: 1;
}
.textContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.description {
  color: $gray-400;

  transition: color 0.3s ease-in-out;
  &.active {
    color: black;
  }
}

.bar {
  flex-shrink: 0;
}

.seventyDescription {
  display: flex;
  align-items: center;

  text-align: left;
  .bar {
    height: 100%;
  }
  .description {
    padding: 10px 20px;
  }
}

.thirtyDescription {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .bar {
    height: 100%;
  }
  .description {
    padding: 10px 20px;
  }
}

.remainDescription {
  display: flex;
  align-items: center;
  .bar {
    height: 100%;
  }
  .description {
    padding: 10px 20px;
  }
}

ul.small {
  display: flex;
  flex-direction: column;

  li {
    max-width: 323px;
    margin: 0;
    padding: 16px 0 16px 16px;
    border-left: 4px solid $bluko-500;
    &:nth-child(2) {
      border-left: 4px solid $bluko-300;
    }
    &:nth-child(3) {
      border-left: 4px solid $bluko-100;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }
  @include above(medium) {
    display: none;
  }
}
.ctaContainer {
  @include below(medium) {
    display: none;
  }
}

.video {
  height: 500px;
}
</style>
