// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__container___2NB37{min-height:40px}.CtaBox__addressForm___2iuur{margin-top:80px}@media (max-width:719px){.CtaBox__addressForm___2iuur{margin-top:40px}}.CtaBox__cardsContainer___24zxV{margin-top:48px}.CtaBox__cards___2AtPp{display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:8px;gap:8px}@media (max-width:539px){.CtaBox__cards___2AtPp{grid-template-columns:1fr 1fr}}.CtaBox__card___2tbAW{display:flex;align-items:center;justify-content:center;min-height:104px;border-radius:8px;line-height:24px;text-align:center;background-color:#fff}@media (max-width:539px){.CtaBox__card___2tbAW:first-of-type{grid-column:span 2}}.CtaBox__cardContent___-RwbL{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:16px 24px;text-decoration:none}.CtaBox__wording___31aGN{margin-top:10px;color:#000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CtaBox__container___2NB37",
	"addressForm": "CtaBox__addressForm___2iuur",
	"cardsContainer": "CtaBox__cardsContainer___24zxV",
	"cards": "CtaBox__cards___2AtPp",
	"card": "CtaBox__card___2tbAW",
	"cardContent": "CtaBox__cardContent___-RwbL",
	"wording": "CtaBox__wording___31aGN"
};
module.exports = ___CSS_LOADER_EXPORT___;
