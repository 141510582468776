// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppBlockDefault__container___PhmB9{position:relative;display:flex;align-items:stretch;height:544px}@media (max-width:1279px){.AppBlockDefault__container___PhmB9{height:750px}}@media (max-width:959px){.AppBlockDefault__container___PhmB9{flex-direction:column}}.AppBlockDefault__contentContainer___s_f61{display:flex;flex:1;align-items:center;background-color:#122182}@media (max-width:959px){.AppBlockDefault__contentContainer___s_f61{width:100%;padding:60px 0;text-align:center}}.AppBlockDefault__content___2uK-m{padding:0 65px}@media (max-width:959px){.AppBlockDefault__content___2uK-m{width:100%;padding:0 32px}}.AppBlockDefault__imageContainer___2Mek2{flex:1;height:100%;background-color:#2864da;background-repeat:no-repeat;background-position:50%;background-size:cover}@media (max-width:959px){.AppBlockDefault__imageContainer___2Mek2{flex:initial;width:100%;height:600px}}@media (max-width:719px){.AppBlockDefault__imageContainer___2Mek2{height:350px}}.AppBlockDefault__title___t4vuz{color:#fff;font-weight:200;font-size:48px;line-height:55px}@media (max-width:719px){.AppBlockDefault__title___t4vuz{font-size:32px;line-height:42px}}.AppBlockDefault__description___2kLCY{color:#acbef7}.AppBlockDefault__ctaContainer___u_WZf{margin-top:48px}.AppBlockDefault__appIcon___3eVhM{height:40px}.AppBlockDefault__appIcon___3eVhM:last-of-type{margin-left:8px}@media (max-width:719px){.AppBlockDefault__appIcon___3eVhM{height:calc(min(40px, 11vw))}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "AppBlockDefault__container___PhmB9",
	"contentContainer": "AppBlockDefault__contentContainer___s_f61",
	"content": "AppBlockDefault__content___2uK-m",
	"imageContainer": "AppBlockDefault__imageContainer___2Mek2",
	"title": "AppBlockDefault__title___t4vuz",
	"description": "AppBlockDefault__description___2kLCY",
	"ctaContainer": "AppBlockDefault__ctaContainer___u_WZf",
	"appIcon": "AppBlockDefault__appIcon___3eVhM"
};
module.exports = ___CSS_LOADER_EXPORT___;
