<template>
  <div>
    <GivebackSchema2021Q4
      v-if="slice.variation === 'givebackSchema2021Q4'"
      :slice="slice"
    />
    <GivebackSchemaDefault v-else :slice="slice" />
  </div>
</template>

<script>
import GivebackSchemaDefault from './default-slice/GivebackSchemaDefault.vue'
import GivebackSchema2021Q4 from './givebackSchema2021Q4/GivebackSchema2021Q4.vue'

export default {
  name: 'Hero',
  components: {
    GivebackSchemaDefault,
    GivebackSchema2021Q4,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
