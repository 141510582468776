// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FaqSliceDefault__questionsContainer___1qEts{position:relative;background-color:#fcf7e8}.FaqSliceDefault__inner___XgkoY{display:flex;flex-direction:column;padding:40px 24px}@media (min-width:960px){.FaqSliceDefault__inner___XgkoY{padding:80px 24px}}.FaqSliceDefault__title___3alB-{color:inherit;font-weight:500;font-size:32px;line-height:48px;max-width:700px;margin:0 auto;text-align:center}.FaqSliceDefault__list___RAF8C{display:grid;grid-gap:40px;grid-template-columns:1fr 1fr;margin-top:56px}@media (max-width:959px){.FaqSliceDefault__list___RAF8C{grid-gap:20px;grid-template-columns:1fr}}.FaqSliceDefault__item___jnThG:not(:first-child){margin-top:48px}@media (min-width:960px){.FaqSliceDefault__item___jnThG{flex-basis:calc(50% - 40px)}.FaqSliceDefault__item___jnThG:not(:first-child){margin-top:0}}.FaqSliceDefault__itemTitle___xoI7L{color:inherit;font-weight:400;font-size:20px;line-height:32px;font-weight:500}.FaqSliceDefault__itemContent___1oy5i{color:#6b6651}.FaqSliceDefault__itemContent___1oy5i,.FaqSliceDefault__itemContent___1oy5i p{margin-top:16px}.FaqSliceDefault__link___3rkR-{margin-top:8px}.FaqSliceDefault__button___2MX8S{margin:80px auto 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"questionsContainer": "FaqSliceDefault__questionsContainer___1qEts",
	"inner": "FaqSliceDefault__inner___XgkoY",
	"title": "FaqSliceDefault__title___3alB-",
	"list": "FaqSliceDefault__list___RAF8C",
	"item": "FaqSliceDefault__item___jnThG",
	"itemTitle": "FaqSliceDefault__itemTitle___xoI7L",
	"itemContent": "FaqSliceDefault__itemContent___1oy5i",
	"link": "FaqSliceDefault__link___3rkR-",
	"button": "FaqSliceDefault__button___2MX8S"
};
module.exports = ___CSS_LOADER_EXPORT___;
