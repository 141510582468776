// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerServiceDefault__container___wQT0r{padding:0 24px}.CustomerServiceDefault__inner___18AFp{margin-top:128px;margin-bottom:60px}.CustomerServiceDefault__content___qiH-_{display:flex;flex-direction:column;grid-gap:24px;gap:24px;margin-top:32px}@media (min-width:720px){.CustomerServiceDefault__content___qiH-_{flex-direction:row;align-items:center;justify-content:space-between}.CustomerServiceDefault__content___qiH-_.CustomerServiceDefault__isReverse___Py17L{flex-direction:row-reverse}}@media (min-width:720px){.CustomerServiceDefault__content___qiH-_{grid-column-gap:32px;-moz-column-gap:32px;column-gap:32px}}@media (min-width:1280px){.CustomerServiceDefault__content___qiH-_{grid-column-gap:40px;-moz-column-gap:40px;column-gap:40px}}.CustomerServiceDefault__contentContainer___1PqaV{flex-basis:0;flex-grow:1}.CustomerServiceDefault__contentContainer___1PqaV a{margin-top:40px}@media (min-width:720px){.CustomerServiceDefault__contentContainer___1PqaV{margin-top:0}}@media (min-width:960px){.CustomerServiceDefault__contentContainer___1PqaV{padding:0 73px}}.CustomerServiceDefault__description___326lg p{margin-top:16px}.CustomerServiceDefault__view___3uiWB{flex-basis:0;flex-grow:1}.CustomerServiceDefault__view___3uiWB img{display:block;width:100%}@media (min-width:960px){.CustomerServiceDefault__view___3uiWB{flex-basis:15%;max-width:620px}}.CustomerServiceDefault__item___3mGSD{display:flex;grid-gap:14px;gap:14px;margin-top:8px;color:#6b6651}.CustomerServiceDefault__image___2HJ8A{border-radius:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CustomerServiceDefault__container___wQT0r",
	"inner": "CustomerServiceDefault__inner___18AFp",
	"content": "CustomerServiceDefault__content___qiH-_",
	"isReverse": "CustomerServiceDefault__isReverse___Py17L",
	"contentContainer": "CustomerServiceDefault__contentContainer___1PqaV",
	"description": "CustomerServiceDefault__description___326lg",
	"view": "CustomerServiceDefault__view___3uiWB",
	"item": "CustomerServiceDefault__item___3mGSD",
	"image": "CustomerServiceDefault__image___2HJ8A"
};
module.exports = ___CSS_LOADER_EXPORT___;
