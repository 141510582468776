// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleSliceDefault__container___322Nl{padding:0 24px}.SimpleSliceDefault__inner___GwAF6{margin-top:128px;margin-bottom:128px}.SimpleSliceDefault__content___3aHYY{margin-top:32px}@media (min-width:720px){.SimpleSliceDefault__content___3aHYY{display:flex;align-items:center;justify-content:space-between;grid-column-gap:24px;-moz-column-gap:24px;column-gap:24px}.SimpleSliceDefault__content___3aHYY.SimpleSliceDefault__isReverse___3pd7Z{flex-direction:row-reverse}}@media (min-width:720px){.SimpleSliceDefault__content___3aHYY{grid-column-gap:32px;-moz-column-gap:32px;column-gap:32px}}@media (min-width:1280px){.SimpleSliceDefault__content___3aHYY{grid-column-gap:40px;-moz-column-gap:40px;column-gap:40px}}.SimpleSliceDefault__desc___CeaED{flex-basis:0;flex-grow:1}.SimpleSliceDefault__desc___CeaED a{margin-top:40px}@media (min-width:720px){.SimpleSliceDefault__desc___CeaED{margin-top:0}}@media (min-width:960px){.SimpleSliceDefault__desc___CeaED{padding:0 73px}}.SimpleSliceDefault__subtitle___2lFvm p{color:inherit;font-weight:500;font-size:32px;line-height:48px;margin-bottom:10px}.SimpleSliceDefault__text___2IF7y{margin-top:16px}.SimpleSliceDefault__text___2IF7y p{color:#6b6651}.SimpleSliceDefault__view___2FRfi{flex-basis:0;flex-grow:1;max-width:620px}.SimpleSliceDefault__view___2FRfi img{display:block;width:100%}@media (min-width:960px){.SimpleSliceDefault__view___2FRfi{flex-basis:15%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "SimpleSliceDefault__container___322Nl",
	"inner": "SimpleSliceDefault__inner___GwAF6",
	"content": "SimpleSliceDefault__content___3aHYY",
	"isReverse": "SimpleSliceDefault__isReverse___3pd7Z",
	"desc": "SimpleSliceDefault__desc___CeaED",
	"subtitle": "SimpleSliceDefault__subtitle___2lFvm",
	"text": "SimpleSliceDefault__text___2IF7y",
	"view": "SimpleSliceDefault__view___2FRfi"
};
module.exports = ___CSS_LOADER_EXPORT___;
