<template>
  <div>
    <ProcessArrowDefault :slice="slice" />
  </div>
</template>

<script>
import ProcessArrowDefault from './default-slice/'

export default {
  name: 'ProcessArrow',
  components: {
    ProcessArrowDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
