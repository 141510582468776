<template>
  <section :class="$style.questionsContainer" data-luko-tracking="FaqSection">
    <Wrapper :class="$style.inner">
      <Title :class="$style.title" :title-params="primary.title" />
      <ul :class="$style.list">
        <li v-for="(item, itemIndex) in items" :key="itemIndex" :class="$style.item">
          <Title :class="$style.itemTitle" :title-params="item.questionTitle" />
          <RichText :class="$style.itemContent" :text-params="item.questionText" />
          <PLink v-if="item.ctaText && item.ctaLink.url" is-highlight color="white" :link-object="item.ctaLink" :class="$style.link">
            {{ item.ctaText }}
          </PLink>
        </li>
      </ul>
      <ArrowButton v-if="primary.ctaText" :class="$style.button" is-outlined is-white :href="getFormatedLink(primary.ctaLink)">
        {{ primary.ctaText }}
      </ArrowButton>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import PLink from '~/components/PLink'
import ArrowButton from '~/components/ArrowButton/ArrowButton.vue'

export default {
  name: 'FaqSlice',

  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.items.map(({ questionTitle, questionText }) => ({
        '@type': 'Question',
        name: questionTitle[0].text,
        acceptedAnswer: {
          '@type': 'Answer',
          text: questionText[0].text,
        },
      })),
    }
  },

  components: {
    PLink,
    ArrowButton,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items.filter((item) => (item.questionTitle && item.questionTitle.length) || (item.questionText && item.questionText.length)),
    }
  },
}
</script>

<style lang="scss" module>
.questionsContainer {
  background-color: $bluko-800;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 40px 24px;

  @include above(medium) {
    padding: 80px 24px;
  }
}

.title {
  @include typo-mega-title;
  max-width: 700px;
  margin: 0 auto;

  color: white;
  font-weight: 200;
  line-height: 58px;

  text-align: center;
  hyphens: auto;
}

.list {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
  margin-top: 56px;

  @include below(medium) {
    grid-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
}

.item {
  &:not(:first-child) {
    margin-top: 48px;
  }

  @include above(medium) {
    flex-basis: calc(50% - 40px);

    &:not(:first-child) {
      margin-top: 0;
    }
  }
}

.itemTitle {
  @include typo-headline;
  color: white;
  font-weight: 500;
}

.itemContent {
  margin-top: 16px;

  color: $bluko-100;
  p {
    margin-top: 16px;
  }
}

.link {
  margin-top: 8px;
}

.button {
  margin: 80px auto 0;
}
</style>
