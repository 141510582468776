<template>
  <section
    v-observe-visibility="isVisible"
    data-luko-tracking="HighlightBlockSlice"
    :class="[$style.container]"
  >
    <Wrapper :class="$style.inner">
      <article
        v-for="(item, index) in items"
        :key="index"
        :class="[$style.article, { [$style.single]: items.length === 1 }]"
      >
        <Counter
          v-if="item.title"
          :text="item.title"
          :launch="launchCounter"
          :class="$style.counter"
        />
        <RichText :text-params="item.description" :class="$style.description" />
        <PLink
          v-if="item.cta_title && item.cta_link.url"
          :link-object="item.cta_link"
          is-highlight
          color="white"
        >
          {{ item.cta_title }}
        </PLink>
      </article>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'
import Counter from '~/components/Counter'

export default {
  name: 'CounterBannerDefault',

  components: {
    RichText,
    PLink,
    Counter,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      items: this.slice.items,
      primary: this.slice.primary,
      launchCounter: false,
    }
  },

  methods: {
    isVisible(visible) {
      if (visible) this.launchCounter = visible
    },
  },
}
</script>

<style lang="scss" module>
.container {
  background-color: $bluko-800;
}

.inner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: center;

  padding: 40px 24px;

  @include above(small) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    padding: 56px 72px;
  }

  @include above(medium) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.article {
  width: 100%;
  margin-bottom: 40px;

  text-align: center;

  @include above(small) {
    max-width: 254px;
    margin-bottom: 0;

    text-align: left;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.counter {
  @include typo-extra-title;
  color: white;
  text-align: center;
}

.description {
  p {
    @include typo-headline;
    margin-top: 8px;

    color: $bluko-100;
    text-align: center;
  }
}
</style>
