// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneSubscriptionForm__phoneContainer___3qqfR{display:flex;grid-gap:10px;gap:10px;align-items:flex-start}@media (max-width:719px){.PhoneSubscriptionForm__phoneContainer___3qqfR{flex-direction:column}}@media (max-width:719px){.PhoneSubscriptionForm__phoneField___360Ni{max-width:unset}}.PhoneSubscriptionForm__button___pvtBV{flex-grow:1;flex-shrink:0}@media (max-width:719px){.PhoneSubscriptionForm__button___pvtBV{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"phoneContainer": "PhoneSubscriptionForm__phoneContainer___3qqfR",
	"phoneField": "PhoneSubscriptionForm__phoneField___360Ni",
	"button": "PhoneSubscriptionForm__button___pvtBV"
};
module.exports = ___CSS_LOADER_EXPORT___;
