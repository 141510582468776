<template>
  <div>
    <SimpleCtaWhite v-if="slice.variation == 'white'" :slice="slice" />
    <SimpleCtaDefault v-else :slice="slice" />
  </div>
</template>

<script>
import SimpleCtaDefault from './default-slice/'
import SimpleCtaWhite from './white'

export default {
  name: 'SimpleCta',
  components: {
    SimpleCtaDefault,
    SimpleCtaWhite,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
