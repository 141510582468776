<template>
  <div :class="$style.root" data-luko-tracking="PhoneField">
    <LkLabel :label="label" :for="lukid" />
    <LkInput
      :id="lukid"
      ref="inputComponent"
      :has-error="!!errorMessage"
      :value="value"
      :has-autofocus="hasAutofocus"
      :required="required"
      @keyup="onKeyup"
      @focus="onFocus"
      @input="$emit('input', intlTelInput ? intlTelInput.getNumber() : null)"
      @countrychange="onCountryChange"
      v-on="$listeners"
    />
    <LkError :error="errorMessage" />
  </div>
</template>

<script>
import { loadStylesheet } from './loadStylesheet'
import { loadScript } from './loadScript'

import LkError from '@/components/LkError'
import LkInput from '@/components/LkInput'
import LkLabel from '@/components/LkLabel'

export default {
  name: 'LkPhoneField',
  components: {
    LkLabel,
    LkError,
    LkInput,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: null },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    hasAutofocus: { type: Boolean, default: false },
    locale: { type: String, default: 'fr' },
    required: { type: Boolean, default: false },
  },

  data() {
    return {
      lukid: Date.now(),
      isFocus: false,
      intlTelInput: null,
    }
  },
  async mounted() {
    const [, utilsScript] = await Promise.all([
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.11/js/intlTelInput.min.js'),
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.11/js/utils.min.js'),
      loadStylesheet('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.11/css/intlTelInput.min.css', true),
    ])

    this.intlTelInput = window.intlTelInput(this.$refs.inputComponent.$refs.inputElement, {
      nationalMode: true,
      separateDialCode: false,
      formatOnDisplay: true,
      autoPlaceholder: 'polite',
      preferredCountries: ['fr', 'de', 'es'],
      initialCountry: this.locale,
      utilsScript,
    })
  },
  methods: {
    onKeyup() {
      this.$emit('input', this.intlTelInput.getNumber())
      this.checkPhoneNumber()
    },
    onCountryChange() {
      this.$emit('input', this.intlTelInput.getNumber())
      this.checkPhoneNumber()
    },
    checkPhoneNumber() {
      if (this.intlTelInput && !this.intlTelInput?.isValidNumber()) {
        return this.$emit('update:error', true)
      }
      this.$emit('update:error', false)
    },
    onFocus() {
      this.isFocus = true
      this.$emit('focus')
    },
    onOpen() {
      this.isFocus = true
      this.$emit('open')
    },
    onClose() {
      this.isFocus = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.iti {
  display: block;

  * {
    @include typo-body;
    color: $gray-1000;
  }
}

.iti--separate-dial-code .iti__selected-flag {
  padding-left: 13px;
  border-radius: 8px;

  background-color: white;
}

.iti__country-list {
  border: none;
  border-radius: 8px;

  box-shadow: 0px 2px 16px rgba(0, 0, 108, 0.12);
}

.iti__flag-container {
  padding: 2px;
}
</style>

<style lang="scss" module>
.root {
  display: block;
  width: 100%;

  text-align: left;
}
</style>
