// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___3q9xB .CtaBox__emailField___3U0qH input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___3q9xB .CtaBox__emailField___3U0qH input::-moz-placeholder{color:#fff}.CtaBox__isDark___3q9xB .CtaBox__emailField___3U0qH input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___3q9xB .CtaBox__emailField___3U0qH input::placeholder{color:#fff}.CtaBox__isDark___3q9xB .CtaBox__emailField___3U0qH input:hover{border-color:#829af2}.CtaBox__isDark___3q9xB .CtaBox__emailField___3U0qH input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___3wrF9{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___3wrF9 .CtaBox__button___3OaLc{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___3wrF9{flex-direction:column;max-width:300px}.CtaBox__emailContainer___3wrF9 .CtaBox__emailField___3U0qH{width:100%}}.CtaBox__appsContainer___2rE0h{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___2rE0h{justify-content:center}}.CtaBox__appImage___2uQLb{max-width:152px;border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___2uQLb{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}.CtaBox__ctaButton___14Dws{color:#2b4de0!important}@media (max-width:959px){.CtaBox__ctaButton___14Dws{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___3q9xB",
	"emailField": "CtaBox__emailField___3U0qH",
	"emailContainer": "CtaBox__emailContainer___3wrF9",
	"button": "CtaBox__button___3OaLc",
	"appsContainer": "CtaBox__appsContainer___2rE0h",
	"appImage": "CtaBox__appImage___2uQLb",
	"ctaButton": "CtaBox__ctaButton___14Dws"
};
module.exports = ___CSS_LOADER_EXPORT___;
