// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pac-icon{display:none}.pac-item-query,.pac-matched{color:#020202;font-weight:400;font-size:16px;font-family:\"CircularXX\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Helvetica,Arial,sans-serif}.pac-item{padding:15px 15px 15px 20px;font-weight:400;font-size:16px;cursor:pointer}input.address-input{padding:12px 24px;border:2px solid #eae9e3;border-radius:8px;color:#2c2302;font-size:16px;font-family:\"CircularXX\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Helvetica,Arial,sans-serif;line-height:24px;background-color:#fff;box-shadow:0 0 0 0 #d6e3fd;transition-duration:.3s;transition-property:border-color,box-shadow}input.address-input::-moz-placeholder{color:#6b6651;opacity:1}input.address-input:-ms-input-placeholder{color:#6b6651;opacity:1}input.address-input::placeholder{color:#6b6651;opacity:1}input.address-input:hover{border-color:#d5d4cf}input.address-input:focus{border-color:#2d50e6;box-shadow:0 0 0 4px #d6e3fd}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px"
};
module.exports = ___CSS_LOADER_EXPORT___;
