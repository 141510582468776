<template>
  <div :class="$style.bar">
    <div
      ref="timer"
      :style="{ animationDuration: `${sec}s`, animationDelay: `${delay}s` }"
      :class="[$style.gage, { [$style.counting]: isActive }]"
    />
  </div>
</template>
<script>
export default {
  name: 'LinearProgressBar',

  props: {
    callback: {
      type: Function,
      default: () => () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    sec: {
      type: Number,
      default: 4,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },

  mounted() {
    this.$refs.timer.addEventListener('animationend', () => {
      this.callback()
    })
  },
}
</script>
<style lang="scss" module>
.bar {
  position: relative;

  width: 3px;
  max-height: 100%;

  background-color: #dfded9; // gray-150
}
.gage {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 3px;

  background-color: #2d50e6;

  transition: height;
}
.counting {
  animation-name: tictoc;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes tictoc {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
</style>
