<template>
  <section :class="$style.container">
    <div :class="$style.tableContainer">
      <div :class="$style.table">
        <div v-observe-visibility="isVisible">
          <h2 :class="[$style.score]">
            {{ $n(trustScore, 'score') }}<span :class="$style.per">/5</span>
          </h2>
        </div>
        <p :class="$style.description">
          {{ primary.description.replace(/\{.*\}/, scoreData.numberOfReviews) }}
        </p>
        <div :class="$style.itemsContainer">
          <div :class="$style.item">
            <img
              src="@/assets/icons/luko-logo.svg"
              :class="[$style.lukologo, { [$style.animation]: visible }]"
            />
            <TrustpilotScore
              :score="scoreData.trustScore"
              :size="32"
              :class="$style.starScore"
              animation
              :get-animation-score="getAnimationScore"
            />
          </div>
          <div v-for="(item, index) in items" :key="index" :class="$style.item">
            <div>{{ item.assurance }}</div>
            <TrustpilotScore
              :score="item.score"
              :size="32"
              is-gray
              animation
              :class="$style.starScore"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="primary.image && primary.image.url"
      :class="$style.imageContainer"
      :style="backgroundStyles(primary.image.url, 'lg:100vw')"
    ></div>
  </section>
</template>

<script>
import scoreData from '@@/script/trustpilot/score.json'

import TrustpilotScore from '~/components/TrustpilotScore'
import { backgroundStyles } from '~/mixins/backgroundStyles'

export default {
  name: 'TrustpilotComparison',
  components: {
    TrustpilotScore,
  },
  mixins: [backgroundStyles],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      scoreData,
      trustScore: scoreData.trustScore,
      visible: false,
    }
  },
  methods: {
    isVisible(visible) {
      this.visible = visible
    },
    getAnimationScore(score) {
      this.trustScore = score
    },
  },
}
</script>

<style lang="scss" module>
.container {
  position: relative;

  display: flex;
  align-items: stretch;
  height: 710px;
  margin-top: 156px;

  @include below(large) {
    height: 600px;
  }
  @include below(medium) {
    flex-direction: column;
    align-items: center;
  }
  @include below(small) {
    margin-top: 60px;
  }
}
.tableContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: right;
  width: 50%;
  height: 100%;

  background-color: $orange-50;
  @include below(large) {
    justify-content: center;
    padding: 32px;
  }
  @include below(medium) {
    flex: initial;
    width: 100%;
    height: 414px;
  }
  @include below(small) {
    padding: 24px;
  }
}

.imageContainer {
  flex: 1;
  width: 50%;
  height: 100%;

  background-color: #2864da;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include below(medium) {
    flex: initial;
    width: 100%;
    height: 225px;
  }
}

.table {
  width: 503px;
  height: 535px;
  margin-right: 88px;
  border-radius: 50% 50% 0 0;

  background-color: white;

  @include below(large) {
    width: 440px;
    height: 460px;
    margin-right: 20px;
  }

  @include below(medium) {
    width: 330px;
    height: 366px;
    margin: 0;
  }
  @include below(small) {
    width: calc(min(330px, 90vw));
    height: calc(min(366px, 96vw));
    margin: 0;
  }
}
.score {
  padding-top: 32px;

  font-weight: 200;

  font-size: 84px;
  text-align: center;

  @include below(large) {
    padding-top: 30px;

    font-size: 64px;
  }
}
.per {
  color: $gray-300;
  font-size: 64px;
  @include below(large) {
    font-size: 26px;
  }
}

.description {
  width: 50%;
  margin: 10px auto 0;

  font-weight: 200;

  font-size: 30px;
  line-height: 32px;
  text-align: center;

  @include below(medium) {
    width: 60%;
    margin: 10px auto 0;

    font-size: 24px;
    line-height: 32px;
  }
  @media screen and (max-width: 374px) {
    font-size: 20px;
    line-height: 22px;
  }
}
.itemsContainer {
  width: 80%;
  margin: 46px auto;
  @include below(medium) {
    margin: 26px auto;

    font-size: 15px;
  }
}
.item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 20px 0;

  color: $gray-400;
  font-size: 24px;
  @include below(large) {
    margin: 16px 0 0;

    font-size: 20px;
  }
  @include below(medium) {
    margin: 12px 0 0;

    font-size: 15px;
  }
  @media screen and (max-width: 374px) {
    margin: 10px 0 0;

    font-size: 12px;
  }
}
.starScore {
  display: flex;
  align-items: center;
  justify-content: right;
  @include below(large) {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  @include below(medium) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 375px) {
    svg {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }
}
.lukologo {
  height: 28px;
  @include below(medium) {
    width: 40px;
    height: 18px;
  }
}
.animation {
  animation: jump 0.5s ease-in-out 2;
}
@keyframes jump {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
