<template>
  <div>
    <CustomerService2021Q4
      v-if="slice.variation === 'customerService2021Q4'"
      :slice="slice"
    />
    <CustomerServicePink
      v-else-if="slice.variation === 'customerServicePink'"
      :slice="slice"
    />
    <CustomerServiceDefault v-else :slice="slice" />
  </div>
</template>

<script>
import CustomerServiceDefault from './default-slice/CustomerServiceDefault.vue'
import CustomerService2021Q4 from './customerService2021Q4/CustomerService2021Q4.vue'
import CustomerServicePink from './customerServicePink/CustomerServicePink.vue'

export default {
  name: 'Hero',
  components: {
    CustomerServiceDefault,
    CustomerService2021Q4,
    CustomerServicePink,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
