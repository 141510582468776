<template>
  <a
    data-luko-tracking="ArrowLink"
    :href="linkObject ? href : simpleUrl"
    :target="linkObject ? target : false"
    :class="[$style.link, $style[color]]"
    @click="!linkObject ? $emit('on-click') : null"
  >
    <ArrowRightIcon :class="$style.icon" :width="iconSize" :height="iconSize" />
    <slot />
  </a>
</template>

<script>
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

import { getFormatedLink } from '~/mixins/getFormatedLink'
export default {
  name: 'ArrowLink',

  components: {
    ArrowRightIcon,
  },
  mixins: [getFormatedLink],

  props: {
    linkObject: {
      type: Object,
      default: null,
    },
    simpleUrl: {
      type: String,
      default: '',
    },
    targetedSection: {
      type: String,
      default: '',
    },
    isArrowed: {
      type: Boolean,
      default: false,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'gray',
    },
    iconSize: {
      type: String,
      default: '14',
    },
  },

  computed: {
    isMedia() {
      return this.linkObject.link_type === 'Media'
    },

    href() {
      if (this.targetedSection)
        return `#${this.targetedSection.split('#').join('')}`
      return this.getFormatedLink(this.linkObject)
    },

    target() {
      if (this.isMedia) return '_blank'
      if (this.linkObject) return this.linkObject.target
      return null
    },
  },
}
</script>
<style lang="scss" module>
.link {
  @include typo-body-bold;
  display: flex;
  align-items: center;
  padding-left: 10px;

  text-decoration: none;

  .icon {
    transform: translateX(-10px);

    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    .icon {
      transform: translateX(-5px);
    }
  }
}

.bluko {
  color: $bluko-500;
}
</style>
