<template>
  <LkButton
    :href="href"
    :target="target"
    :rel="rel"
    :is-outlined="isOutlined"
    :class="$style.button"
    :is-white="isWhite"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #icon>
      <ArrowRightIcon v-if="isArrow" class="icon-bluko-500" :class="$style.icon" />
    </template>
    <slot></slot>
  </LkButton>
</template>
<script>
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

export default {
  name: 'ArrowButton',
  components: {
    ArrowRightIcon,
  },
  props: {
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    rel: {
      type: String,
      default: '',
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isArrow: {
      type: Boolean,
      default: true,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" module>
.button {
  transition: background-color 0.2s ease-in-out;
  &:hover {
    .icon {
      transform: translateX(5px);
    }
  }
}
.icon {
  transition: transform 0.2s ease-in-out;
}
</style>
