<template>
  <a :class="$style.container" :href="getTrustpilotLink" target="_blank" rel="nofollow">
    <div :class="$style.section">
      <span :class="[$style.scoreString, { [$style.dark]: isDark }]">
        {{ $t('trustpilot.score') }}
      </span>
      <img :src="score.starImage" :class="$style.starImage" />
    </div>
    <div :class="$style.section">
      <span :class="[$style.reviewString, { [$style.dark]: isDark }]">
        {{ $t('trustpilot.review', { value: score.numberOfReviews }) }}
      </span>
      <img :src="isDark ? score.logoImage.darkBackground : score.logoImage.lightBackground" :class="$style.logo" />
    </div>
  </a>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import { getTrustpilotLink } from '~/mixins/getTrustpilotLink'

export default {
  name: 'TrustpilotStars',
  mixins: [getTrustpilotLink],
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      score,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;

  text-decoration: none;
}
.scoreString {
  margin-right: 10px;

  font-size: 18px;
  &.dark {
    color: white;
  }
  @include below(small) {
    margin-right: 5px;

    font-size: 14px;
  }
}

.reviewString {
  margin-right: 10px;

  font-size: 16px;
  &.dark {
    color: white;
  }
  @include below(small) {
    margin-right: 5px;

    font-size: 12px;
  }
}

.logo {
  height: 20px;
  margin-left: 5px;

  transform: translateY(-1px);
  @include below(small) {
    width: 60px;
    margin-left: 0px;
  }
}

.starImage {
  height: 20px;
  margin-right: 10px;
  @include below(small) {
    width: 80px;
    margin-right: 5px;
  }
}
.section {
  display: flex;
  align-items: center;
}
</style>
