// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iti{display:block}.iti *{color:inherit;font-weight:400;font-size:16px;line-height:24px;color:#2c2302}.iti--separate-dial-code .iti__selected-flag{padding-left:13px;border-radius:8px;background-color:#fff}.iti__country-list{border:none;border-radius:8px;box-shadow:0 2px 16px rgba(0,0,108,.12)}.iti__flag-container{padding:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px"
};
module.exports = ___CSS_LOADER_EXPORT___;
