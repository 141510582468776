<template>
  <div :class="$style.root">
    <textarea
      v-if="isTextarea"
      ref="inputElement"
      :data-luko-tracking="dataLukoTracking"
      :value="value"
      v-bind="$attrs"
      :class="{
        [$style.input]: true,
        [$style['input--error']]: hasError,
        [$style['input--success']]: isSuccess,
      }"
      :required="required"
      v-on="{
        ...$listeners,
        input: (event) => $emit('input', event.target.value),
      }"
    />
    <input
      v-else
      ref="inputElement"
      :data-luko-tracking="dataLukoTracking"
      :value="value"
      v-bind="$attrs"
      :class="{
        [$style.input]: true,
        [$style['input--error']]: hasError,
        [$style['input--success']]: isSuccess,
      }"
      :required="required"
      v-on="{
        ...$listeners,
        input: (event) => $emit('input', event.target.value),
      }"
    />
    <div
      v-if="$slots.append"
      :class="{
        [$style.append]: true,
        [$style['append--full']]: isAppendFull,
      }"
    >
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LkInput',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    dataLukoTracking: {
      type: String,
      default: 'Input',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasAutofocus: {
      type: Boolean,
      default: false,
    },
    isAppendFull: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.hasAutofocus) {
      this.$nextTick(this.focus())
    }
  },
  methods: {
    focus() {
      const input = this.$refs.inputElement

      input.focus()
    },
  },
}
</script>

<style lang="scss" module>
.root {
  position: relative;

  display: block;
  width: 100%;

  text-align: left;
}

.input {
  @include typo-body;
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 16px;
  border: 2px solid $gray-100;
  border-radius: 8px;

  color: $gray-1000;
  font-family: inherit;

  background-color: white;
  outline: none;
  box-shadow: 0 0 0 0 $bluko-200;

  transition-duration: 200ms;
  transition-property: border-color, box-shadow;

  &:hover {
    border-color: $gray-300;
  }

  &.input--success {
    border-color: $mint-500;
  }

  &.input--error {
    border-color: $terracota-500;
  }

  &:focus {
    border-color: $bluko-500;

    box-shadow: 0 0 0 4px $bluko-100;
  }

  &:disabled {
    border-color: $gray-100;

    color: $gray-500;

    background-color: $gray-50;
    cursor: not-allowed;
  }
}

input.input {
  height: 48px;
}

textarea.input {
  min-height: 48px;

  white-space: pre-wrap;

  resize: vertical;
}

.append {
  position: absolute;
  top: 12px;
  right: 18px;

  cursor: pointer;
}

.append--full {
  top: 0;
  right: 0;
}
</style>
