<template>
  <div v-observe-visibility="{ callback: isVisible }">
    <svg
      v-for="(score, idx) in stars"
      :key="idx"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="$style.star"
    >
      <rect width="100%" height="100%" :fill="backgroundColor" />
      <rect
        :ref="`star${idx}`"
        :width="`${score * 100}%`"
        height="100%"
        :fill="getColor"
        :class="{ [$style.animation]: animation && visible }"
        :style="{ transitionDelay: `${animation && visible ? idx * 0.4 : 0}s` }"
      />
      <path
        d="M16 4.5L18.839 12.4226L26.9371 12.7918L20.5936 18.0574L22.7595 26.2082L16 21.54L9.24047 26.2082L11.4064 18.0574L5.06285 12.7918L13.161 12.4226L16 4.5Z"
        fill="white"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    score: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 20,
    },
    isGray: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: Boolean,
      default: false,
    },
    getAnimationScore: {
      type: Function,
      default: () => ({}),
    },
    color: {
      type: String,
      default: '#55A497',
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    stars() {
      if (this.animation && !this.visible) {
        return [0, 0, 0, 0, 0]
      }

      const arr = []
      let score = this.score

      for (let i = 0; i < 5; i++) {
        if (score >= 1) {
          arr[i] = 1
          score--
        } else if (score > 0) {
          arr[i] = 0.5
          score = 0
        } else {
          arr[i] = 0
        }
      }

      return arr
    },
    backgroundColor() {
      return this.isGray ? '#ebebeb' : '#bbdbd5'
    },
    getColor() {
      return this.isGray ? '#cccccc' : this.color
    },
  },
  mounted() {
    if (this.animation) {
      for (const idx of [0, 1, 2, 3, 4]) {
        this.$refs[`star${idx}`][0].addEventListener('transitionend', () => {
          this.getAnimationScore(
            this.stars.slice(0, idx + 1).reduce((a, b) => a + b)
          )
        })
      }
    }
  },
  methods: {
    isVisible(visible) {
      this.visible = visible
    },
  },
}
</script>
<style lang="scss" module>
.star {
  margin-right: 4px;

  transition-duration: 0;
}
.animation {
  transition-timing-function: ease-in;
  transition-duration: 0.35s !important;
  transition-property: width;
}
</style>
