<template>
  <div>
    <FaqSlice2021Q4 v-if="slice.variation === '2021Q4'" :slice="slice" />
    <FaqSliceDefault v-else :slice="slice" />
  </div>
</template>

<script>
import FaqSliceDefault from './default-slice/'
import FaqSlice2021Q4 from './2021Q4'
export default {
  name: 'FaqSlice',
  components: {
    FaqSliceDefault,
    FaqSlice2021Q4,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
