const loadedStylesheet = []

export const loadStylesheet = (url, prepend = false) => {
  const found = loadedStylesheet.find((sheet) => sheet.url === url)

  if (found) {
    return found.getPromise()
  }

  const sheetObject = {
    url,
    promise: null,
    getPromise() {
      if (this.promise) return this.promise

      this.promise = new Promise((resolve) => {
        const sheet = document.createElement('link')

        sheet.rel = 'stylesheet'
        sheet.href = url
        sheet.onload = () => resolve()
        if (prepend) document.head.prepend(sheet)
        else document.head.appendChild(sheet)
      })

      return this.promise
    },
  }

  loadedStylesheet.push(sheetObject)

  return sheetObject.getPromise()
}
