<template>
  <section
    :class="[$style.container, { [$style.isReverse]: primary.imagePosition }]"
  >
    <PImage
      loading="lazy"
      :image-object="primary.image"
      :class="$style.image"
      sizes="sm:100vw"
    />
    <div :class="$style.contentContainer">
      <Title
        v-if="primary.title"
        :title-params="primary.title"
        :class="$style.title"
      />
      <RichText
        :text-params="primary.description"
        :class="$style.description"
      />
      <div :class="$style.itemsContainer">
        <div v-for="(item, index) in items" :key="index" :class="$style.item">
          <PImage :image-object="item.icon" :class="$style.itemIcon" />
          <RichText :text-params="item.text" :class="$style.itemText" />
        </div>
      </div>
      <CtaBox
        :primary="primary"
        :class="$style.ctaContainer"
        is-white
        is-outlined
      />
    </div>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import PImage from '~/components/PImage'

import CtaBox from '../components/CtaBox'

export default {
  name: 'CustomerService2021Q4',

  components: {
    RichText,
    Title,
    PImage,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;

  background-color: $bluko-800;

  &.isReverse {
    flex-direction: row-reverse;
  }
  @include below(small) {
    flex-direction: column !important;
  }
}

.title {
  color: white;
  font-weight: 200;
  font-size: 48px;
  line-height: 55px;
  @include below(medium) {
    font-size: 36px;
    line-height: 38px;
  }
}
.description p {
  margin-top: 16px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 64px;

  @include below(large) {
    padding: 80px 32px;
  }
  @include below(small) {
    padding: 30px 16px;
  }
}
.itemText {
  color: white;
}

.item {
  display: flex;
  gap: 14px;
  margin-top: 16px;

  color: $gray-700;
}
.itemsContainer {
  margin-top: 16px;
}
.image {
  width: 50%;
  object-fit: cover;
  @include below(small) {
    width: 100%;
  }
}
.ctaContainer {
  margin-top: 64px;
}
</style>
