<template>
  <section :class="$style.container" data-luko-tracking="SimpleSlice">
    <Wrapper :class="$style.inner">
      <div
        :class="[
          [$style.content],
          { [$style.isReverse]: primary.imagePosition },
        ]"
      >
        <div :class="$style.view">
          <PImage
            loading="lazy"
            sizes="xs:600px"
            :quality="90"
            :image-object="primary.image"
          />
        </div>
        <div :class="$style.desc">
          <Title :title-params="primary.subtitle" :class="$style.subtitle" />
          <RichText :text-params="primary.text" :class="$style.text" />
          <PLink :link-object="primary.ctaLink" is-highlight color="bluko">
            {{ primary.ctaText }}
          </PLink>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

export default {
  name: 'SimpleSlice',

  components: {
    PImage,
    PLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 0 24px;
}

.inner {
  margin-top: 128px;
  margin-bottom: 128px;
}

.content {
  margin-top: 32px;

  @include above(small) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 24px;

    &.isReverse {
      flex-direction: row-reverse;
    }
  }

  @include above(small) {
    column-gap: 32px;
  }
  @include above(large) {
    column-gap: 40px;
  }
}

.desc {
  flex-basis: 0;
  flex-grow: 1;

  a {
    margin-top: 40px;
  }

  @include above(small) {
    margin-top: 0;
  }
  @include above(medium) {
    padding: 0 73px;
  }
}
.subtitle {
  p {
    @include typo-large-title;
    margin-bottom: 10px;
  }
}
.text {
  margin-top: 16px;

  p {
    color: $gray-700;
  }
}

.view {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 620px;

  img {
    display: block;
    width: 100%;
  }

  @include above(medium) {
    flex-basis: 15%;
  }
}
</style>
