// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___-aTI_ .CtaBox__emailField___2S9LQ input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___-aTI_ .CtaBox__emailField___2S9LQ input::-moz-placeholder{color:#fff}.CtaBox__isDark___-aTI_ .CtaBox__emailField___2S9LQ input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___-aTI_ .CtaBox__emailField___2S9LQ input::placeholder{color:#fff}.CtaBox__isDark___-aTI_ .CtaBox__emailField___2S9LQ input:hover{border-color:#829af2}.CtaBox__isDark___-aTI_ .CtaBox__emailField___2S9LQ input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___22N2Q{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___22N2Q .CtaBox__button___wYDU7{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___22N2Q{flex-direction:column;max-width:300px}.CtaBox__emailContainer___22N2Q .CtaBox__emailField___2S9LQ{width:100%}}.CtaBox__appsContainer___OOItY{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___OOItY{justify-content:center}}.CtaBox__appImage___3aESS{max-width:152px;border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___3aESS{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}@media (max-width:959px){.CtaBox__ctaButton___4Fr_Q{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___-aTI_",
	"emailField": "CtaBox__emailField___2S9LQ",
	"emailContainer": "CtaBox__emailContainer___22N2Q",
	"button": "CtaBox__button___wYDU7",
	"appsContainer": "CtaBox__appsContainer___OOItY",
	"appImage": "CtaBox__appImage___3aESS",
	"ctaButton": "CtaBox__ctaButton___4Fr_Q"
};
module.exports = ___CSS_LOADER_EXPORT___;
