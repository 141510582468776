<template>
  <div data-luko-tracking="CtaBox">
    <AddressForm v-if="type === 'Address'" :placeholder="placeholder" :button-text="text" :query-params="queryParams" />
    <LkButton v-else-if="type === 'Button'" :class="$style.button" :href="ctaTarget" data-cta="calendly">
      {{ text }}
    </LkButton>
  </div>
</template>

<script>
import AddressForm from '~/components/AddressForm'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'

export default {
  name: 'CtaBox',

  components: {
    AddressForm,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    type: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: () => ({}),
    },

    toOnboarding: {
      type: Boolean,
      default: false,
    },

    apps: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ctaTarget() {
      if (this.toOnboarding) return this.getOnboardingLink()

      const formatedLink = new URL(this.getFormatedLink(this.link))

      // forwarding query params
      Object.entries(this.$route.query).forEach(([k, v]) => {
        if (!formatedLink.searchParams.has(k)) {
          formatedLink.searchParams.append(k, v)
        }
      })

      return formatedLink.toString()
    },

    queryParams() {
      if (this.link && this.link.url) {
        return Array.from(new URL(this.link.url).searchParams.entries())
      }
      return []
    },
  },
}
</script>

<style lang="scss" module>
.button {
  @include below(medium) {
    width: 100%;
  }
}
</style>
