<template>
  <div>
    <HighlightIconsDefault :slice="slice" />
  </div>
</template>

<script>
import HighlightIconsDefault from './default-slice/'

export default {
  name: 'HighlightSection',
  components: {
    HighlightIconsDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
