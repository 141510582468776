export const prepare_partial_address = (streetNumber, route) => {
  const addressArray = []
  if (streetNumber) addressArray.push(streetNumber)
  if (route) addressArray.push(route)
  return addressArray.length ? addressArray.join(' ') : null
}

export const prepare_address = (partialAddress, postalCode, city, country) => {
  const addressArray = []
  const cityArray = []
  if (partialAddress) addressArray.push(partialAddress)
  if (postalCode) cityArray.push(postalCode)
  if (city) cityArray.push(city)
  if (cityArray.length) addressArray.push(cityArray.join(' '))
  if (country) addressArray.push(country)
  // `${partialAddress}, ${postalCode} ${city}, ${country}`
  return addressArray.length ? addressArray.join(', ') : null
}
