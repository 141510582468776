<template>
  <section
    :class="$style.container"
    :style="backgroundStyles(primary.backgroundImage.url)"
  >
    <Wrapper id="home-hero" :class="$style.inner">
      <div :class="$style.desc">
        <Title :class="$style.title" :title-params="primary.title" />
        <span v-if="primary.tag" :class="$style.tag">{{ primary.tag }}</span>
        <RichText :text-params="primary.subtitle" :class="$style.subtitle" />
        <AddressForm
          :class="$style.addressBarContainer"
          :placeholder="primary.inputPlaceholder"
          :is-required="false"
          :button-text="primary.ctaText"
        />
        <div
          v-if="primary.trustpilotText && primary.trustpilotText.length"
          :class="$style.trustpilot"
        >
          <TrustpilotStars inline-small />
          <RichText :text-params="primary.trustpilotText" />
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import AddressForm from '~/components/AddressForm'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import TrustpilotStars from '~/components/TrustpilotStars'

import { backgroundStyles } from '@/mixins/backgroundStyles'

export default {
  name: 'HeroDefault',

  components: {
    AddressForm,
    Title,
    RichText,
    TrustpilotStars,
  },

  mixins: [backgroundStyles],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: this.slice.items,
      primary: this.slice.primary,
    }
  },
  methods: {
    onError(e) {
      console.log(e)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: inherit;
  min-height: 700px;
  margin-top: -64px;
  padding: 0 24px;

  background-color: #fcf7e8;
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: 2048px 594px;

  @include below(medium) {
    background-position-x: -270px;

    background-size: 1024px 287px;
  }

  @media (min-width: 2000px) {
    background-position-x: 500px;
    background-position-y: bottom;
  }

  @media (min-width: 3000px) {
    background-position-x: 1000px;
    background-position-y: bottom;
  }
}

.inner {
  display: flex;
  min-height: 630px;
  padding-top: 120px;

  @include above(small) {
    min-height: 680px;
    padding-top: 144px;
  }
}

.desc {
  max-width: 720px;
  margin: 0 auto;
  @include above(small) {
    margin: 0;
  }
}

.title h1 {
  max-width: 725px;
  margin-bottom: 16px;

  color: $gray-1000;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;

  @include above(small) {
    font-size: 52px;
    line-height: 64px;
  }
}

.subtitle {
  max-width: 650px;
  margin: 0;

  color: $gray-800;
  p {
    font-size: 20px;
    line-height: 32px;

    @include below(small) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.addressBarContainer {
  margin-top: 56px;
}

.trustpilot {
  @include typo-sub-text;
  display: flex;
  gap: 8px;
  margin-top: 24px;

  color: $gray-500;

  strong {
    color: $gray-700;
  }
}

.tag {
  display: inline-block;
  margin-top: 10px;

  padding: 8px 16px;
  border-radius: 20px;

  background-color: $gold-500;

  transform: translateY(-10px);
  @include typo-body;

  @include below(small) {
    display: none;
  }
}
</style>
