<template>
  <div :class="$style.container">
    <client-only>
      <AddressForm
        v-if="type === 'Address'"
        data-luko-tracking="AddressCta"
        :placeholder="placeholder"
        :button-text="text"
        :query-params="queryParams"
        :class="$style.addressForm"
        is-light
      />
      <div v-else-if="type === 'Cards'" data-luko-tracking="CardCta" :class="$style.cardsContainer">
        <ul :class="$style.cards">
          <li v-for="(card, index) in cards" :key="index" :class="$style.card" :data-luko-tracking="card.i18nKey">
            <a
              :class="$style.cardContent"
              :href="
                getOnboardingLink(null, {
                  type: card.onboardingType,
                  queryParams: card.queryParams,
                })
              "
              @click="onCardClick(card.product)"
            >
              <component :is="card.icon" />
              <span :class="$style.wording">
                {{ $t(`HomePage.Cards.${card.i18nKey}`) }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </client-only>
  </div>
</template>

<script>
import HouseIcon from 'LkIcons/House.vue'
import eScooterIcon from 'LkIcons/eScooter.vue'
import BankIcon from 'LkIcons/Bank.vue'
import KeyIcon from 'LkIcons/Key.vue'
import BeachIcon from 'LkIcons/Beach.vue'
import PawIcon from 'LkIcons/Paw.vue'
import CouchIcon from 'LkIcons/Couch.vue'
import PersonIcon from 'LkIcons/Person.vue'

import { ONBOARDING_TYPE } from '~/utils/constants'
import AddressForm from '~/components/AddressForm'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'

const CardList = {
  fr: [
    {
      i18nKey: 'Housing',
      icon: 'HouseIcon',
      onboardingType: ONBOARDING_TYPE.home,
      product: 'HOME',
    },
    {
      i18nKey: 'Mortgage',
      icon: 'BankIcon',
      onboardingType: ONBOARDING_TYPE.mortgage,
      product: 'MGI',
    },
    {
      i18nKey: 'eScooter',
      icon: 'eScooterIcon',
      onboardingType: ONBOARDING_TYPE.escooter,
      product: 'FR_ESCOOTER',
    },
  ],
  es: [
    {
      i18nKey: 'Home',
      icon: 'HouseIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.is_main', 'true'],
        ['$.product', 'ES_HOME'],
        ['bypass', 'true'],
      ],
      product: 'ES_HOME',
    },
    {
      i18nKey: 'SecondaryHome',
      icon: 'BeachIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.is_main', 'false'],
        ['$.product', 'ES_SECONDARY'],
        ['bypass', 'true'],
      ],
      product: 'ES_SECONDARY',
    },
    {
      i18nKey: 'Landlord',
      icon: 'KeyIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.country_code', 'ES'],
        ['$.home.main_asset.owner_type', 'landlord'],
        ['$.product', 'ES_LANDLORD'],
      ],
      product: 'ES_LANDLORD',
    },
  ],
  de: [
    {
      i18nKey: 'Pet',
      icon: 'PawIcon',
      onboardingType: ONBOARDING_TYPE.pet,
      product: 'PET',
    },
    {
      i18nKey: 'HomeContents',
      icon: 'CouchIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.country_code', 'DE'],
        ['$.product', 'DE_HOME_CONTENT'],
        ['bypass', 'true'],
      ],
      product: 'DE_HOME_CONTENT',
    },
    {
      i18nKey: 'PrivateLiability',
      icon: 'PersonIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.country_code', 'DE'],
        ['$.product', 'DE_LIABILITY_PRIVATE'],
        ['bypass', 'true'],
      ],
      product: 'DE_LIABILITY_PRIVATE',
    },
  ],
}

export default {
  name: 'CtaBox',

  components: {
    AddressForm,
    HouseIcon,
    eScooterIcon,
    BankIcon,
    KeyIcon,
    BeachIcon,
    PawIcon,
    CouchIcon,
    PersonIcon,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    type: {
      type: String,
      default: 'None',
    },

    text: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cards: CardList[process.env.CURRENT_DOMAIN],
    }
  },
  computed: {
    ctaTarget() {
      if (this.toOnboarding) return this.getOnboardingLink()

      const formatedLink = new URL(this.getFormatedLink(this.link))

      // forwarding query params
      Object.entries(this.$route.query).forEach(([k, v]) => {
        if (!formatedLink.searchParams.has(k)) {
          formatedLink.searchParams.append(k, v)
        }
      })

      return formatedLink.toString()
    },

    queryParams() {
      if (this.link && this.link.url) {
        return Array.from(new URL(this.link.url).searchParams.entries())
      }
      return []
    },
  },
  methods: {
    onCardClick(product) {
      window.LukoTracking.trackEvent({
        id: 'CTA Clicked',
        properties: { onboarding: { product, step: 'homepage' } },
      })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  min-height: 40px;
}

.addressForm {
  margin-top: 80px;
  @include below(small) {
    margin-top: 40px;
  }
}

.cardsContainer {
  margin-top: 48px;
}
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  @include below(xsmall) {
    grid-template-columns: 1fr 1fr;
  }
}
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 104px;
  border-radius: 8px;

  line-height: 24px;
  text-align: center;

  background-color: white;

  @include below(xsmall) {
    &:first-of-type {
      grid-column: span 2;
    }
  }
}
.cardContent {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 16px 24px;

  text-decoration: none;
}

.wording {
  margin-top: 10px;

  color: black;
}
</style>
