<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="primary.title" :class="$style.title" />
      <div>
        <RichText
          :text-params="primary.description"
          :class="$style.description"
        />
        <PLink
          v-if="primary.ctaText"
          is-highlight
          color="bluko"
          :link-object="primary.ctaLink"
          :class="$style.cta"
          >{{ primary.ctaText }}</PLink
        >
      </div>
    </Wrapper>
  </section>
</template>

<script>
import PLink from '~/components/PLink'
export default {
  name: 'SliceName',
  components: {
    PLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 0 auto;
  padding: 130px 60px;
}

.inner {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 40px;
  @include below(small) {
    grid-template-columns: 1fr;
  }
}
.title {
  @include typo-title;
}
.description {
  color: $gray-700;
}
.cta {
  margin-top: 16px;
}
</style>
