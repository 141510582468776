<template>
  <section v-observe-visibility="isVisible" :class="[[$style.heroContainer]]">
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Breadcrumb v-if="primary.showBreadcrumb" :class="$style.breadcrumb" :custom-name="primary.title[0].text" />
        <div :class="$style.titleContainer">
          <Title :class="$style.title" :title-params="primary.title" />
          <span v-if="primary.tag" :class="$style.tag">{{ primary.tag }}</span>
        </div>
        <RichText :class="$style.subtitle" :text-params="primary.subtitle" />
        <CtaBox
          v-if="primary.ctaType !== 'None'"
          :class="$style.ctaContainer"
          :placeholder="primary.inputPlaceholder"
          :type="primary.ctaType"
          :text="primary.ctaText"
          :link="primary.ctaLink"
          :to-onboarding="primary.ctaToOnboarding"
        />
      </div>
      <div :class="$style.view">
        <PImage :class="$style.image" :image-object="primary.backgroundImage" sizes="sm:100vw" />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import PImage from '~/components/PImage'
import Breadcrumb from '~/components/Breadcrumb'

import { CtaBox } from './components'

export default {
  name: 'HeroPartner2021Q4',

  components: {
    PImage,
    CtaBox,
    Breadcrumb,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },

  computed: {
    ctaTarget() {
      return this.getFormatedLink(this.primary.ctaLink)
    },
  },

  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  min-height: 615px;
  margin-top: -64px;
  padding: 0 80px;

  background-color: #fff4ee;

  @include below(medium) {
    padding: 24px;
    padding-top: 64px;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-top: 128px;

  @include below(medium) {
    flex-direction: column;
    padding-top: 24px;
  }
}

.desc {
  width: 50%;
  max-width: 600px;
  @include below(medium) {
    width: 100%;
    max-width: none;
  }
}

.view {
  width: 40%;
  max-width: 500px;
  height: 438px;
  margin: 0;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  overflow: hidden;

  @include below(medium) {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: unset;
    height: 80vw;
    max-height: 400px;
    margin: 50px auto 0;
  }
}

.breadcrumb {
  margin-bottom: 16px;
}

.title {
  display: inline;
  margin-top: 16px;

  font-weight: 200;
  font-size: 48px;
  line-height: 59px;
  hyphens: auto;

  h1 {
    display: inline;
  }

  @include below(small) {
    font-size: 40px;
    line-height: 56px;
  }
}

.tag {
  display: inline-block;
  margin-top: 10px;

  padding: 8px 16px;
  border-radius: 20px;

  background-color: $gold-500;

  transform: translateY(-10px);
  @include typo-body;

  @include below(small) {
    display: none;
  }
}

.subtitle p {
  @include typo-headline;
  margin-top: 16px;

  color: $gray-700;
  line-height: 32px;
}

.ctaContainer {
  margin-top: 40px;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
