// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Waitlist__warning___2GwTZ{color:inherit;font-weight:500;font-size:20px;line-height:32px;color:red}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"warning": "Waitlist__warning___2GwTZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
