// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerServicePink__container___16xas{margin:160px auto;padding:0 24px}@media (max-width:959px){.CustomerServicePink__container___16xas{margin:80px auto}}.CustomerServicePink__inner___1INyb{display:flex;background-color:#fff4ee}.CustomerServicePink__inner___1INyb.CustomerServicePink__isReverse___2oOVB{flex-direction:row-reverse}@media (max-width:959px){.CustomerServicePink__inner___1INyb{flex-direction:column!important}}.CustomerServicePink__title___2BYVp{color:inherit;font-weight:500;font-size:48px;line-height:64px;font-weight:200}@media (max-width:719px){.CustomerServicePink__title___2BYVp{font-size:36px;line-height:38px}}.CustomerServicePink__description___DG0aq p{margin-top:16px}.CustomerServicePink__contentContainer___11G9o{display:flex;flex:1;flex-direction:column;justify-content:center;padding:72px 86px}@media (max-width:1279px){.CustomerServicePink__contentContainer___11G9o{padding:80px 32px}}@media (max-width:719px){.CustomerServicePink__contentContainer___11G9o{padding:24px}}.CustomerServicePink__itemText___1WTLW{color:#fff}.CustomerServicePink__item___1EI0A{display:flex;grid-gap:14px;gap:14px;color:#6b6651}.CustomerServicePink__item___1EI0A,.CustomerServicePink__itemsContainer___38pvf{margin-top:16px}.CustomerServicePink__image___1CVJT{flex:1;width:50%;-o-object-fit:cover;object-fit:cover}@media (max-width:959px){.CustomerServicePink__image___1CVJT{width:100%}}.CustomerServicePink__ctaContainer___3s0od{margin-top:64px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CustomerServicePink__container___16xas",
	"inner": "CustomerServicePink__inner___1INyb",
	"isReverse": "CustomerServicePink__isReverse___2oOVB",
	"title": "CustomerServicePink__title___2BYVp",
	"description": "CustomerServicePink__description___DG0aq",
	"contentContainer": "CustomerServicePink__contentContainer___11G9o",
	"itemText": "CustomerServicePink__itemText___1WTLW",
	"item": "CustomerServicePink__item___1EI0A",
	"itemsContainer": "CustomerServicePink__itemsContainer___38pvf",
	"image": "CustomerServicePink__image___1CVJT",
	"ctaContainer": "CustomerServicePink__ctaContainer___3s0od"
};
module.exports = ___CSS_LOADER_EXPORT___;
