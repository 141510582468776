// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdviceHub2021Q4__heroContainer___1L5mT{min-height:615px;margin-top:-64px;padding:64px 40px 46px;background-color:#fff4ee}@media (max-width:959px){.AdviceHub2021Q4__heroContainer___1L5mT{padding:64px 24px 24px}}.AdviceHub2021Q4__breadcrumb___1IX2x{margin-bottom:16px}.AdviceHub2021Q4__inner___1vHfo{display:flex;grid-gap:90px;gap:90px;justify-content:space-between;height:100%;padding-top:56px}@media (max-width:1279px){.AdviceHub2021Q4__inner___1vHfo{grid-gap:40px;gap:40px}}@media (max-width:959px){.AdviceHub2021Q4__inner___1vHfo{flex-direction:column;grid-gap:0;gap:0}}@media (max-width:719px){.AdviceHub2021Q4__inner___1vHfo{padding-top:24px}}.AdviceHub2021Q4__desc___1xtk5{width:50%;max-width:600px}@media (max-width:959px){.AdviceHub2021Q4__desc___1xtk5{width:100%;max-width:none}}.AdviceHub2021Q4__title___2wlax{margin-top:16px;font-weight:200;font-size:48px;line-height:59px}.AdviceHub2021Q4__title___2wlax,.AdviceHub2021Q4__title___2wlax h1{display:inline}@media (max-width:719px){.AdviceHub2021Q4__title___2wlax{font-size:40px;line-height:56px}}.AdviceHub2021Q4__subtitle___3Ctug p{margin-top:16px;color:inherit;font-weight:400;font-size:20px;line-height:32px;color:#6b6651}.AdviceHub2021Q4__view___14T_9{width:50%;min-height:430px;max-height:460px;margin:0;border-bottom-left-radius:300px;overflow:hidden}@media (max-width:959px){.AdviceHub2021Q4__view___14T_9{width:100%;max-width:none;height:263px;min-height:auto;margin-top:16px;border-bottom-right-radius:0;border-bottom-left-radius:150px}}.AdviceHub2021Q4__image___2DzXg{display:block;width:100%;height:100%;margin-bottom:30px;-o-object-fit:cover;object-fit:cover;-o-object-position:80%;object-position:80%}.AdviceHub2021Q4__ctaContainer___1UbOr{margin-top:40px}@media (max-width:719px){.AdviceHub2021Q4__ctaContainer___1UbOr a{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "AdviceHub2021Q4__heroContainer___1L5mT",
	"breadcrumb": "AdviceHub2021Q4__breadcrumb___1IX2x",
	"inner": "AdviceHub2021Q4__inner___1vHfo",
	"desc": "AdviceHub2021Q4__desc___1xtk5",
	"title": "AdviceHub2021Q4__title___2wlax",
	"subtitle": "AdviceHub2021Q4__subtitle___3Ctug",
	"view": "AdviceHub2021Q4__view___14T_9",
	"image": "AdviceHub2021Q4__image___2DzXg",
	"ctaContainer": "AdviceHub2021Q4__ctaContainer___1UbOr"
};
module.exports = ___CSS_LOADER_EXPORT___;
