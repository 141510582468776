// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroProduct2021Q4__heroContainer___3s9Jo{min-height:615px;margin-top:-64px;padding:0 80px;background-color:#fff4ee}@media (max-width:959px){.HeroProduct2021Q4__heroContainer___3s9Jo{padding:64px 24px 24px}}.HeroProduct2021Q4__inner___2gYdD{display:flex;justify-content:space-between;height:100%;padding-top:128px}@media (max-width:959px){.HeroProduct2021Q4__inner___2gYdD{flex-direction:column;padding-top:24px}}.HeroProduct2021Q4__desc___ul5XO{width:50%;max-width:600px}@media (max-width:959px){.HeroProduct2021Q4__desc___ul5XO{width:100%;max-width:none}}.HeroProduct2021Q4__view___2qLzj{width:40%;max-width:500px;height:438px;margin:0;border-top-left-radius:50%;border-top-right-radius:50%;overflow:hidden}@media (max-width:959px){.HeroProduct2021Q4__view___2qLzj{width:calc(100vw - 24px);max-width:none;height:256px;margin-top:16px;margin-right:-24px;margin-bottom:-24px;border-top-left-radius:0;border-top-right-radius:0;border-bottom-left-radius:150px}}.HeroProduct2021Q4__breadcrumb___hyMnv{margin-bottom:16px}.HeroProduct2021Q4__title___2O5VK{margin-top:16px;font-weight:200;font-size:48px;line-height:59px;-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto}.HeroProduct2021Q4__title___2O5VK,.HeroProduct2021Q4__title___2O5VK h1{display:inline}@media (max-width:719px){.HeroProduct2021Q4__title___2O5VK{font-size:40px;line-height:56px}}.HeroProduct2021Q4__tag___10M1q{display:inline-block;margin-top:10px;padding:8px 16px;border-radius:20px;background-color:#e9b857;transform:translateY(-10px);color:inherit;font-weight:400;font-size:16px;line-height:24px}@media (max-width:719px){.HeroProduct2021Q4__tag___10M1q{display:none}}.HeroProduct2021Q4__subtitle___3gqKt p{color:inherit;font-weight:400;font-size:20px;margin-top:16px;color:#6b6651;line-height:32px}.HeroProduct2021Q4__ctaContainer___3cWor{margin-top:40px}.HeroProduct2021Q4__image___1F0xH{display:block;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "HeroProduct2021Q4__heroContainer___3s9Jo",
	"inner": "HeroProduct2021Q4__inner___2gYdD",
	"desc": "HeroProduct2021Q4__desc___ul5XO",
	"view": "HeroProduct2021Q4__view___2qLzj",
	"breadcrumb": "HeroProduct2021Q4__breadcrumb___hyMnv",
	"title": "HeroProduct2021Q4__title___2O5VK",
	"tag": "HeroProduct2021Q4__tag___10M1q",
	"subtitle": "HeroProduct2021Q4__subtitle___3gqKt",
	"ctaContainer": "HeroProduct2021Q4__ctaContainer___3cWor",
	"image": "HeroProduct2021Q4__image___1F0xH"
};
module.exports = ___CSS_LOADER_EXPORT___;
