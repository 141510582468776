<template>
  <div>
    <MediaCarouselDesktop :slice="slice" :class="$style.desktop" />
    <MediaCarouselMobile :slice="slice" :class="$style.mobile" />
  </div>
</template>

<script>
import MediaCarouselDesktop from './components/MediaCarouselDesktop'
import MediaCarouselMobile from './components/MediaCarouselMobile'

export default {
  name: 'MediaCarrouselDesktop',
  components: {
    MediaCarouselDesktop,
    MediaCarouselMobile,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.desktop {
  @include below(medium) {
    display: none;
  }
}
.mobile {
  @include above(medium) {
    display: none;
  }
}
</style>
