<template>
  <div>
    <PLink
      v-if="ctaType === 'link' && item.ctaText && item.ctaLink"
      :link-object="blockLink(item.ctaLink, item.link_url_connected_user)"
      is-highlight
      color="bluko"
    >
      {{ blockLinkText(item.ctaText, item.link_text_connected_user) }}
    </PLink>

    <PLink
      v-else-if="ctaType === 'mailto' && item.ctaText && item.mailTo"
      :simple-url="`mailto:${item.mailTo}`"
      is-highlight
      color="bluko"
    >
      {{ item.ctaText }}
    </PLink>

    <PLink
      v-else-if="ctaType === 'intercom' && item.ctaText"
      simple-url="#/"
      is-highlight
      color="bluko"
      @on-click="showIntercom"
    >
      {{ item.ctaText }}
    </PLink>
  </div>
</template>
<script>
import { isLoggedIn } from '~/mixins/isLoggedIn'
import PLink from '~/components/PLink'

export default {
  name: 'CtaBox',

  components: {
    PLink,
  },

  mixins: [isLoggedIn],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      lastname: null,
      firstname: null,
    }
  },

  computed: {
    ctaType() {
      return this.item.ctaType || 'link'
    },
  },

  methods: {
    blockLink(normalLink, connectedLink) {
      if (connectedLink?.url && this.isLoggedIn) return connectedLink

      return normalLink
    },

    blockLinkText(normalLinkText, connectedLinkText) {
      if (connectedLinkText && this.isLoggedIn) return connectedLinkText

      return normalLinkText
    },
    showIntercom() {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        window.Didomi.notice.show()
      }
    },
  },
}
</script>

<style lang="scss" module></style>
