<template>
  <section :class="$style.container" data-luko-tracking="MediaCarrousel">
    <Wrapper :class="$style.inner">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="$style.item"
        data-aos="fade-up"
      >
        <div :class="$style.imageContainer">
          <PImage
            :image-object="item.imageBack"
            sizes="xs:100vw"
            :class="$style.imageBack"
          />
          <PImage
            :image-object="item.imageFront"
            sizes="xs:100vw"
            :class="$style.imageFront"
          />
        </div>
        <RichText
          :text-params="item.description"
          :class="[$style.description]"
        />
      </div>
    </Wrapper>
    <Wrapper
      v-if="primary.ctaText && primary.ctaLink"
      :class="$style.ctaContainer"
    >
      <ArrowButton
        is-outlined
        :href="getFormatedLink(primary.ctaLink)"
        :target="primary.ctaLink.target"
        :class="$style.ctaButton"
      >
        {{ primary.ctaText }}
      </ArrowButton>
    </Wrapper>
  </section>
</template>

<script>
import ArrowButton from '~/components/ArrowButton'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import RichText from '~/components/RichText'

export default {
  name: 'MediaCarouselMobile',
  components: {
    RichText,
    ArrowButton,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items.filter(
        (item) => item.imageFront.url && item.imageBack.url
      ),
    }
  },
}
</script>

<style lang="scss" module>
.container {
  justify-content: center;
  padding: 60px 20px 80px;
}

.inner {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;

  width: 100%;
  height: calc(min(300px, 40vw));

  text-align: center;
}

.imageFront {
  position: absolute;
  z-index: 2;

  width: calc(min(300px, 40vw));
  height: calc(min(300px, 40vw));

  transform: translateX(-50%);
}
.imageBack {
  position: absolute;

  width: calc(min(300px, 40vw));
  height: calc(min(300px, 40vw));

  transform: translateX(-50%);
}

.description {
  max-width: 400px;
  padding-top: 40px;

  text-align: center;
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
  p {
    color: $gray-700;
    font-size: 16px;
    line-height: 24px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ctaContainer {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
.ctaButton {
  @include below(small) {
    width: 100%;
  }
}
</style>
