// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerService2021Q4__container___3X4Ry{display:flex;background-color:#122182}.CustomerService2021Q4__container___3X4Ry.CustomerService2021Q4__isReverse___2l4nc{flex-direction:row-reverse}@media (max-width:719px){.CustomerService2021Q4__container___3X4Ry{flex-direction:column!important}}.CustomerService2021Q4__title___1B25-{color:#fff;font-weight:200;font-size:48px;line-height:55px}@media (max-width:959px){.CustomerService2021Q4__title___1B25-{font-size:36px;line-height:38px}}.CustomerService2021Q4__description___3U426 p{margin-top:16px}.CustomerService2021Q4__contentContainer___CBK24{display:flex;flex-direction:column;justify-content:center;padding:0 64px}@media (max-width:1279px){.CustomerService2021Q4__contentContainer___CBK24{padding:80px 32px}}@media (max-width:719px){.CustomerService2021Q4__contentContainer___CBK24{padding:30px 16px}}.CustomerService2021Q4__itemText___31gfa{color:#fff}.CustomerService2021Q4__item___12qwN{display:flex;grid-gap:14px;gap:14px;color:#6b6651}.CustomerService2021Q4__item___12qwN,.CustomerService2021Q4__itemsContainer___2OH03{margin-top:16px}.CustomerService2021Q4__image___EkCrl{width:50%;-o-object-fit:cover;object-fit:cover}@media (max-width:719px){.CustomerService2021Q4__image___EkCrl{width:100%}}.CustomerService2021Q4__ctaContainer___2ncCY{margin-top:64px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CustomerService2021Q4__container___3X4Ry",
	"isReverse": "CustomerService2021Q4__isReverse___2l4nc",
	"title": "CustomerService2021Q4__title___1B25-",
	"description": "CustomerService2021Q4__description___3U426",
	"contentContainer": "CustomerService2021Q4__contentContainer___CBK24",
	"itemText": "CustomerService2021Q4__itemText___31gfa",
	"item": "CustomerService2021Q4__item___12qwN",
	"itemsContainer": "CustomerService2021Q4__itemsContainer___2OH03",
	"image": "CustomerService2021Q4__image___EkCrl",
	"ctaContainer": "CustomerService2021Q4__ctaContainer___2ncCY"
};
module.exports = ___CSS_LOADER_EXPORT___;
