<template>
  <div>
    <HighlightTextDefault :slice="slice" />
  </div>
</template>

<script>
import HighlightTextDefault from './default-slice'

export default {
  name: 'HighlightText',
  components: {
    HighlightTextDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
