// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___2YOik .CtaBox__emailField___2j4Nw input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___2YOik .CtaBox__emailField___2j4Nw input::-moz-placeholder{color:#fff}.CtaBox__isDark___2YOik .CtaBox__emailField___2j4Nw input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___2YOik .CtaBox__emailField___2j4Nw input::placeholder{color:#fff}.CtaBox__isDark___2YOik .CtaBox__emailField___2j4Nw input:hover{border-color:#829af2}.CtaBox__isDark___2YOik .CtaBox__emailField___2j4Nw input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___1enBP{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___1enBP .CtaBox__button___3JfL5{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___1enBP{flex-direction:column;max-width:300px}.CtaBox__emailContainer___1enBP .CtaBox__emailField___2j4Nw{width:100%}}.CtaBox__appsContainer___3h-4j{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___3h-4j{justify-content:center}}.CtaBox__appImage___2Uqf0{max-width:152px;border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___2Uqf0{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}@media (max-width:959px){.CtaBox__ctaButton___1cDD7{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___2YOik",
	"emailField": "CtaBox__emailField___2j4Nw",
	"emailContainer": "CtaBox__emailContainer___1enBP",
	"button": "CtaBox__button___3JfL5",
	"appsContainer": "CtaBox__appsContainer___3h-4j",
	"appImage": "CtaBox__appImage___2Uqf0",
	"ctaButton": "CtaBox__ctaButton___1cDD7"
};
module.exports = ___CSS_LOADER_EXPORT___;
