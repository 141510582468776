// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FaqSlice2021Q4__questionsContainer___2n4OE{background-color:#122182}.FaqSlice2021Q4__inner___1BLV1{display:flex;flex-direction:column;padding:40px 24px}@media (min-width:960px){.FaqSlice2021Q4__inner___1BLV1{padding:80px 24px}}.FaqSlice2021Q4__title___3fNW0{color:inherit;font-weight:500;font-size:48px;line-height:64px;max-width:700px;margin:0 auto;color:#fff;font-weight:200;line-height:58px;text-align:center;-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto}.FaqSlice2021Q4__list___1Pelj{display:grid;grid-gap:40px;grid-template-columns:1fr 1fr;margin-top:56px}@media (max-width:959px){.FaqSlice2021Q4__list___1Pelj{grid-gap:20px;grid-template-columns:1fr;margin-top:40px}}.FaqSlice2021Q4__item___3UblR:not(:first-child){margin-top:48px}@media (min-width:960px){.FaqSlice2021Q4__item___3UblR{flex-basis:calc(50% - 40px)}.FaqSlice2021Q4__item___3UblR:not(:first-child){margin-top:0}}.FaqSlice2021Q4__itemTitle___v1-Rs{color:inherit;font-weight:400;font-size:20px;line-height:32px;color:#fff;font-weight:500}.FaqSlice2021Q4__itemContent___2MGzx{color:#d6e3fd}.FaqSlice2021Q4__itemContent___2MGzx,.FaqSlice2021Q4__itemContent___2MGzx p{margin-top:16px}.FaqSlice2021Q4__link___Wglju{margin-top:8px}.FaqSlice2021Q4__button___1I9jG{margin:80px auto 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"questionsContainer": "FaqSlice2021Q4__questionsContainer___2n4OE",
	"inner": "FaqSlice2021Q4__inner___1BLV1",
	"title": "FaqSlice2021Q4__title___3fNW0",
	"list": "FaqSlice2021Q4__list___1Pelj",
	"item": "FaqSlice2021Q4__item___3UblR",
	"itemTitle": "FaqSlice2021Q4__itemTitle___v1-Rs",
	"itemContent": "FaqSlice2021Q4__itemContent___2MGzx",
	"link": "FaqSlice2021Q4__link___Wglju",
	"button": "FaqSlice2021Q4__button___1I9jG"
};
module.exports = ___CSS_LOADER_EXPORT___;
