// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrustpilotWidget__container___1yd4P{display:flex;align-items:center;text-decoration:none}.TrustpilotWidget__scoreString___lwTDP{margin-right:10px;font-size:18px}.TrustpilotWidget__scoreString___lwTDP.TrustpilotWidget__dark___3h3Q6{color:#fff}@media (max-width:719px){.TrustpilotWidget__scoreString___lwTDP{margin-right:5px;font-size:14px}}.TrustpilotWidget__reviewString___1Qt7B{margin-right:10px;font-size:16px}.TrustpilotWidget__reviewString___1Qt7B.TrustpilotWidget__dark___3h3Q6{color:#fff}@media (max-width:719px){.TrustpilotWidget__reviewString___1Qt7B{margin-right:5px;font-size:12px}}.TrustpilotWidget__logo___2vDmr{height:20px;margin-left:5px;transform:translateY(-1px)}@media (max-width:719px){.TrustpilotWidget__logo___2vDmr{width:60px;margin-left:0}}.TrustpilotWidget__starImage___2HAFA{height:20px;margin-right:10px}@media (max-width:719px){.TrustpilotWidget__starImage___2HAFA{width:80px;margin-right:5px}}.TrustpilotWidget__section___3_Lj2{display:flex;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "TrustpilotWidget__container___1yd4P",
	"scoreString": "TrustpilotWidget__scoreString___lwTDP",
	"dark": "TrustpilotWidget__dark___3h3Q6",
	"reviewString": "TrustpilotWidget__reviewString___1Qt7B",
	"logo": "TrustpilotWidget__logo___2vDmr",
	"starImage": "TrustpilotWidget__starImage___2HAFA",
	"section": "TrustpilotWidget__section___3_Lj2"
};
module.exports = ___CSS_LOADER_EXPORT___;
