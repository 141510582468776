<template>
  <div :class="$style.none"></div>
</template>

<script>
export default {
  name: 'PageSettings',
  props: {
    slice: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    if (this.slice) {
      throw new Error('PageSettings not removed from slice list')
    }
    return {}
  },
}
</script>

<style module>
.none {
  display: none;
}
</style>
