<template>
  <label
    v-if="label || hasSlot"
    data-luko-tracking="Label"
    :class="{
      [$style.root]: true,
      [$style['root--disabled']]: isDisabled,
    }"
  >
    <template v-if="label">{{ label }}</template>
    <slot v-else />
  </label>
</template>

<script>
export default {
  name: 'LkLabel',
  props: {
    isBusinessScope: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasSlot() {
      const slot = this.$slots.default

      return slot && slot.text
    },
  },
}
</script>

<style lang="scss" module>
.root {
  @include typo-sub-text;
  display: inline-block;
  margin-bottom: 8px;

  color: $gray-1000;

  cursor: pointer;
}

.root.root--business {
  margin-bottom: 4px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.root.root--disabled {
  cursor: not-allowed;
}
</style>
