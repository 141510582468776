<template>
  <section
    :class="$style.container"
    :style="backgroundStyles(primary.backgroundImageMobile.url, 'sm:150vw')"
  >
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Title :class="$style.title" :title-params="primary.title" />
        <span v-if="primary.tag" :class="$style.tag">{{ primary.tag }}</span>
        <RichText :text-params="primary.subtitle" :class="$style.subtitle" />
        <CtaBox
          :type="$store.state.variations.values.HomePageCTA.value"
          :text="primary.ctaText"
          :placeholder="primary.inputPlaceholder"
        />
        <TrustpilotWidget :class="$style.trustpilot" is-dark />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import TrustpilotWidget from '~/components/TrustpilotWidget'

import CtaBox from './CtaBox.vue'

import { backgroundStyles } from '@/mixins/backgroundStyles'

export default {
  name: 'HeroDefault',

  components: {
    CtaBox,
    Title,
    RichText,
    TrustpilotWidget,
  },

  mixins: [backgroundStyles],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: this.slice.items,
      primary: this.slice.primary,
    }
  },
  methods: {
    onError(e) {
      console.log(e)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: inherit;
  min-height: 780px;
  margin-top: -64px; // desktop header height
  padding: 0 24px;
  padding-bottom: 310px;

  background-color: #1450e6;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.inner {
  display: flex;
  min-height: 630px;
  padding-top: 120px;
}

.desc {
  max-width: 720px;
  margin: 0 auto;
}

.title {
  max-width: 725px;
  margin-bottom: 16px;

  color: white;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.subtitle {
  max-width: 650px;
  margin: 0;

  color: $bluko-100;
  p {
    font-size: 20px;
    line-height: 32px;
  }
}

.addressBarContainer {
  margin-top: 40px;
}

.trustpilot {
  @include typo-sub-text;
  display: flex;
  gap: 8px;
  margin-top: 40px;

  color: white;

  strong {
    color: white;
  }
}

.tag {
  @include typo-body;
  display: inline-block;
  margin-top: 10px;

  padding: 8px 16px;
  border-radius: 20px;

  background-color: $gold-500;

  transform: translateY(-10px);
}
</style>
