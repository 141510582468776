<template>
  <div>
    <ArrowButton
      v-if="ctaType === 'link' && primary.ctaText && primary.ctaLink.url"
      :href="primary.ctaLink.url"
      :is-outlined="isOutlined"
      :is-white="isWhite"
      :class="$style.button"
      color="bluko"
      target="_blank"
    >
      {{ primary.ctaText }}
    </ArrowButton>

    <ArrowButton
      v-else-if="ctaType === 'mailto' && primary.ctaText && primary.mailto"
      :simple-url="`mailto:${primary.mailto}`"
      :is-outlined="isOutlined"
      :is-white="isWhite"
      :class="$style.button"
    >
      {{ primary.ctaText }}
    </ArrowButton>

    <ArrowButton
      v-else-if="ctaType === 'intercom' && primary.ctaText"
      simple-url="#/"
      :is-outlined="isOutlined"
      :is-white="isWhite"
      :class="$style.button"
      @click="showIntercom"
    >
      {{ primary.ctaText }}
    </ArrowButton>
  </div>
</template>
<script>
import { isLoggedIn } from '~/mixins/isLoggedIn'
import ArrowButton from '~/components/ArrowButton'

export default {
  name: 'CtaBox',
  components: {
    ArrowButton,
  },

  mixins: [isLoggedIn],

  props: {
    primary: {
      type: Object,
      required: true,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ctaType() {
      return this.primary.ctaType || 'link'
    },
  },

  methods: {
    showIntercom() {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        window.Didomi.notice.show()
      }
    },
  },
}
</script>

<style lang="scss" module>
.button {
  @include below(small) {
    width: 100%;
  }
}
</style>
