// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaCarouselDesktop__container___3_nUB{max-width:1120px;margin:0 auto;padding:128px 32px 160px}.MediaCarouselDesktop__inner___2nHaL{display:flex}.MediaCarouselDesktop__title___2kT98{color:inherit;font-weight:500;font-size:48px;line-height:64px;margin-bottom:36px;font-weight:200}.MediaCarouselDesktop__imageContainer___2X25-{position:relative;flex:1;text-align:center}.MediaCarouselDesktop__imageFront___3-jbQ{z-index:2}.MediaCarouselDesktop__imageBack___2OE4i,.MediaCarouselDesktop__imageFront___3-jbQ{position:absolute;bottom:10%;left:0;width:calc(min(480px, 40vw));height:calc(min(480px, 40vw))}.MediaCarouselDesktop__descriptionContainer___1oPvh{flex:1;max-width:452px;margin-bottom:80px}.MediaCarouselDesktop__description___2PKMB{color:#aaa8a1}.MediaCarouselDesktop__description___2PKMB h3{font-weight:500;font-size:22px;line-height:32px}.MediaCarouselDesktop__description___2PKMB p{font-size:18px;line-height:24px}.MediaCarouselDesktop__description___2PKMB.MediaCarouselDesktop__active___IkbOH h3{color:#000}.MediaCarouselDesktop__description___2PKMB.MediaCarouselDesktop__active___IkbOH p{color:#6b6651}.MediaCarouselDesktop__item___35ZWx{display:flex;margin-bottom:40px;cursor:pointer}.MediaCarouselDesktop__item___35ZWx:last-child{margin-bottom:0}.MediaCarouselDesktop__progressBar___21l5n{flex-shrink:0;margin-right:36px}.MediaCarouselDesktop__ctaContainer___2O9Ex{display:flex;justify-content:center;margin-top:60px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "MediaCarouselDesktop__container___3_nUB",
	"inner": "MediaCarouselDesktop__inner___2nHaL",
	"title": "MediaCarouselDesktop__title___2kT98",
	"imageContainer": "MediaCarouselDesktop__imageContainer___2X25-",
	"imageFront": "MediaCarouselDesktop__imageFront___3-jbQ",
	"imageBack": "MediaCarouselDesktop__imageBack___2OE4i",
	"descriptionContainer": "MediaCarouselDesktop__descriptionContainer___1oPvh",
	"description": "MediaCarouselDesktop__description___2PKMB",
	"active": "MediaCarouselDesktop__active___IkbOH",
	"item": "MediaCarouselDesktop__item___35ZWx",
	"progressBar": "MediaCarouselDesktop__progressBar___21l5n",
	"ctaContainer": "MediaCarouselDesktop__ctaContainer___2O9Ex"
};
module.exports = ___CSS_LOADER_EXPORT___;
