var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('Wrapper',{class:_vm.$style.inner},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.sectionOnScreen,
        intersection: {
          threshold: 1,
        },
      }),expression:"{\n        callback: sectionOnScreen,\n        intersection: {\n          threshold: 1,\n        },\n      }"}],class:_vm.$style.content},[_c('div',{class:_vm.$style.view},[_vm._l((_vm.items),function(item,itemIndex){return [(item.video && item.video.url)?[_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive(itemIndex)),expression:"isActive(itemIndex)"}],key:'video-' + itemIndex,ref:'video-' + itemIndex,refInFor:true,class:[_vm.$style.mediaContainer],attrs:{"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":item.video.url,"type":"video/mp4"}})])]:_vm._e(),_vm._v(" "),(item.image && item.image.url)?[_c('PImage',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive(itemIndex)),expression:"isActive(itemIndex)"}],key:'image-' + itemIndex,ref:'image-' + itemIndex,refInFor:true,class:[_vm.$style.mediaContainer],attrs:{"image-object":item.image}})]:_vm._e()]})],2),_vm._v(" "),_c('div',{class:_vm.$style.items},_vm._l((_vm.items),function(item,itemIndex){
      var _obj, _obj$1;
return _c('div',{key:itemIndex,class:[_vm.$style.item, ( _obj = {}, _obj[_vm.$style.itemActive] = _vm.isActive(itemIndex), _obj )],on:{"click":function($event){return _vm.setActiveIndex(itemIndex)},"mouseenter":function($event){return _vm.onMouseEnter(itemIndex)},"mouseleave":function($event){return _vm.onMouseLeave(itemIndex)}}},[_c('div',{class:_vm.$style.iconContainer},[_c('RadialProgressBar',{class:_vm.$style.circleProgress,attrs:{"completed-steps":_vm.getProgress(itemIndex),"animate-speed":_vm.getProgressSpeed(itemIndex),"diameter":68,"total-steps":100,"inner-stroke-color":"transparent","stroke-width":4}}),_vm._v(" "),_c('div',{class:[_vm.$style.itemIcon, ( _obj$1 = {}, _obj$1[_vm.$style.itemActive] = _vm.isActive(itemIndex), _obj$1 )]},[_vm._v("\n              "+_vm._s(itemIndex + 1)+"\n            ")])],1),_vm._v(" "),_c('div',{class:_vm.$style.infos},[_c('Title',{class:_vm.$style.info_title,attrs:{"title-params":item.title,"is-headline-bold":""}}),_vm._v(" "),_c('RichText',{class:_vm.$style.info_description,attrs:{"text-params":item.description}})],1)])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }