// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__container___d0gxO{display:none;background-color:#2d50e6}@media (min-width:720px){.index__container___d0gxO{display:block}}.index__inner___1YUAS{display:flex;flex-wrap:wrap;grid-gap:32px 75px;gap:32px 75px;justify-content:center;margin:0 auto;padding:8px 24px}.index__image___1fCZu{height:64px;-o-object-fit:contain;object-fit:contain}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "index__container___d0gxO",
	"inner": "index__inner___1YUAS",
	"image": "index__image___1fCZu"
};
module.exports = ___CSS_LOADER_EXPORT___;
