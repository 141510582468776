// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LukofferCtaDefault__container___10hBn{margin-top:128px;padding:24px 32px;border-radius:8px;background-color:#122182}.LukofferCtaDefault__titleContainer___2-BjZ{display:flex}.LukofferCtaDefault__description___ZtNPs{padding-bottom:32px;color:#fff}.LukofferCtaDefault__description___ZtNPs ul{padding-top:0;list-style:none}.LukofferCtaDefault__description___ZtNPs ul li{padding-left:32px}.LukofferCtaDefault__description___ZtNPs ul li:before{padding-left:12px;content:url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='7' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.293.96a1 1 0 000 1.414L3.919 6 .293 9.626a1 1 0 101.414 1.414L6.04 6.707a1 1 0 000-1.414L1.707.96a1 1 0 00-1.414 0z' fill='%23FCE1D2'/%3E%3C/svg%3E\")}.LukofferCtaDefault__checkIcon___pS4al{width:32px;height:32px;margin-right:16px;padding:8px;border-radius:50%;background-color:#2d50e6}.LukofferCtaDefault__title___3CwIb{color:inherit;font-weight:500;font-size:20px;line-height:32px;color:#fce1d2}.LukofferCtaDefault__ctaContainer___1mTg4{display:flex;align-items:center;justify-content:space-between}@media (max-width:719px){.LukofferCtaDefault__ctaContainer___1mTg4{flex-direction:column-reverse;grid-gap:20px;gap:20px;align-items:start}}.LukofferCtaDefault__link___1gG2N{color:#acbef7;font-size:14px;line-height:20px}.LukofferCtaDefault__link___1gG2N path{fill:#acbef7}@media (max-width:719px){.LukofferCtaDefault__button___mBEoK{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "LukofferCtaDefault__container___10hBn",
	"titleContainer": "LukofferCtaDefault__titleContainer___2-BjZ",
	"description": "LukofferCtaDefault__description___ZtNPs",
	"checkIcon": "LukofferCtaDefault__checkIcon___pS4al",
	"title": "LukofferCtaDefault__title___3CwIb",
	"ctaContainer": "LukofferCtaDefault__ctaContainer___1mTg4",
	"link": "LukofferCtaDefault__link___1gG2N",
	"button": "LukofferCtaDefault__button___mBEoK"
};
module.exports = ___CSS_LOADER_EXPORT___;
