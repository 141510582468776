// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___3SWRP .CtaBox__emailField___Z-kvI input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___3SWRP .CtaBox__emailField___Z-kvI input::-moz-placeholder{color:#fff}.CtaBox__isDark___3SWRP .CtaBox__emailField___Z-kvI input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___3SWRP .CtaBox__emailField___Z-kvI input::placeholder{color:#fff}.CtaBox__isDark___3SWRP .CtaBox__emailField___Z-kvI input:hover{border-color:#829af2}.CtaBox__isDark___3SWRP .CtaBox__emailField___Z-kvI input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___1Dsvh{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___1Dsvh .CtaBox__button___3QWbd{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___1Dsvh{flex-direction:column;max-width:300px}.CtaBox__emailContainer___1Dsvh .CtaBox__emailField___Z-kvI{width:100%}}.CtaBox__appsContainer___1HDFS{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___1HDFS{justify-content:center}}.CtaBox__appImage___2ZCvZ{border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___2ZCvZ{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___3SWRP",
	"emailField": "CtaBox__emailField___Z-kvI",
	"emailContainer": "CtaBox__emailContainer___1Dsvh",
	"button": "CtaBox__button___3QWbd",
	"appsContainer": "CtaBox__appsContainer___1HDFS",
	"appImage": "CtaBox__appImage___2ZCvZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
