// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___1Pqha .CtaBox__emailField___mbP4g input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___1Pqha .CtaBox__emailField___mbP4g input::-moz-placeholder{color:#fff}.CtaBox__isDark___1Pqha .CtaBox__emailField___mbP4g input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___1Pqha .CtaBox__emailField___mbP4g input::placeholder{color:#fff}.CtaBox__isDark___1Pqha .CtaBox__emailField___mbP4g input:hover{border-color:#829af2}.CtaBox__isDark___1Pqha .CtaBox__emailField___mbP4g input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___zKrpV{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___zKrpV .CtaBox__button___1OZjC{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___zKrpV{flex-direction:column;max-width:300px}.CtaBox__emailContainer___zKrpV .CtaBox__emailField___mbP4g{width:100%}}.CtaBox__appsContainer___3PMai{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___3PMai{justify-content:center}}.CtaBox__appImage___19mt4{max-width:152px;border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___19mt4{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}.CtaBox__ctaButton___1dAil{color:#2d50e6!important}.CtaBox__ctaButton___1dAil:hover{background-color:#d6e3fd!important}@media (max-width:959px){.CtaBox__ctaButton___1dAil{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___1Pqha",
	"emailField": "CtaBox__emailField___mbP4g",
	"emailContainer": "CtaBox__emailContainer___zKrpV",
	"button": "CtaBox__button___1OZjC",
	"appsContainer": "CtaBox__appsContainer___3PMai",
	"appImage": "CtaBox__appImage___19mt4",
	"ctaButton": "CtaBox__ctaButton___1dAil"
};
module.exports = ___CSS_LOADER_EXPORT___;
