// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__waitlist___2t_zg{display:flex;grid-gap:8px;gap:8px;align-items:flex-start;width:100%}@media (max-width:539px){.CtaBox__waitlist___2t_zg{flex-direction:column}.CtaBox__waitlist___2t_zg button{width:100%}}.CtaBox__appsContainer___1QXmI{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___1QXmI{justify-content:center}}.CtaBox__appImage___13dZQ{border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___13dZQ{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"waitlist": "CtaBox__waitlist___2t_zg",
	"appsContainer": "CtaBox__appsContainer___1QXmI",
	"appImage": "CtaBox__appImage___13dZQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
