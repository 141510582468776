<template>
  <div :class="$style.container">
    <Title :title-params="primary.title" :class="$style.title" />
    <RichText :text-params="primary.description" :class="$style.description" />
    <form :class="$style.form" @submit.prevent="onSubmit">
      <LkEmailField v-model="email" :placeholder="primary.placeholder" :class="$style.emailField" :error="errorMessage" @error="onEmailError" />
      <LkButton :class="$style.button" :is-loading="isLoading" type="submit">
        {{ primary.ctaText }}
      </LkButton>
    </form>
  </div>
</template>

<script>
import { delay } from '@fxts/core'

import LkEmailField from '~/components/LkEmailField'
import { COUNTRIES } from '~/utils/constants.js'

export default {
  name: 'NewsLetterDefault',
  components: { LkEmailField },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      hasError: false,
      errorMessage: '',
      email: '',
      isLoading: false,
    }
  },
  methods: {
    onEmailError(hasError) {
      this.hasError = hasError
    },
    onSubmit() {
      if (this.hasError || !this.email) return (this.errorMessage = this.$t('Error.invalidEmail'))
      this.errorMessage = ''
      const categoryUid = this.$store.state.categoryUid

      this.sendWaitlistToBraze(this.email, categoryUid)
    },
    async sendWaitlistToBraze(email, categoryUid) {
      const notification = {
        type: 'success',
        text: this.$t('Newsletter.success'),
      }
      try {
        this.isLoading = true
        await delay(1000)

        window.LukoTracking.trackEvent({
          id: `Newsletter Request`,
          properties: {
            subscriber: {
              email,
              category: Object.values(this.$store.state.adviceCategoryData).find((c) => c.uid === categoryUid)?.category_name || '',
              uid: this.$route.params.uid,
              lang: this.$i18n.localeProperties.iso,
              country: COUNTRIES[process.env.CURRENT_DOMAIN],
            },
          },
        })
      } catch (e) {
        console.error(e)
        notification.type = 'danger'
        notification.text = this.$t('Newsletter.fail')
      } finally {
        this.isLoading = false
        this.$store.commit('notification/SET_NOTIFICATION', notification)
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 80px 0 0;
  padding: 24px 32px;
  border: 1px solid $bluko-500;
  border-radius: 8px;
}

.title {
  @include typo-headline-bold;

  color: $gray-1000;
  font-size: 20px;
  line-height: 32px;
}

.description {
  @include typo-body;

  margin-top: 4px;
  margin-bottom: 24px;

  color: $gray-700;
}

.form {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  @include below(small) {
    flex-direction: column;
    align-items: unset;
  }
}
.button {
  flex-shrink: 0;
}
</style>
