<template>
  <section :class="$style.container">
    <Title :title-params="primary.title" :class="$style.title" />
    <Wrapper :class="$style.inner">
      <div v-for="(item, index) in items" :key="index" :class="$style.item">
        <div :class="$style.imageContainer">
          <PImage
            :image-object="item.image"
            :class="$style.logo"
            sizes="xs:200px"
          />
        </div>
        <RichText :text-params="item.description" :class="$style.description" />
      </div>
    </Wrapper>
    <Wrapper :class="$style.ctaContainer">
      <ArrowButton
        v-if="primary.ctaLink"
        :href="getFormatedLink(primary.ctaLink)"
        :target="primary.ctaLink.target"
        is-outlined
        is-white
        :class="$style.ctaButton"
      >
        {{ primary.ctaText }}
      </ArrowButton>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import ArrowButton from '~/components/ArrowButton'

export default {
  name: 'CertificationSection',
  components: { Title, RichText, ArrowButton },
  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 80px 100px;

  background-color: $bluko-800;
  @include below(small) {
    padding: 40px 22px;
  }
}
.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

.description {
  color: white;
  font-size: 14px;
  line-height: 20px;
  @include below(small) {
    text-align: center;
  }
}

.item {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 2fr;
  align-content: center;
  width: 400px;
  min-width: 400px;
  max-width: 400px;

  @include below(small) {
    grid-template-columns: 1fr;
    gap: 20px;
    min-width: 300px;
  }
}
.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ctaContainer {
  margin-top: 40px;

  text-align: center;
}
.ctaButton {
  margin: 0 auto;
  @include below(small) {
    width: 100%;
  }
}
.title {
  @include typo-large-title;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 50px;

  color: white;
  font-weight: 200;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
}
.logo {
  max-width: 100px;
}
</style>
