// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LkLabel__root___3ec2m{color:inherit;font-weight:400;font-size:14px;line-height:20px;display:inline-block;margin-bottom:8px;color:#2c2302;cursor:pointer}.LkLabel__root___3ec2m.LkLabel__root--business___2Rpbq{margin-bottom:4px;font-weight:500;font-size:12px;line-height:16px}.LkLabel__root___3ec2m.LkLabel__root--disabled___4V6fE{cursor:not-allowed}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"root": "LkLabel__root___3ec2m",
	"root--business": "LkLabel__root--business___2Rpbq",
	"root--disabled": "LkLabel__root--disabled___4V6fE"
};
module.exports = ___CSS_LOADER_EXPORT___;
