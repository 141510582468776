<template>
  <section
    :class="$style.container"
    :style="backgroundStyles(primary.backgroundImage.url, 'lg:200vw')"
  >
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Title :class="$style.title" :title-params="primary.title" />
        <span v-if="primary.tag" :class="$style.tag">{{ primary.tag }}</span>
        <RichText :text-params="primary.subtitle" :class="$style.subtitle" />
        <CtaBox
          :type="$store.state.variations.values.HomePageCTA.value"
          :text="primary.ctaText"
          :placeholder="primary.inputPlaceholder"
        />
        <TrustpilotWidget :class="$style.trustpilot" is-dark />
      </div>
    </Wrapper>
    <Wrapper v-if="items.length" :class="$style.itemsContainer">
      <div v-for="(item, index) in items" :key="index">
        <RichText :text-params="item.text" :class="$style.itemText" />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import TrustpilotWidget from '~/components/TrustpilotWidget'

import CtaBox from './CtaBox.vue'

import { backgroundStyles } from '@/mixins/backgroundStyles'

export default {
  name: 'HeroDefault',

  components: {
    CtaBox,
    Title,
    RichText,
    TrustpilotWidget,
  },

  mixins: [backgroundStyles],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: this.slice.items.filter((i) => i.text.length),
      primary: this.slice.primary,
    }
  },
  methods: {
    onError(e) {
      console.log(e)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: inherit;
  min-height: 700px;
  margin-top: -64px;
  padding: 0 24px;

  background-color: #015ce3;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.inner {
  display: flex;
  min-height: 630px;
  padding-top: 96px;

  @include above(small) {
    min-height: 680px;
    padding-top: 144px;
  }
}

.desc {
  max-width: 620px;
  margin: 0 auto;
  @include above(small) {
    margin: 0;
  }
}

.title {
  max-width: 725px;
  margin-bottom: 16px;

  color: white;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;

  @include above(small) {
    font-size: 52px;
    line-height: 64px;
  }
}

.subtitle {
  max-width: 650px;
  margin: 0;

  color: white;
  p {
    font-weight: 200;
    font-size: 30px;
    line-height: 36px;
  }
}

.addressBarContainer {
  margin-top: 80px;
}

.trustpilot {
  margin-top: 24px;
}

.tag {
  @include typo-body;
  display: inline-block;
  margin-top: 10px;

  padding: 8px 16px;
  border-radius: 20px;

  background-color: $orange-100;

  transform: translateY(-10px);
}

.itemsContainer {
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid white;
}
.itemText {
  padding: 20px 0;

  color: white;
  h2,
  h3 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }
  p {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
</style>
