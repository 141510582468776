// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroDesktop__container___1dBzo{max-width:inherit;min-height:700px;margin-top:-64px;padding:0 24px;background-color:#015ce3;background-repeat:no-repeat;background-position:50%;background-size:cover}.HeroDesktop__inner___3qs8V{display:flex;min-height:630px;padding-top:96px}@media (min-width:720px){.HeroDesktop__inner___3qs8V{min-height:680px;padding-top:144px}}.HeroDesktop__desc___1X9VW{max-width:620px;margin:0 auto}@media (min-width:720px){.HeroDesktop__desc___1X9VW{margin:0}}.HeroDesktop__title___1ory3{max-width:725px;margin-bottom:16px;color:#fff;font-weight:500;font-size:32px;line-height:48px}@media (min-width:720px){.HeroDesktop__title___1ory3{font-size:52px;line-height:64px}}.HeroDesktop__subtitle___3_Spi{max-width:650px;margin:0;color:#fff}.HeroDesktop__subtitle___3_Spi p{font-weight:200;font-size:30px;line-height:36px}.HeroDesktop__addressBarContainer___1gxwF{margin-top:80px}.HeroDesktop__trustpilot___YXr44{margin-top:24px}.HeroDesktop__tag___hTrEw{color:inherit;font-weight:400;font-size:16px;line-height:24px;display:inline-block;margin-top:10px;padding:8px 16px;border-radius:20px;background-color:#fce1d2;transform:translateY(-10px)}.HeroDesktop__itemsContainer___3eUWm{display:flex;justify-content:space-evenly;border-top:1px solid #fff}.HeroDesktop__itemText___7D7I3{padding:20px 0;color:#fff}.HeroDesktop__itemText___7D7I3 h2,.HeroDesktop__itemText___7D7I3 h3{font-size:22px;line-height:24px;text-align:center}.HeroDesktop__itemText___7D7I3 p{font-weight:200;font-size:16px;line-height:24px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HeroDesktop__container___1dBzo",
	"inner": "HeroDesktop__inner___3qs8V",
	"desc": "HeroDesktop__desc___1X9VW",
	"title": "HeroDesktop__title___1ory3",
	"subtitle": "HeroDesktop__subtitle___3_Spi",
	"addressBarContainer": "HeroDesktop__addressBarContainer___1gxwF",
	"trustpilot": "HeroDesktop__trustpilot___YXr44",
	"tag": "HeroDesktop__tag___hTrEw",
	"itemsContainer": "HeroDesktop__itemsContainer___3eUWm",
	"itemText": "HeroDesktop__itemText___7D7I3"
};
module.exports = ___CSS_LOADER_EXPORT___;
