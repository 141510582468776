<template>
  <div>
    <MediaCarouselDefault :slice="slice" />
  </div>
</template>

<script>
import MediaCarouselDefault from './default-slice/MediaCarouselDefault.vue'

export default {
  name: 'MediaCarousel',
  components: {
    MediaCarouselDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
