// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__carousel___3Y3ht{--carousel-items:0;--carousel-item-width:220px;display:none;width:calc(var(--carousel-items)*var(--carousel-item-width)*2 + 4.1px);margin-top:55px;-webkit-animation:index__scroll___1Qrjl 50s linear infinite;animation:index__scroll___1Qrjl 50s linear infinite}.index__container___RX4yK{margin:0;padding:64px 0;overflow-x:hidden;background-color:#ffede3}@media (max-width:719px){.index__container___RX4yK{margin:0;padding:40px 0}}.index__title___PH3en{max-width:1036px;margin:0 auto 16px;padding:0 24px;font-weight:200;font-size:48px;line-height:55px;text-align:center}@media (max-width:719px){.index__title___PH3en{font-size:40px;line-height:48px}}.index__description___pgWU_{color:inherit;font-weight:400;font-size:16px;line-height:24px;max-width:1036px;margin:10px auto;padding:0 24px;text-align:center}.index__logoContainer___3c9Le{display:inline-block;width:var(--carousel-item-width);margin:0;padding:0;text-align:center}.index__logo___f-rAx{max-width:var(--carousel-item-width);height:80px;margin:0;padding:0}.index__carouselWrapper___3DMWU{min-height:100px;overflow:hidden}@-webkit-keyframes index__scroll___1Qrjl{0%{transform:translateX(0)}to{transform:translateX(calc(var(--carousel-items)*-1*var(--carousel-item-width)))}}@keyframes index__scroll___1Qrjl{0%{transform:translateX(0)}to{transform:translateX(calc(var(--carousel-items)*-1*var(--carousel-item-width)))}}.index__ctaContainer___3QoFK{margin-top:24px;padding:0 24px;text-align:center}@media (max-width:719px){.index__ctaButton___2Gdgv{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"carousel": "index__carousel___3Y3ht",
	"scroll": "index__scroll___1Qrjl",
	"container": "index__container___RX4yK",
	"title": "index__title___PH3en",
	"description": "index__description___pgWU_",
	"logoContainer": "index__logoContainer___3c9Le",
	"logo": "index__logo___f-rAx",
	"carouselWrapper": "index__carouselWrapper___3DMWU",
	"ctaContainer": "index__ctaContainer___3QoFK",
	"ctaButton": "index__ctaButton___2Gdgv"
};
module.exports = ___CSS_LOADER_EXPORT___;
