<template>
  <div
    :class="$style.container"
    :style="{
      backgroundColor: randomColor,
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <span v-if="!image" :class="$style.initial">{{ initial }}</span>
    <img v-else :src="image" :class="$style.image" />
  </div>
</template>

<script>
export default {
  name: 'ProfileThumbnail',
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 80,
    },
  },
  data() {
    return {}
  },
  computed: {
    initial() {
      if (!this.name) return ''

      const initials = this.name.split(' ')
      if (initials.length === 1) {
        return initials[0].charAt(0).toUpperCase() + initials[0].charAt(1).toUpperCase()
      }
      return initials[0].charAt(0).toUpperCase() + initials[1].charAt(0).toUpperCase()
    },
    randomColor() {
      const colors = ['#b72b2b', '#2bb756', '#b72bac', '#2b43b7', '#d9bf0a']

      return colors[Math.floor(Math.random() * colors.length)]
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 50%;

  background-color: $bluko-500;
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 50% 50%;
}
.initial {
  color: white;
  font-weight: bold;
}
</style>
