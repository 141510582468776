<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner">
      <div :class="$style.primaryContainer">
        <Title :title-params="primary.title" :class="$style.title" />
        <RichText :text-params="primary.description" :class="$style.description" />
        <ArrowButton v-if="primary.ctaText" is-outlined is-white :href="getFormatedLink(primary.ctaLink)" :class="$style.button">
          {{ primary.ctaText }}
        </ArrowButton>
      </div>
      <div :class="$style.itemsContainer">
        <ul :class="[$style.iconsContainer, { [$style.icon3x3Grid]: items.length > 2 }]">
          <li
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="$style.item"
            data-aos="zoom-in"
            :data-aos-delay="itemDelays[itemIndex]"
            data-aos-duration="150"
            data-aos-mirror="false"
          >
            <PImage :image-object="item.icon" sizes="xs:100px" :class="$style.icon" />
            <Title :title-params="item.title" :class="$style.itemTitle" />
            <RichText :text-params="item.description" :class="$style.itemDescription" />
          </li>
        </ul>
        <RichText :text-params="primary.iconsSubtext" :class="$style.iconsSubtext" />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import score from '@@/script/trustpilot/score.json'
import { pipe, range, toArray, map } from '@fxts/core'

import ArrowButton from '~/components/ArrowButton'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'HighlightIconsDefault',
  components: {
    Title,
    RichText,
    ArrowButton,
  },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      itemDelays: pipe(
        range(this.slice.items.length),
        map((a) => (a + 1) * 100),
        toArray,
        (arr) => {
          for (let i = 0; i < arr.length / 2; i++) {
            const randomIdx = Math.floor(Math.random() * this.slice.items.length)
            const tmp = arr[i]
            arr[i] = arr[randomIdx]
            arr[randomIdx] = tmp
          }
          return arr
        }
      ),
      score,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 80px 0;

  background-color: #122182;
}
.inner {
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  @include below(medium) {
    flex-direction: column;
    gap: 60px;
  }
}
.primaryContainer {
  flex: 1;
  max-width: 550px;
  @include below(medium) {
    max-width: none;
  }
}
.itemsContainer {
  flex: 1;
}

.iconsContainer {
  display: grid;
  flex: 1;
  gap: 40px;
  justify-content: space-between;
  max-width: 600px;
  @include below(medium) {
    max-width: none;
  }
  @include below(small) {
    grid-template-columns: 1fr 1fr;
  }
}

.icon3x3Grid {
  grid-template-columns: 1fr 1fr 1fr;
  @include below(small) {
    grid-template-columns: 1fr 1fr;
  }
}

.button {
  margin-top: 60px;
  @include below(medium) {
    width: 100%;
  }
}
.title {
  color: white;
  font-weight: 200;
  font-size: 48px;
  line-height: 58px;
  @include below(small) {
    font-size: 32px;
    line-height: 42px;
  }
}
.description {
  color: white;
}
.item {
  flex: 1;

  text-align: center;
}
.icon {
  width: 56px;
  height: 56px;
  margin-bottom: 8px;
}
.itemTitle {
  margin-bottom: 8px;

  color: white;
  font-size: 16px;
  line-height: 24px;
}
.itemDescription {
  p {
    color: white;
    font-size: 14px;
    line-height: 20px;

    opacity: 70%;
  }
}

.iconsSubtext {
  margin-top: 40px;

  p {
    @include typo-sub-text;
    color: white;

    text-align: center;
  }
}
</style>
