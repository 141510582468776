<template>
  <section class="section" data-luko-tracking="LukofferCta">
    <LukofferCtaDefault :slice="slice" />
  </section>
</template>

<script>
import LukofferCtaDefault from './default-slice'
export default {
  name: 'LukofferCta',
  components: {
    LukofferCtaDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
