<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner" data-aos="fade-up">
      <div
        :class="[
          [$style.content],
          { [$style.isReverse]: primary.imagePosition },
        ]"
      >
        <div :class="$style.view">
          <PImage
            loading="lazy"
            :image-object="primary.image"
            :class="$style.image"
            sizes="xs:600px"
            :quality="80"
          />
        </div>
        <div :class="$style.contentContainer">
          <Title
            v-if="primary.title"
            is-large-title
            :title-params="primary.title"
          />
          <RichText
            :text-params="primary.description"
            :class="$style.description"
          />
          <div v-for="(item, index) in items" :key="index" :class="$style.item">
            <PImage :image-object="item.icon" :class="$style.itemIcon" />
            <RichText :text-params="item.text" :class="$style.itemText" />
          </div>
          <CtaBox :primary="primary" />
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import PImage from '~/components/PImage'

import CtaBox from '../components/CtaBox'

export default {
  name: 'CustomerServiceDefault',

  components: {
    RichText,
    Title,
    PImage,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 0 24px;
}

.inner {
  margin-top: 128px;
  margin-bottom: 60px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;

  @include above(small) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.isReverse {
      flex-direction: row-reverse;
    }
  }

  @include above(small) {
    column-gap: 32px;
  }
  @include above(large) {
    column-gap: 40px;
  }
}

.contentContainer {
  flex-basis: 0;
  flex-grow: 1;

  a {
    margin-top: 40px;
  }

  @include above(small) {
    margin-top: 0;
  }
  @include above(medium) {
    padding: 0 73px;
  }
}

.description p {
  margin-top: 16px;
}

.view {
  flex-basis: 0;
  flex-grow: 1;

  img {
    display: block;
    width: 100%;
  }

  @include above(medium) {
    flex-basis: 15%;
    max-width: 620px;
  }
}

.item {
  display: flex;
  gap: 14px;
  margin-top: 8px;

  color: $gray-700;
}

.image {
  border-radius: 8px;
}
</style>
