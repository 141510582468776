// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__container___UgJXZ{padding:80px 100px;background-color:#122182}@media (max-width:719px){.index__container___UgJXZ{padding:40px 22px}}.index__inner___2kCPb{display:flex;flex-wrap:wrap;grid-gap:40px;gap:40px;justify-content:center}.index__description___dcX8p{color:#fff;font-size:14px;line-height:20px}@media (max-width:719px){.index__description___dcX8p{text-align:center}}.index__item___24Oky{display:grid;flex:1;grid-template-columns:1fr 2fr;align-content:center;width:400px;min-width:400px;max-width:400px}@media (max-width:719px){.index__item___24Oky{grid-template-columns:1fr;grid-gap:20px;gap:20px;min-width:300px}}.index__imageContainer___3-6Oc{display:flex;align-items:center;justify-content:center}.index__ctaContainer___3AMOO{margin-top:40px;text-align:center}.index__ctaButton___1QGkv{margin:0 auto}@media (max-width:719px){.index__ctaButton___1QGkv{width:100%}}.index__title___3aJdM{color:inherit;font-weight:500;font-size:32px;line-height:48px;max-width:700px;margin:0 auto 50px;color:#fff;font-weight:200;font-size:40px;line-height:45px;text-align:center}.index__logo___3lWiD{max-width:100px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "index__container___UgJXZ",
	"inner": "index__inner___2kCPb",
	"description": "index__description___dcX8p",
	"item": "index__item___24Oky",
	"imageContainer": "index__imageContainer___3-6Oc",
	"ctaContainer": "index__ctaContainer___3AMOO",
	"ctaButton": "index__ctaButton___1QGkv",
	"title": "index__title___3aJdM",
	"logo": "index__logo___3lWiD"
};
module.exports = ___CSS_LOADER_EXPORT___;
