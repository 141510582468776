// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArrowButton__button___3vtag{transition:background-color .2s ease-in-out}.ArrowButton__button___3vtag:hover .ArrowButton__icon___xYn4B{transform:translateX(5px)}.ArrowButton__icon___xYn4B{transition:transform .2s ease-in-out}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"button": "ArrowButton__button___3vtag",
	"icon": "ArrowButton__icon___xYn4B"
};
module.exports = ___CSS_LOADER_EXPORT___;
