<template>
  <div :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="primary.title" :class="$style.title" />
      <div :class="$style.items">
        <template v-for="(item, itemIndex) in items">
          <div v-if="itemIndex % 2" :key="'seperator_' + itemIndex" :class="$style.seperator"></div>
          <div :key="itemIndex" :class="[hasSecondIllustration(item) ? $style.flex2 : $style.flex1]">
            <Title :title-params="item.title" :class="$style.item_title" />
            <RichText :text-params="item.description" :class="$style.description" />
            <Title :title-params="item.illustrationsTitle" :class="$style.illustration_title" />
            <div :class="$style.illustrations_container">
              <div :class="$style.illustration_container">
                <PImage v-if="item.illustration" :image-object="item.illustration" :class="$style.illustration" sizes="xs:350px" />
                <RichText :text-params="item.illustrationDescription" :class="$style.illustration_description" />
              </div>
              <div v-if="hasSecondIllustration(item)" :class="$style.illustration_container">
                <PImage :image-object="item.illustration2" :class="$style.illustration" sizes="xs:350px" />
                <RichText :text-params="item.illustration2Description" :class="$style.illustration_description" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </Wrapper>
  </div>
</template>

<script>
export default {
  name: 'RefundSchema',

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },

  methods: {
    hasSecondIllustration(item) {
      return item.illustration2 && item.illustration2.url
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 128px 156px;

  @include below(small) {
    padding: 128px 24px;
  }
}

.inner {
  max-width: 1150px;

  text-align: center;
}

.title {
  @include typo-mega-title;
  font-weight: 200;

  @include below(medium) {
    @include typo-large-title;
    font-weight: 200;
  }
}

.items {
  display: flex;
  gap: 100px;
  justify-content: center;
  margin-top: 40px;

  @include below(large) {
    flex-direction: column;
    gap: 80px;
    align-items: center;
    max-width: 500px;
    margin: 40px auto;
  }
}

.item_title p {
  @include typo-title;

  @include below(medium) {
    @include typo-headline-bold;
  }
}

.flex1 {
  flex: 1.2;
}

.flex2 {
  flex: 2;
}

.seperator {
  align-items: stretch;
  width: 0px;
  border: 1px solid $gray-75;
  @include below(large) {
    display: none;
  }
}

.illustrations_container {
  display: flex;
  justify-content: space-evenly;
  @include below(small) {
    flex-direction: column;
    gap: 32px;
  }
}

.illustration {
  width: 282px;
  height: 293px;
}

.illustration_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  margin-top: 8px;
  margin-bottom: 40px;

  color: $gray-700;

  b,
  strong {
    color: $gray-1000;
  }
}
.illustration_title {
  color: $gray-700;
}
.illustration_description {
  max-width: 208px;

  color: $gray-500;
}
</style>
