<template>
  <div v-observe-visibility="isVisible">
    <HeroLaunchDarkblue v-if="slice.variation === 'darkblue'" :slice="slice" />
    <HeroLaunchPastelBlue
      v-else-if="slice.variation === 'pastelblue'"
      :slice="slice"
    />
    <HeroLaunchSimple v-else-if="slice.variation === 'simple'" :slice="slice" />
    <HeroLaunchImageFull
      v-else-if="slice.variation === 'imageFull'"
      :slice="slice"
    />
    <HeroLaunchDefault v-else :slice="slice" />
  </div>
</template>

<script>
import HeroLaunchDefault from './default-slice'
import HeroLaunchDarkblue from './darkblue'
import HeroLaunchPastelBlue from './pastelblue'
import HeroLaunchSimple from './simple'
import HeroLaunchImageFull from './imageFull'

export default {
  name: 'HeroLaunch',
  components: {
    HeroLaunchDefault,
    HeroLaunchDarkblue,
    HeroLaunchPastelBlue,
    HeroLaunchSimple,
    HeroLaunchImageFull,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style></style>
