<template>
  <section :class="$style.container">
    <Title :title-params="primary.title" :class="$style.title" />
    <RichText :text-params="primary.description" :class="$style.description" />
    <div :class="$style.ctaContainer">
      <ArrowButton
        v-if="primary.ctaText && primary.ctaLink && primary.ctaLink.url"
        is-outlined
        :href="getFormatedLink(primary.ctaLink)"
        :target="primary.ctaLink.target"
        :class="$style.ctaButton"
      >
        {{ primary.ctaText }}
      </ArrowButton>
    </div>
    <Wrapper :class="$style.carouselWrapper">
      <div ref="carousel" :class="$style.carousel">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="$style.logoContainer"
        >
          <PImage
            :image-object="item.logo"
            :class="$style.logo"
            loading="normal"
          />
        </div>
        <div
          v-for="(item, index) in items"
          :key="'second-' + index"
          :class="$style.logoContainer"
        >
          <PImage :image-object="item.logo" :class="$style.logo" />
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import ArrowButton from '~/components/ArrowButton'
import RichText from '~/components/RichText'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'

export default {
  name: 'PressListCarousel',
  components: {
    Title,
    RichText,
    ArrowButton,
  },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
  mounted() {
    const carousel = this.$refs.carousel
    carousel.style.display = 'block'
    carousel.style.setProperty('--carousel-items', this.items.length)
  },
}
</script>

<style lang="scss" module>
.carousel {
  --carousel-items: 0;
  --carousel-item-width: 220px;

  display: none;
  width: calc(
    var(--carousel-items) * var(--carousel-item-width) * 2 + 4.1px
  ); // I don't know why there is 4.1px

  margin-top: 55px;

  animation: scroll 50s linear infinite;
}

.container {
  margin: 0;
  padding: 64px 0;
  overflow-x: hidden;

  background-color: $orange-50;
  @include below(small) {
    margin: 0 0;
    padding: 40px 0;
  }
}
.title {
  max-width: 1036px;
  margin: 0 auto 16px;
  padding: 0 24px;

  font-weight: 200;

  font-size: 48px;
  line-height: 55px;
  text-align: center;
  @include below(small) {
    font-size: 40px;
    line-height: 48px;
  }
}
.description {
  @include typo-body;
  max-width: 1036px;
  margin: 10px auto;
  padding: 0 24px;

  text-align: center;
}
.logoContainer {
  display: inline-block;
  width: var(--carousel-item-width);
  margin: 0;
  padding: 0;

  text-align: center;
}
.logo {
  max-width: var(--carousel-item-width);
  height: 80px;
  margin: 0;
  padding: 0;
}
.carouselWrapper {
  min-height: 100px;
  overflow: hidden;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--carousel-items) * var(--carousel-item-width))
    );
  }
}
.ctaContainer {
  margin-top: 24px;
  padding: 0 24px;

  text-align: center;
}

.ctaButton {
  @include below(small) {
    width: 100%;
  }
}
</style>
