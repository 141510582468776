// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroConfirm2021Q4__heroContainer___2Tng2{min-height:500px;margin-top:-64px;padding:46px 80px;background-color:#fff4ee}@media (max-width:959px){.HeroConfirm2021Q4__heroContainer___2Tng2{padding:64px 24px 24px}}.HeroConfirm2021Q4__inner___kQUQI{display:flex;grid-gap:40px;gap:40px;align-items:flex-end;justify-content:space-between;height:100%;padding-top:56px}@media (max-width:959px){.HeroConfirm2021Q4__inner___kQUQI{flex-direction:column}}.HeroConfirm2021Q4__desc___3khOl{display:flex;flex:1;flex-direction:column;justify-content:center;min-height:450px}@media (max-width:959px){.HeroConfirm2021Q4__desc___3khOl{width:100%;min-height:auto}}.HeroConfirm2021Q4__view___1_z2W{flex:1;margin:0}.HeroConfirm2021Q4__breadcrumb___1VKmM{margin-bottom:16px}.HeroConfirm2021Q4__title___l5ULE{color:inherit;font-weight:500;font-size:48px;line-height:64px;display:inline;margin-top:16px;font-weight:200;line-height:59px;text-align:left}@media (max-width:719px){.HeroConfirm2021Q4__title___l5ULE{font-size:40px;line-height:56px}}.HeroConfirm2021Q4__subtitle___2sWbx p{color:inherit;font-weight:400;font-size:20px;margin-top:16px;color:#6b6651;line-height:32px}.HeroConfirm2021Q4__ctaContainer___3UnnY{margin-top:40px}@media (max-width:719px){.HeroConfirm2021Q4__ctaContainer___3UnnY,.HeroConfirm2021Q4__ctaContainer___3UnnY a{width:100%}}.HeroConfirm2021Q4__image___19TOA{display:block;width:100%}@media (max-width:959px){.HeroConfirm2021Q4__image___19TOA{height:400px}}@media (max-width:719px){.HeroConfirm2021Q4__image___19TOA{height:50vw}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "HeroConfirm2021Q4__heroContainer___2Tng2",
	"inner": "HeroConfirm2021Q4__inner___kQUQI",
	"desc": "HeroConfirm2021Q4__desc___3khOl",
	"view": "HeroConfirm2021Q4__view___1_z2W",
	"breadcrumb": "HeroConfirm2021Q4__breadcrumb___1VKmM",
	"title": "HeroConfirm2021Q4__title___l5ULE",
	"subtitle": "HeroConfirm2021Q4__subtitle___2sWbx",
	"ctaContainer": "HeroConfirm2021Q4__ctaContainer___3UnnY",
	"image": "HeroConfirm2021Q4__image___19TOA"
};
module.exports = ___CSS_LOADER_EXPORT___;
