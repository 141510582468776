<template>
  <input
    ref="addressInput"
    v-model="internalValue.value"
    class="address-input"
    type="text"
    :placeholder="placeholder"
    :required="isRequired"
    data-luko-tracking="AddressBar"
    @input="onInput"
    @focus="loadGoogleMap"
  />
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

const autoreformatAddressForGoogle = (addressString) => {
  const regexp = /^(\d*)\s*(bis|ter)\s+(.*)/i
  const regexpResult = regexp.exec(addressString)

  if (!regexpResult) {
    return addressString
  }

  const number = regexpResult[1]
  const bisOrTerChar = regexpResult[2][0].toUpperCase()
  const ending = regexpResult[3]

  return `${number}${bisOrTerChar} ${ending}`
}

export default {
  name: 'AddressBar',

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    textValue: {
      type: String,
      default: '',
    },
    gmapValue: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      internalValue: {
        value: null,
        status: null,
      },
      autocomplete: null,
    }
  },

  computed: {
    addressInput() {
      return this.$refs.addressInput
    },
  },

  watch: {
    internalValue: {
      handler(newValue, oldValue) {
        if (
          !(oldValue.status !== newValue.status && newValue.status === 'update')
        ) {
          this.$emit('input', null)
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.gmapValue) {
      this.internalValue.value = this.gmapValue
    } else if (this.textValue) {
      this.internalValue.value = this.textValue
    }
    if (this.focus) {
      this.addressInput.focus()
    }
  },

  methods: {
    async loadGoogleMap() {
      try {
        if (!window.google) {
          await new Loader({
            apiKey: 'AIzaSyBYc7VTJNAZigO6CtgKTweJesEsRt5NBZ0',
            version: 'weekly',
            libraries: ['places'],
          }).load()
        }
        if (!this.autocomplete) {
          await this.initGoogleAutocomplete()
        }

        this.$emit('gmapLoaded')
      } catch (err) {
        console.error(err)
      }
    },
    async onInput() {
      if (!window.google) {
        return await this.loadGoogleMap()
      }
      this.internalValue.value = autoreformatAddressForGoogle(
        this.internalValue.value
      )
    },
    async initGoogleAutocomplete() {
      if (this.autocomplete) return

      const map = document.createElement('div')
      this.gmapService = new window.google.maps.places.PlacesService(map)

      try {
        const place = await this.extractAddress(this.internalValue.value)
        this.internalValue = {
          value: place.formatted_address,
          status: 'update',
        }
        this.$emit('input', this.extractGooglePlaceInfos(place), true)
      } catch (err) {}

      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.addressInput
      )
      this.autocomplete.setComponentRestrictions({
        country: [this.$store.state.currentDomain],
      })
      this.autocomplete.addListener('place_changed', this.placeChanged)
    },
    extractAddress(value) {
      if (!value) {
        return Promise.reject(value)
      }
      const request = {
        query: value,
        fields: ['place_id'],
      }
      return new Promise((resolve) => {
        try {
          this.gmapService.findPlaceFromQuery(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const request = {
                placeId: results[0].place_id,
                fields: ['formatted_address', 'address_component', 'geometry'],
              }
              this.gmapService.getDetails(request, (place) => {
                resolve(place)
              })
            } else if (
              status ===
              window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              resolve(null)
            } else {
              this.$store.commit('app/SET_ERROR_DATA', {
                text: this.$t('error.generic'),
                cta: this.$t('error.retry'),
                error: { status, results },
                errorCode: 'CF0000',
              })
            }
          })
        } catch (e) {
          this.$store.commit('app/SET_ERROR_DATA', {
            text: this.$t('error.generic'),
            cta: this.$t('error.retry'),
            exception: e,
            request,
            errorCode: 'CF0001',
          })
        }
      })
    },
    placeChanged() {
      const place = this.autocomplete.getPlace()
      if (!place || !place.geometry) {
        // Not autocompleted place
        this.$emit('input', null, false)
      } else {
        this.$emit('input', this.extractGooglePlaceInfos(place), false)
      }
    },
    extractGooglePlaceInfos(place) {
      const addressObj = {
        streetNumber: null,
        route: null,
        city: null,
        administrativeAreaLevel2: null,
        administrativeAreaLevel1: null,
        country: null,
        countryCode: null,
        postalCode: null,
        formattedAddress: place.formatted_address,
      }

      if (place.geometry) {
        addressObj.lat = place.geometry.location.lat()
        addressObj.lon = place.geometry.location.lng()
      }

      for (let i = 0; i < place.address_components.length; i++) {
        switch (place.address_components[i].types[0]) {
          case 'street_number':
            addressObj.streetNumber = place.address_components[i].long_name
            break
          case 'sublocality_level_1':
          case 'route':
            addressObj.route = place.address_components[i].long_name
            break
          case 'locality':
            addressObj.city = place.address_components[i].long_name
            break
          case 'administrative_area_level_2':
            addressObj.administrativeAreaLevel2 =
              place.address_components[i].long_name
            break
          case 'administrative_area_level_1':
            addressObj.administrativeAreaLevel1 =
              place.address_components[i].long_name
            break
          case 'country':
            addressObj.country = place.address_components[i].long_name
            addressObj.countryCode = place.address_components[i].short_name
            break
          case 'postal_code':
            addressObj.postalCode = place.address_components[i].long_name
            break
        }
      }

      const regexp = /^(\d*)(b|t)/i
      const regexpResultOnInternalValue = regexp.exec(this.internalValue.value)
      const regexpResultOnFormattedAddress = regexp.exec(
        addressObj.formattedAddress
      )
      const regexpResultOnStreetNumber = regexp.exec(addressObj.streetNumber)

      if (regexpResultOnInternalValue) {
        const bisOrTerChar = regexpResultOnInternalValue[2].toUpperCase()
        if (!regexpResultOnFormattedAddress && addressObj.streetNumber) {
          const index = addressObj.streetNumber.length
          addressObj.formattedAddress =
            addressObj.formattedAddress.slice(0, index) +
            bisOrTerChar +
            addressObj.formattedAddress.slice(index)
        }
        if (!regexpResultOnStreetNumber) {
          addressObj.streetNumber += bisOrTerChar
        }
      }

      return addressObj
    },
  },
}
</script>

<!-- Absence of scoped property is intented for styling the Google maps places autocomplete dropdown -->
<style lang="scss">
.pac-icon {
  display: none;
}

.pac-matched,
.pac-item-query {
  color: #020202;
  font-weight: 400;
  font-size: 16px;
  font-family: 'CircularXX', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif;
}

.pac-item {
  padding: 15px 15px 15px 20px;

  font-weight: 400;
  font-size: 16px;

  cursor: pointer;
}

input.address-input {
  padding: 12px 24px;
  border: 2px solid $gray-100;

  border-radius: 8px;

  color: $gray-1000;
  font-size: 16px;

  font-family: 'CircularXX', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif;

  line-height: 24px;

  background-color: white;

  box-shadow: 0 0 0 0px $bluko-100;

  transition-duration: 0.3s;

  transition-property: border-color, box-shadow;
  &::placeholder {
    color: $gray-700;

    opacity: 1;
  }

  &:hover {
    border-color: $gray-200;
  }

  &:focus {
    border-color: #2d50e6;

    box-shadow: 0 0 0 4px $bluko-100;
  }
}
</style>
