// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroLaunchImageFull__container___1tUu3{min-height:615px;margin-top:-64px}@media (max-width:719px){.HeroLaunchImageFull__container___1tUu3{height:auto;min-height:480px}}.HeroLaunchImageFull__inner___QWEf9{max-width:1440px!important;height:100%;min-height:615px;background-repeat:no-repeat;background-position:50%;background-size:cover}@media (max-width:719px){.HeroLaunchImageFull__inner___QWEf9{min-height:480px;background-size:cover}}.HeroLaunchImageFull__content___2sNx5{display:flex;flex-direction:column;justify-content:center;max-width:1000px;min-height:615px;padding:128px 120px 68px}@media (max-width:719px){.HeroLaunchImageFull__content___2sNx5{padding:112px 32px 42px}}.HeroLaunchImageFull__backgroundShadow___1g2so{background:linear-gradient(90deg,rgba(0,0,0,.8),transparent)}.HeroLaunchImageFull__title___jJSwl{color:inherit;font-weight:500;font-size:48px;line-height:64px;width:85%;color:#fff}@media (max-width:719px){.HeroLaunchImageFull__title___jJSwl{color:inherit;font-weight:500;font-size:32px;line-height:48px;width:100%;color:#fff}}.HeroLaunchImageFull__description___kA3Zc{color:inherit;font-weight:400;font-size:20px;line-height:32px;width:85%;margin-top:16px;color:#fff}@media (max-width:719px){.HeroLaunchImageFull__description___kA3Zc{color:inherit;font-weight:400;font-size:16px;line-height:24px;width:100%;color:#fff}}.HeroLaunchImageFull__ctaSection___p7AAI{margin-top:58px}@media (max-width:719px){.HeroLaunchImageFull__cta___2T8Fa{width:100%}}.HeroLaunchImageFull__trustpilot___3b_wG{margin-top:62px}.HeroLaunchImageFull__trustpilotContent___3UMXb{display:flex;align-items:center}.HeroLaunchImageFull__trustpilotText___2-ToR{margin-left:8px;color:#fff}.HeroLaunchImageFull__link___2feUi{text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HeroLaunchImageFull__container___1tUu3",
	"inner": "HeroLaunchImageFull__inner___QWEf9",
	"content": "HeroLaunchImageFull__content___2sNx5",
	"backgroundShadow": "HeroLaunchImageFull__backgroundShadow___1g2so",
	"title": "HeroLaunchImageFull__title___jJSwl",
	"description": "HeroLaunchImageFull__description___kA3Zc",
	"ctaSection": "HeroLaunchImageFull__ctaSection___p7AAI",
	"cta": "HeroLaunchImageFull__cta___2T8Fa",
	"trustpilot": "HeroLaunchImageFull__trustpilot___3b_wG",
	"trustpilotContent": "HeroLaunchImageFull__trustpilotContent___3UMXb",
	"trustpilotText": "HeroLaunchImageFull__trustpilotText___2-ToR",
	"link": "HeroLaunchImageFull__link___2feUi"
};
module.exports = ___CSS_LOADER_EXPORT___;
