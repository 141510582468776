// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcrumb__path___25sjN{display:flex;flex-wrap:wrap;margin-left:-4px}.Breadcrumb__path___25sjN li{position:relative;margin-left:4px;color:inherit;font-weight:400;font-size:16px;line-height:24px}.Breadcrumb__path___25sjN li:not(:last-child):after{color:#6b6651;content:\" /\"}.Breadcrumb__path___25sjN li a{color:#6b6651;text-decoration:none}.Breadcrumb__path___25sjN li a:hover{text-decoration:underline}.Breadcrumb__path___25sjN li:last-child a{display:inline-block;max-width:200px;overflow:hidden;color:#2c2302;font-weight:500;white-space:nowrap;text-align:left;text-overflow:ellipsis}.Breadcrumb__isDark___2Yae4 li:not(:last-child):after,.Breadcrumb__isDark___2Yae4 li a{color:#829af2}.Breadcrumb__isDark___2Yae4 li:last-child a{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"path": "Breadcrumb__path___25sjN",
	"isDark": "Breadcrumb__isDark___2Yae4"
};
module.exports = ___CSS_LOADER_EXPORT___;
