// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:959px){.MediaCarouselDefault__desktop___2GwNz{display:none}}@media (min-width:960px){.MediaCarouselDefault__mobile___BE6kM{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"desktop": "MediaCarouselDefault__desktop___2GwNz",
	"mobile": "MediaCarouselDefault__mobile___BE6kM"
};
module.exports = ___CSS_LOADER_EXPORT___;
