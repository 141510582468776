<template>
  <section :class="$style.container">
    <aside :class="$style.contentContainer">
      <Title :class="$style.title" :title-params="primary.title" />
      <RichText v-if="primary.description" :class="$style.description" :text-params="primary.description" />

      <div :class="$style.ctaContainer">
        <img :src="score.logoImage.darkBackground" loading="lazy" :class="$style.tpLogo" />
        <img :src="score.starImage" loading="lazy" :class="$style.tpScore" />
        <PLink :simple-url="getTrustpilotLink" color="white" is-arrowed :class="$style.tpLink">{{ primary.ctaText }}</PLink>
      </div>
    </aside>
    <div :class="$style.cardContainer">
      <div
        ref="carousel"
        :class="[$style.carousel]"
        :style="{ width: `${(cardWidth + 40) * cardGroup * items.length}px` }"
        @mouseenter="onMouseenter"
        @mouseleave="onMouseleave"
        @mousemove="onMousemove"
        @mousedown="onMousedown"
        @click="onClick"
        @touchstart="onTouchstart"
        @touchmove="onTouchmove"
        @touchend="onTouchend"
      >
        <template v-for="(gItems, gindex) in groupedItems">
          <div v-for="(item, index) in gItems" :key="'card' + gindex + index" :class="$style.card" :style="{ width: `${cardWidth}px` }">
            <PImage :image-object="item.damageImage" :class="$style.damageImage" />
            <div :class="$style.cardContent">
              <RichText :text-params="item.text" />
            </div>
            <div :class="$style.cardInfo">
              <ProfileThumbnail :image="item.profileImage.url" :name="item.displayName" :size="32" />
              <div>
                <RichText :text-params="item.info" :class="$style.info" />
                <span :class="$style.subInfo">
                  {{ item.subInfo }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import PLink from '@/components/PLink/PLink.vue'
import { getFormatedLink } from '@/mixins/getFormatedLink'
import ProfileThumbnail from '@/components/ProfileThumbnail'
import { getTrustpilotLink } from '@/mixins/getTrustpilotLink'
const CARD_GROUP = 5

export default {
  name: 'TrustpilotCarousel2021Q4',
  components: {
    ProfileThumbnail,
    PLink,
  },
  mixins: [getFormatedLink, getTrustpilotLink],

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const _items = this.slice.items.filter((item) => item.damageImage?.url)

    return {
      cardGroup: CARD_GROUP,
      score,
      primary: this.slice.primary,
      items: _items,
      groupedItems: new Array(CARD_GROUP).fill(0).map(() => _items),
      isTouchDevice: false,
      startDrag: false,
      startX: null,
      animationID: null,
      speed: -1,
      timeStamp: null,
      cardWidth: 400,
    }
  },
  mounted() {
    this.startAnimation()
  },
  methods: {
    startAnimation() {
      this.animationID = requestAnimationFrame(this.loop)
    },
    stopAnimation() {
      this.animationID = cancelAnimationFrame(this.animationID)
    },
    loop() {
      if (!this.animationID) return
      this.moveCarousel()
      this.animationID = requestAnimationFrame(this.loop)
    },
    moveCarousel() {
      const el = this.$refs.carousel
      const currentTranslateX = parseInt(window.getComputedStyle(el).transform.split(',')[4])
      let newTranslateX = (currentTranslateX + this.speed) % ((this.cardWidth + 40) * this.items.length)
      if (newTranslateX > 0) {
        newTranslateX = 0
        this.speed = -1
      }

      el.style.transform = `translateX(${newTranslateX}px)`

      // Friction when user scroll right
      if (this.speed > 0) return (this.speed -= 0.2)

      // Friction when user scroll left
      if (this.speed < 0) {
        this.speed += 0.2
        if (this.speed > -1) return (this.speed = -1)
      }
    },
    onMouseenter() {
      if (this.isTouchDevice) return
      this.speed = 0
    },
    onMouseleave() {
      if (this.isTouchDevice) return
      this.startDrag = false

      if (this.speed === 0) this.speed = -1
    },
    onMousemove(e) {
      if (this.isTouchDevice) return
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.pageX
    },
    onMousedown(e) {
      if (this.isTouchDevice) return
      this.startDrag = true
      this.startX = e.pageX
      this.timeStamp = e.timeStamp
    },
    onClick() {
      if (this.isTouchDevice) return
      this.startDrag = false
    },
    onTouchstart(e) {
      this.isTouchDevice = true
      this.startDrag = true
      this.startX = e.targetTouches[0].pageX
      this.timeStamp = e.timeStamp
      this.speed = 0
    },
    onTouchend() {
      this.isTouchDevice = true
      this.startDrag = false
      if (this.speed === 0) this.speed = -1
    },
    onTouchmove(e) {
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.targetTouches[0].pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.targetTouches[0].pageX
    },
  },
}
</script>

<style lang="scss" module>
.container {
  position: relative;

  min-height: 500px;
  margin: 0;

  background-color: #ffede3;

  @include below(medium) {
    background-color: #122182;
  }
}
.tpScore {
  height: 40px;
  margin-top: 12px;
}
.tpLogo {
  height: 32px;
}
.tpLink {
  margin-top: 24px;
}
.contentContainer {
  position: absolute;
  left: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  height: 100%;
  padding: 100px 80px;

  color: white;

  background-color: #122182;

  @include below(medium) {
    position: static;

    max-width: none;
    padding: 48px 24px 0;
  }
}

.title p {
  color: white;
  font-weight: 200;
  font-size: 42px;
  line-height: 54px;
  @include below(medium) {
    text-align: center;
  }
  @include below(small) {
    font-size: 32px;
    line-height: 42px;
  }
}
.description {
  @include typo-body;
  margin-top: 32px;

  color: white;
  @include below(medium) {
    display: none;
  }
}
.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;

  @include below(medium) {
    align-items: center;
    margin-top: 42px;

    text-align: center;
  }
  @include below(small) {
    margin-top: 34px;
  }
}

.cardContainer {
  height: 100%;
  padding: 92px 0;
  overflow-x: hidden;
  @include below(medium) {
    padding: 82px 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
  margin-left: 40px;
  padding: 24px;
  border-radius: 8px;

  background-color: white;
  cursor: pointer;

  user-select: none;
}
.cardContent {
  font-size: 16px;
  line-height: 24px;
}
.carousel {
  display: flex;

  transform: translateX(0);
}

.cardInfo {
  display: flex;
  align-items: center;
}
.damageImage {
  height: 128px;
  border-radius: 8px;
  object-fit: cover;
}
.info p {
  margin-left: 10px;

  color: $gray-800;
  font-size: 12px;
  line-height: unset;
}

.subInfo {
  @include typo-caption;
  margin-left: 10px;

  color: $gray-500;
}
</style>
