<template>
  <div :class="{ [$style.isDark]: isDark }" data-luko-tracking="CtaBox">
    <AddressForm v-if="type === 'Address'" :placeholder="placeholder" :button-text="text" is-light :query-params="queryParams" />
    <div v-else-if="type === 'Email'" :class="$style.emailContainer">
      <LkEmailField :placeholder="placeholder" :button-text="text" :class="$style.emailField" />
      <LkButton :href="ctaTarget" :class="$style.button" is-white>
        {{ text }}
      </LkButton>
    </div>
    <LkButton v-else-if="type === 'Button'" :class="[$style.ctaButton]" :href="ctaTarget" is-white data-cta="calendly">
      {{ text }}
    </LkButton>
    <div v-else-if="type === 'App' && apps.length > 0" :class="$style.appsContainer">
      <a v-for="(app, appIndex) in apps" :key="appIndex" :href="getFormatedLink(app.link)" target="_blank">
        <PImage :image-object="app.image" :class="$style.appImage" sizes="xs:320px" />
      </a>
    </div>
    <PhoneSubscriptionForm v-else-if="type === 'Phone'" :button-text="text" :is-light="isDark" />
  </div>
</template>

<script>
import AddressForm from '@/components/AddressForm'
import { getFormatedLink } from '@/mixins/getFormatedLink'
import { getOnboardingLink } from '@/mixins/getOnboardingLink'
import LkEmailField from '@/components/LkEmailField'
import PhoneSubscriptionForm from '@/components/PhoneSubscriptionForm'

export default {
  name: 'CtaBox',

  components: {
    LkEmailField,
    AddressForm,
    PhoneSubscriptionForm,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    type: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: () => ({}),
    },

    toOnboarding: {
      type: Boolean,
      default: false,
    },

    isDark: {
      type: Boolean,
      default: false,
    },

    apps: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ctaTarget() {
      if (this.toOnboarding) return this.getOnboardingLink()

      const formatedLink = new URL(this.getFormatedLink(this.link))

      // forwarding query params
      Object.entries(this.$route.query).forEach(([k, v]) => {
        if (!formatedLink.searchParams.has(k)) {
          formatedLink.searchParams.append(k, v)
        }
      })

      return formatedLink.toString()
    },

    queryParams() {
      if (this.link && this.link.url) {
        return Array.from(new URL(this.link.url).searchParams.entries())
      }
      return []
    },
  },
}
</script>

<style lang="scss" module>
.isDark {
  .emailField input {
    border-color: $bluko-600;

    color: white;

    background-color: $bluko-700;

    &::placeholder {
      color: white;
    }

    &:hover {
      border-color: $bluko-300;
    }

    &:focus {
      border-color: $bluko-300;

      box-shadow: 0 0 0 4px $bluko-500;
    }
  }
}

.emailContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  .button {
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include below(large) {
    flex-direction: column;
    max-width: 300px;

    .emailField {
      width: 100%;
    }
  }
}

.appsContainer {
  display: flex;
  gap: 25px;

  @include below(small) {
    justify-content: center;
  }
}

.appImage {
  max-width: 152px;
  border: 1px solid white;
  border-radius: 8px;

  @include below(small) {
    width: 40vw;
    max-width: 152px;
    height: auto;
    min-height: initial;
    max-height: 48px;
  }
}
.ctaButton {
  color: #2b4de0 !important;
  @include below(medium) {
    width: 100%;
  }
}
</style>
