<template>
  <section :class="[$style.container]">
    <Wrapper
      :class="[$style.inner, { [$style.isReverse]: primary.imagePosition }]"
    >
      <PImage
        loading="lazy"
        :image-object="primary.image"
        :class="$style.image"
        sizes="sm:100vw"
      />
      <div :class="$style.contentContainer">
        <Title
          v-if="primary.title"
          :title-params="primary.title"
          :class="$style.title"
        />
        <RichText
          :text-params="primary.description"
          :class="$style.description"
        />
        <div :class="$style.itemsContainer">
          <div v-for="(item, index) in items" :key="index" :class="$style.item">
            <PImage :image-object="item.icon" :class="$style.itemIcon" />
            <RichText :text-params="item.text" :class="$style.itemText" />
          </div>
        </div>
        <CtaBox :primary="primary" :class="$style.ctaContainer" is-outlined />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import PImage from '~/components/PImage'

import CtaBox from '../components/CtaBox'

export default {
  name: 'CustomerServicePink',

  components: {
    RichText,
    Title,
    PImage,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 160px auto;
  padding: 0 24px;

  @include below(medium) {
    margin: 80px auto;
  }
}
.inner {
  display: flex;

  background-color: #fff4ee;

  &.isReverse {
    flex-direction: row-reverse;
  }
  @include below(medium) {
    flex-direction: column !important;
  }
}

.title {
  @include typo-mega-title;
  font-weight: 200;
  @include below(small) {
    font-size: 36px;
    line-height: 38px;
  }
}
.description p {
  margin-top: 16px;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 72px 86px;

  @include below(large) {
    padding: 80px 32px;
  }
  @include below(small) {
    padding: 24px;
  }
}
.itemText {
  color: white;
}

.item {
  display: flex;
  gap: 14px;
  margin-top: 16px;

  color: $gray-700;
}
.itemsContainer {
  margin-top: 16px;
}
.image {
  flex: 1;
  width: 50%;
  object-fit: cover;
  @include below(medium) {
    width: 100%;
  }
}
.ctaContainer {
  margin-top: 64px;
}
</style>
