<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner" :style="backgroundStyles(primary.backgroundImage.url)">
      <div :class="[$style.content, { [$style.backgroundShadow]: primary.backgroundShadow }]">
        <Title :title-params="primary.title" :class="$style.title" />
        <RichText :text-params="primary.description" :class="$style.description" />
        <div :class="$style.ctaSection">
          <CtaBox
            v-if="primary.ctaType !== 'None' && primary.ctaText"
            :class="$style.cta"
            :placeholder="primary.inputPlaceholder"
            :type="primary.ctaType"
            :text="primary.ctaText"
            :link="primary.ctaLink"
            :apps="apps"
          />
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getTrustpilotLink } from '~/mixins/getTrustpilotLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { backgroundStyles } from '~/mixins/backgroundStyles'

import CtaBox from './components/CtaBox'

export default {
  name: 'HeroFullImage',
  components: { CtaBox },

  mixins: [backgroundStyles, getFormatedLink, getTrustpilotLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
  computed: {
    apps() {
      return [
        {
          image: this.primary.imageAppStore,
          link: this.primary.ctaAppStore,
        },
        {
          image: this.primary.imageGooglePlay,
          link: this.primary.ctaGooglePlay,
        },
      ]
    },
  },
}
</script>

<style lang="scss" module>
.container {
  min-height: 615px;
  margin-top: -64px;

  @include below(small) {
    height: auto;
    min-height: 480px;
  }
}

.inner {
  max-width: 1440px !important;
  height: 100%;
  min-height: 615px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include below(small) {
    min-height: 480px;

    background-size: cover;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  min-height: 615px;
  padding: 128px 120px 68px;

  @include below(small) {
    padding: 112px 32px 42px;
  }
}
.backgroundShadow {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.title {
  @include typo-mega-title;
  width: 85%;

  color: white;

  @include below(small) {
    @include typo-large-title;
    width: 100%;

    color: white;
  }
}
.description {
  @include typo-headline;
  width: 85%;
  margin-top: 16px;

  color: white;

  @include below(small) {
    @include typo-body;
    width: 100%;

    color: white;
  }
}
.ctaSection {
  margin-top: 58px;
}
.cta {
  @include below(small) {
    width: 100%;
  }
}

.trustpilot {
  margin-top: 62px;
}

.trustpilotContent {
  display: flex;
  align-items: center;
}
.trustpilotText {
  margin-left: 8px;

  color: white;
}

.link {
  text-decoration: none;
}
</style>
