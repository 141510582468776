// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArrowLink__link___3JVpq{color:inherit;font-weight:500;font-size:16px;line-height:24px;display:flex;align-items:center;padding-left:10px;text-decoration:none}.ArrowLink__link___3JVpq .ArrowLink__icon___1bkuv{transform:translateX(-10px);transition:transform .2s ease-in-out}.ArrowLink__link___3JVpq:hover .ArrowLink__icon___1bkuv{transform:translateX(-5px)}.ArrowLink__bluko___1CKrV{color:#2d50e6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"link": "ArrowLink__link___3JVpq",
	"icon": "ArrowLink__icon___1bkuv",
	"bluko": "ArrowLink__bluko___1CKrV"
};
module.exports = ___CSS_LOADER_EXPORT___;
