<template>
  <div :class="{ [$style.isDark]: isDark }" data-luko-tracking="CtaBox">
    <AddressForm
      v-if="type === 'Address'"
      :placeholder="placeholder"
      :button-text="text"
      :is-dark="isDark"
      :query-params="queryParams"
    />
    <div v-else-if="type === 'Email'" :class="$style.emailContainer">
      <LkEmailField
        :placeholder="placeholder"
        :button-text="text"
        :class="$style.emailField"
      />
      <LkButton :href="ctaTarget" :class="$style.button">
        {{ text }}
      </LkButton>
    </div>
    <LkButton
      v-else-if="type === 'Button'"
      :class="$style.button"
      :href="ctaTarget"
      data-cta="calendly"
    >
      {{ text }}
    </LkButton>
    <div
      v-else-if="type === 'App' && apps.length > 0"
      :class="$style.appsContainer"
    >
      <PLink
        v-for="(app, appIndex) in apps"
        :key="appIndex"
        :link-object="app.link"
      >
        <PImage :image-object="app.image" :class="$style.appImage" />
      </PLink>
    </div>
  </div>
</template>

<script>
import AddressForm from '~/components/AddressForm'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import PLink from '~/components/PLink'
import LkEmailField from '~/components/LkEmailField'

export default {
  name: 'CtaBox',

  components: {
    AddressForm,
    PLink,
    LkEmailField,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    type: {
      type: String,
      default: 'None',
    },

    text: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: () => ({}),
    },

    toOnboarding: {
      type: Boolean,
      default: false,
    },

    isDark: {
      type: Boolean,
      default: false,
    },

    apps: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ctaTarget() {
      if (this.toOnboarding) return this.getOnboardingLink()

      const formatedLink = new URL(this.getFormatedLink(this.link))

      // forwarding query params
      Object.entries(this.$route.query).forEach(([k, v]) => {
        if (!formatedLink.searchParams.has(k)) {
          formatedLink.searchParams.append(k, v)
        }
      })

      return formatedLink.toString()
    },

    queryParams() {
      if (this.link && this.link.url) {
        return Array.from(new URL(this.link.url).searchParams.entries())
      }
      return []
    },
  },
}
</script>

<style lang="scss" module>
.isDark {
  .emailField input {
    border-color: $bluko-600;

    color: white;

    background-color: $bluko-700;

    &::placeholder {
      color: white;
    }

    &:hover {
      border-color: $bluko-300;
    }

    &:focus {
      border-color: $bluko-300;

      box-shadow: 0 0 0 4px $bluko-500;
    }
  }
}

.emailContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  .button {
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include below(large) {
    flex-direction: column;
    max-width: 300px;

    .emailField {
      width: 100%;
    }
  }
}

.appsContainer {
  display: flex;
  gap: 25px;

  @include below(small) {
    justify-content: center;
  }
}

.appImage {
  border: 1px solid white;
  border-radius: 8px;

  @include below(small) {
    width: 40vw;
    max-width: 152px;
    height: auto;
    min-height: initial;
    max-height: 48px;
  }
}
</style>
