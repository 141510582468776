<template>
  <section :class="$style.container" data-luko-tracking="QuinconceSlice">
    <Title
      v-if="primary.title"
      :title-params="primary.title"
      :class="$style.title"
    />
    <Wrapper :class="$style.inner">
      <div
        v-for="(item, itemIndex) in items"
        :id="blockId(item.title[0].text)"
        :key="itemIndex"
        :class="[
          $style.content,
          (primary.is_reverse || isReverse) && $style.reverseContent,
        ]"
        data-aos="fade-up"
      >
        <div :class="$style.view">
          <PImage :image-object="item.image" sizes="sm:90vw" :quality="80" />
        </div>
        <div :class="$style.desc">
          <PImage
            v-if="item.icon && item.icon.url"
            :class="$style.icon"
            :image-object="item.icon"
          />
          <div :class="$style.titleContainer">
            <Title
              :class="$style.contentTitle"
              is-large-title
              :title-params="item.title"
            />
            <span v-if="item.tag" :class="$style.tag">
              {{ item.tag }}
            </span>
          </div>
          <RichText
            :text-params="item.text"
            :class="$style.contentDescription"
          />
          <div v-if="item.trustpilot" :class="$style.trustpilot">
            <RichText
              :text-params="item.trustpilot_text"
              :class="$style.trustpilotText"
            />
            <img
              src="~/assets/icons/socials/trustpilot-stars.svg"
              alt="trustpilot icon"
              :class="$style.trustpilotStars"
            />
            <img
              src="~/assets/icons/socials/trustpilot-star.svg"
              alt="trustpilot icon"
            />
          </div>

          <CtaBox :item="item" :class="$style.cta" />

          <div v-if="item.has_app_link" :class="$style.app_links">
            <PLink :link-object="primary.cta_app_store">
              <PImage
                :image-object="primary.image_app_store"
                :class="$style.app_badge"
                sizes="xs:200px"
              />
            </PLink>
            <PLink :link-object="primary.cta_google_play">
              <PImage
                :image-object="primary.image_google_play"
                :class="$style.app_badge"
                sizes="xs:200px"
              />
            </PLink>
          </div>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

import CtaBox from './components/CtaBox.vue'

export default {
  name: 'QuinconceSlice',
  components: {
    PImage,
    PLink,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },

  methods: {
    blockId(title) {
      const formatedTitle = title
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 154px 0 64px;
  @include below(small) {
    margin: 60px 0;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
}

.title {
  max-width: 840px;
  margin: 128px auto 80px;
  padding: 0 20px;

  font-weight: 200;
  font-size: 48px;
  line-height: 64px;

  text-align: center;

  @include below(medium) {
    font-size: 38px;
    line-height: 46px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  @include below(medium) {
    flex-direction: column !important;
    &:first-child {
      margin-top: 0;
    }
    margin-top: 60px;
  }
}

.reverseContent {
  &:nth-child(2n + 1) {
    flex-direction: row !important;
  }
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  @include below(medium) {
  }
}

.desc {
  width: 50%;
  margin-top: 0;
  padding: 30px 73px;

  @include below(medium) {
    width: 100%;
    max-width: 760px;
    padding: 32px;
  }
}

.view {
  width: 50%;

  img {
    display: block;
    width: 100%;
  }
  @include below(medium) {
    width: 100%;
    max-width: 760px;
  }
}

.cta {
  margin-top: 40px;
}

.icon {
  display: none;

  @include above(small) {
    display: block;
    width: 56px;
    height: 56px;
    margin-bottom: 16px;
  }
}

.contentTitle {
  display: inline;

  font-weight: 200;
  font-size: 40px;
  line-height: 49px;
  @include below(small) {
    font-size: 28px;
    line-height: 34px;
  }
}

.tag {
  display: inline-block;
  margin-top: 8px;
  padding: 6px 6px 3px;
  border-radius: 4px;

  color: white;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;

  background-color: $gold-500;

  transform: translateY(-4px);
}

.contentDescription {
  @include typo-body;
  margin-top: 16px;

  ul > li {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.trustpilot {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.trustpilotStars {
  margin: 0 10px;
}
.trustpilotText {
  color: black;
}

.app_links {
  display: flex;
  justify-content: left;

  column-gap: 25px;

  @include below(small) {
    justify-content: center;

    column-gap: 17px;
  }
}

.app_badge {
  height: 48px;

  @media screen and (max-width: 374px) {
    width: 40vw;
    height: auto;
  }
}
</style>
