var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.container,attrs:{"data-luko-tracking":"MediaCarrousel"}},[_c('Wrapper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.sectionOnScreen,
      intersection: {
        threshold: 0.8,
      },
    }),expression:"{\n      callback: sectionOnScreen,\n      intersection: {\n        threshold: 0.8,\n      },\n    }"}],class:_vm.$style.inner},[_c('transition-group',{class:_vm.$style.imageContainer,attrs:{"name":"cross","tag":"div"}},[_c('PImage',{key:'front' + _vm.currentIndex + _vm.currentItem.url,staticClass:"front",class:_vm.$style.imageFront,attrs:{"image-object":_vm.currentItem.imageFront,"sizes":"xs:600px"}}),_vm._v(" "),_c('PImage',{key:'back' + _vm.currentIndex + _vm.currentItem.url,staticClass:"back",class:_vm.$style.imageBack,attrs:{"image-object":_vm.currentItem.imageBack,"sizes":"xs:100px"}})],1),_vm._v(" "),_c('div',{class:_vm.$style.descriptionContainer},[_c('Title',{class:_vm.$style.title,attrs:{"title-params":_vm.primary.title}}),_vm._v(" "),_vm._l((_vm.items),function(item,index){
    var _obj;
return _c('div',{key:index,class:_vm.$style.item,on:{"click":function($event){return _vm.onClickItem(index)}}},[_c('LinearProgressBar',{class:_vm.$style.progressBar,attrs:{"callback":_vm.afterCount,"is-active":index === _vm.currentIndex && (_vm.isScrollDown || _vm.isVisible)}}),_vm._v(" "),_c('RichText',{class:[
            _vm.$style.description,
            ( _obj = {}, _obj[_vm.$style.active] = _vm.currentIndex === index && (_vm.isScrollDown || _vm.isVisible), _obj ) ],attrs:{"text-params":item.description}})],1)})],2)],1),_vm._v(" "),(_vm.primary.ctaText && _vm.primary.ctaLink)?_c('Wrapper',{class:_vm.$style.ctaContainer},[(_vm.primary.ctaLink)?_c('ArrowButton',{attrs:{"href":_vm.getFormatedLink(_vm.primary.ctaLink),"target":_vm.primary.ctaLink.target,"is-outlined":""}},[_vm._v("\n      "+_vm._s(_vm.primary.ctaText)+"\n    ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }