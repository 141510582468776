<template>
  <transition name="delayed-fade" mode="out-in">
    <p v-show="error" :class="$style.root" v-html="error" />
  </transition>
</template>

<script>
export default {
  name: 'LkError',
  props: {
    error: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" module>
.root {
  margin-top: 8px;

  color: $terracota-500;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
</style>
