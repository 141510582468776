<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="primary.title" :class="$style.title" />
      <div v-observe-visibility="isVisible" :class="$style.itemsContainer">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="$style.item"
          data-aos="fade-up"
        >
          <PImage
            :image-object="item.image"
            sizes="xs:64px"
            :class="$style.image"
          />
          <Counter
            :text="item.title"
            :launch="launchFlags[index]"
            :class="$style.itemTitle"
          />
          <RichText
            :text-params="item.description"
            :class="$style.itemDescription"
          />
        </div>
      </div>
      <div :class="$style.ctaContainer">
        <ArrowButton
          :href="getFormatedLink(primary.ctaLink)"
          is-outlined
          :class="$style.ctaButton"
          target="_blank"
        >
          {{ primary.ctaText }}
        </ArrowButton>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { delay } from '@fxts/core'

import { getFormatedLink } from '~/mixins/getFormatedLink'
import Counter from '~/components/Counter'
import ArrowButton from '~/components/ArrowButton'

export default {
  name: 'HighlightCounter',
  components: { Counter, ArrowButton },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      launchFlags: new Array(this.slice.items.length).fill(false),
    }
  },
  methods: {
    isVisible(visible) {
      if (visible) {
        return this.startCounter()
      }
      this.launchFlags = new Array(this.slice.items.length).fill(false)
    },
    async startCounter() {
      for (let i = 0; i < this.items.length; i++) {
        this.launchFlags.splice(i, 1, true)
        await delay(250)
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin-top: 56px;
  padding: 64px 0 0;
}
.inner {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.title {
  @include typo-mega-title;
  font-weight: 200;
  text-align: center;
  @include below(small) {
    font-size: 40px;
    line-height: 48px;
  }
}
.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
}
.item {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  margin-bottom: 50px;
  padding: 0 40px;
}
.itemTitle {
  @include typo-extra-title;
  margin-top: 16px;

  text-align: center;
  @include below(small) {
    font-size: 32px;
    line-height: 48px;
  }
}
.itemDescription {
  color: #848484;
  text-align: center;
}
.image {
  width: 64px;
  height: 64px;
}

.ctaContainer {
  margin: 30px 0 0;

  text-align: center;
  @include below(small) {
    display: none;
  }
}
</style>
