<template>
  <div>
    <QuinconceDefault :slice="slice" />
  </div>
</template>

<script>
import QuinconceDefault from './default-slice'

export default {
  name: 'QuinconceSlice',
  components: {
    QuinconceDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
}
</script>
