// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__container___3KFG3{margin-top:56px;padding:64px 0 0}.index__inner___2HQdr{display:flex;flex-direction:column;padding:0 20px}.index__title___1TXZR{color:inherit;font-weight:500;font-size:48px;line-height:64px;font-weight:200;text-align:center}@media (max-width:719px){.index__title___1TXZR{font-size:40px;line-height:48px}}.index__itemsContainer___3EW1z{display:flex;flex-wrap:wrap;justify-content:center;margin-top:60px}.index__item___P8H_7{display:flex;flex:1;flex-direction:column;align-items:center;min-width:300px;margin-bottom:50px;padding:0 40px}.index__itemTitle___3xL9M{color:inherit;font-weight:500;font-size:40px;line-height:56px;margin-top:16px;text-align:center}@media (max-width:719px){.index__itemTitle___3xL9M{font-size:32px;line-height:48px}}.index__itemDescription___2dRiY{color:#848484;text-align:center}.index__image___3Jzu_{width:64px;height:64px}.index__ctaContainer___15XjX{margin:30px 0 0;text-align:center}@media (max-width:719px){.index__ctaContainer___15XjX{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "index__container___3KFG3",
	"inner": "index__inner___2HQdr",
	"title": "index__title___1TXZR",
	"itemsContainer": "index__itemsContainer___3EW1z",
	"item": "index__item___P8H_7",
	"itemTitle": "index__itemTitle___3xL9M",
	"itemDescription": "index__itemDescription___2dRiY",
	"image": "index__image___3Jzu_",
	"ctaContainer": "index__ctaContainer___15XjX"
};
module.exports = ___CSS_LOADER_EXPORT___;
