<template>
  <section v-observe-visibility="isVisible" :class="$style.heroContainer">
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Breadcrumb v-if="primary.showBreadcrumb" :class="$style.breadcrumb" />
        <Title :class="$style.title" :title-params="primary.title" />
        <RichText :class="$style.subtitle" :text-params="primary.subtitle" />
        <CtaBox
          v-if="primary.ctaType !== 'None'"
          :class="$style.ctaContainer"
          :placeholder="primary.inputPlaceholder"
          :type="primary.ctaType"
          :text="primary.ctaText"
          :link="primary.ctaLink"
        />
      </div>
      <div :class="$style.view">
        <PImage
          :class="$style.image"
          :image-object="primary.backgroundImage"
          sizes="sm:100vw"
        />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Breadcrumb from '~/components/Breadcrumb'
import { getFormatedLink } from '~/mixins/getFormatedLink'

import { CtaBox } from './components'

export default {
  name: 'HeroAdviceHub2021Q4',

  components: {
    Breadcrumb,
    CtaBox,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },

  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  min-height: 615px;
  margin-top: -64px;
  padding: 64px 40px 46px;

  background-color: #fff4ee;

  @include below(medium) {
    padding: 24px;
    padding-top: 64px;
  }
}

.breadcrumb {
  margin-bottom: 16px;
}

.inner {
  display: flex;
  gap: 90px;
  justify-content: space-between;
  height: 100%;
  padding-top: 56px;

  @include below(large) {
    gap: 40px;
  }
  @include below(medium) {
    flex-direction: column;
    gap: 0;
  }
  @include below(small) {
    padding-top: 24px;
  }
}

.desc {
  width: 50%;
  max-width: 600px;
  @include below(medium) {
    width: 100%;
    max-width: none;
  }
}

.title {
  display: inline;
  margin-top: 16px;

  font-weight: 200;
  font-size: 48px;
  line-height: 59px;

  h1 {
    display: inline;
  }

  @include below(small) {
    font-size: 40px;
    line-height: 56px;
  }
}

.subtitle p {
  margin-top: 16px;

  @include typo-headline;
  color: $gray-700;
}

.view {
  width: 50%;
  min-height: 430px;
  max-height: 460px;
  margin: 0;
  border-bottom-left-radius: 300px;
  overflow: hidden;

  @include below(medium) {
    width: 100%;
    max-width: none;
    height: 263px;
    min-height: auto;
    margin-top: 16px;
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 150px;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  object-fit: cover;
  object-position: 80%;
}

.ctaContainer {
  margin-top: 40px;
  a {
    @include below(small) {
      width: 100%;
    }
  }
}
</style>
