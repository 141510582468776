// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroLaunchSimple__heroContainer___1Kkhw{min-height:615px;margin-top:-64px;padding:64px 80px 46px;background-color:#2d50e6}@media (max-width:959px){.HeroLaunchSimple__heroContainer___1Kkhw{padding:64px 24px 24px}}.HeroLaunchSimple__inner___3ulaB{padding-top:56px}@media (max-width:959px){.HeroLaunchSimple__inner___3ulaB{flex-direction:column;grid-gap:unset;gap:unset}}@media (max-width:719px){.HeroLaunchSimple__inner___3ulaB{padding-top:24px}}.HeroLaunchSimple__desc___350fZ{width:100%;text-align:center}.HeroLaunchSimple__breadcrumb___21kin{margin-bottom:16px}.HeroLaunchSimple__title___2UShG{color:inherit;font-weight:500;font-size:48px;line-height:64px}.HeroLaunchSimple__title___2UShG h1{display:inline;margin-bottom:16px;color:#fff}@media (max-width:719px){.HeroLaunchSimple__title___2UShG{font-size:40px;line-height:56px}}.HeroLaunchSimple__tag___lBVYt{display:inline-block;margin-top:10px;padding:8px 16px;border-radius:20px;background-color:#e9b857;transform:translateY(-10px);color:inherit;font-weight:400;font-size:16px;line-height:24px}@media (max-width:719px){.HeroLaunchSimple__tag___lBVYt{display:none}}.HeroLaunchSimple__cobranded___2j_L9{display:flex;grid-gap:8px;gap:8px;align-items:flex-end;margin-top:16px;color:inherit}.HeroLaunchSimple__cobranded___2j_L9,.HeroLaunchSimple__withText___a130l{font-weight:400;font-size:20px;line-height:32px}.HeroLaunchSimple__withText___a130l{color:inherit;color:#829af2}.HeroLaunchSimple__description___8D0Gt p{color:inherit;font-weight:400;font-size:20px;margin-top:16px;color:#d6e3fd;line-height:32px}.HeroLaunchSimple__ctaContainer___2TgH8{margin-top:40px}.HeroLaunchSimple__subDescription___3Owg0{color:inherit;font-weight:400;font-size:16px;line-height:24px;margin-top:16px;color:#829af2}.HeroLaunchSimple__linkList___23cC_{margin-top:40px;color:#fff}.HeroLaunchSimple__linkItem___MH-UW{margin-top:16px}.HeroLaunchSimple__linkItem___MH-UW:first-child{margin-top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "HeroLaunchSimple__heroContainer___1Kkhw",
	"inner": "HeroLaunchSimple__inner___3ulaB",
	"desc": "HeroLaunchSimple__desc___350fZ",
	"breadcrumb": "HeroLaunchSimple__breadcrumb___21kin",
	"title": "HeroLaunchSimple__title___2UShG",
	"tag": "HeroLaunchSimple__tag___lBVYt",
	"cobranded": "HeroLaunchSimple__cobranded___2j_L9",
	"withText": "HeroLaunchSimple__withText___a130l",
	"description": "HeroLaunchSimple__description___8D0Gt",
	"ctaContainer": "HeroLaunchSimple__ctaContainer___2TgH8",
	"subDescription": "HeroLaunchSimple__subDescription___3Owg0",
	"linkList": "HeroLaunchSimple__linkList___23cC_",
	"linkItem": "HeroLaunchSimple__linkItem___MH-UW"
};
module.exports = ___CSS_LOADER_EXPORT___;
