// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrustpilotScore__star___2qHXM{margin-right:4px;transition-duration:0}.TrustpilotScore__animation___2xf-q{transition-timing-function:ease-in;transition-duration:.35s!important;transition-property:width}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"star": "TrustpilotScore__star___2qHXM",
	"animation": "TrustpilotScore__animation___2xf-q"
};
module.exports = ___CSS_LOADER_EXPORT___;
