<template>
  <section id="trustpilot-carousel" v-observe-visibility="carouselAutoSliding" :class="$style.container" data-aos="fade-up">
    <Wrapper :class="$style.inner">
      <div :class="$style.card">
        <div :class="$style.cardHeading">
          <img :class="$style.logo" :src="score.logoImage.lightBackground" />
          <img :class="$style.starImage" :src="score.starImage" />
          <Title :class="$style.title" :title-params="content.title" is-large-title />
          <RichText v-if="content.description" :class="$style.description" :text-params="content.description" />
          <PLink :class="$style.link" is-highlight :link-object="content.ctaLink" color="mint" simple-url="#" @on-click="showIntercom">
            {{ content.ctaText }}
          </PLink>
        </div>

        <div :class="$style.carousel">
          <div :class="$style.carouselHeader">
            <button :class="$style.arrowButton" @click="decrementStep">
              <ArrowLeft height="20px" width="20px" :class="`icon-${leftArrowColor}`" />
            </button>
            <transition-group :name="slideEffect">
              <ProfileThumbnail
                :key="currentStep.consumer.id"
                :class="$style.profilePicture"
                :name="currentStep.consumer.displayName"
                :image="currentStep.consumer.profileImage ? currentStep.consumer.profileImage.href : ''"
              />
            </transition-group>
            <button :class="$style.arrowButton" @click="incrementStep">
              <ArrowRight :class="`icon-${rightArrowColor}`" height="20px" width="20px" />
            </button>
          </div>
          <transition-group :name="slideEffect" tag="div" :class="$style.carouselBody">
            <div :key="currentStep.consumer.id" :class="$style.carouselItem">
              <div :class="$style.content">
                <RichText v-if="Array.isArray(currentStep.text)" :text-params="currentStep.text" />
                <span v-else>
                  {{ currentStep.text }}
                </span>
              </div>
              <div :class="$style.infos">— {{ currentStep.consumer.displayName }} —</div>
            </div>
          </transition-group>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import ArrowRight from '@demain-es/lukomponents/src/icons/ArrowRight.vue'
import ArrowLeft from '@demain-es/lukomponents/src/icons/ArrowLeft.vue'
import score from '@@/script/trustpilot/score.json'

import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'
import ProfileThumbnail from '~/components/ProfileThumbnail'

export default {
  name: 'TrustpilotCarouselDefault',

  components: {
    Title,
    RichText,
    PLink,
    ArrowRight,
    ArrowLeft,
    ProfileThumbnail,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    let items = require(`@@/script/trustpilot/reviews-${this.$i18n.locale}.json`)
    if (this.slice.items?.length) {
      items = this.slice.items.map((item, idx) => {
        item.consumer = {
          id: idx,
          profileImage: {
            href: item.profileImage?.url,
          },
          displayName: item.info[0]?.text,
        }
        return item
      })
    }

    return {
      carouselStep: 0,
      slideEffect: 'slide-right',
      intervalId: null,
      score,
      content: this.slice.primary,
      items,
    }
  },

  computed: {
    leftArrowColor() {
      if (this.carouselStep - 1 < 0) return 'mint-200'

      return 'mint-500'
    },

    rightArrowColor() {
      if (this.carouselStep + 1 === this.items.length) return 'mint-200'

      return 'mint-500'
    },

    currentStep() {
      return this.items[this.carouselStep]
    },
  },

  watch: {
    carouselStep(newStep, preStep) {
      if (newStep > preStep) this.slideEffect = 'slide-left'
      else this.slideEffect = 'slide-right'
      clearInterval(this.intervalId)
      this.intervalId = this.setCarouselAutoSliding(8000)
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
  },

  methods: {
    decrementStep() {
      if (this.carouselStep - 1 < 0) return
      this.carouselStep--
    },

    incrementStep() {
      if (this.carouselStep + 1 === this.items.length) return
      this.carouselStep++
    },

    showIntercom() {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        window.Didomi.notice.show()
      }
    },

    carouselAutoSliding(visible) {
      if (!visible) return clearInterval(this.intervalId)

      this.intervalId = this.setCarouselAutoSliding(8000)
    },

    setCarouselAutoSliding(ms) {
      return setInterval(() => {
        this.carouselStep = (this.carouselStep + 1) % this.items.length
      }, ms)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 80px 0;
  padding: 0 24px;
}

.card {
  padding: 32px 24px;

  border-radius: 8px;
  overflow: hidden;

  background-color: $mint-50;

  @include above(small) {
    padding: 80px 73px;
  }

  @include above(medium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.cardHeading {
  padding-bottom: 64px;

  text-align: center;

  @include above(medium) {
    padding-right: 90px;
    padding-bottom: 0;

    text-align: left;
  }
}

.logo {
  height: 32px;
  margin-right: 10px;
  object-fit: contain;
}
.starImage {
  height: 32px;
}

.title {
  margin-top: 24px;
  p {
    line-height: 48px;

    @include below(small) {
      @include typo-title;
    }
  }
}

.description {
  @include typo-body;
  color: $gray-700;
}

.link {
  display: block;
  margin: 48px auto 0;

  @include above(medium) {
    margin: 48px 0 0;
  }
}

.image {
  display: block;
  justify-self: flex-end;
  width: 100%;
}

.carousel {
  padding-top: 64px;
  border-top: 1px solid $mint-100;

  color: $mint-800;
  text-align: center;

  @include above(medium) {
    padding-top: 0;
    padding-left: 90px;
    border-top: 0;
    border-left: 1px solid $mint-100;
  }
}

.carouselHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowButton {
  padding: 0;
  border: 0;

  background-color: transparent;

  cursor: pointer;
}

.profilePicture {
  width: 80px !important;
  height: 80px !important;
  margin: 0 25px;
  border-radius: 40px;
  @include below(small) {
    width: 48px !important;
    height: 48px !important;
  }
}

.carouselBody {
  position: relative;

  margin-top: 24px;
}

.carouselItem {
  width: 100%;
}

.content {
  line-height: 25px;
}
.infos {
  @include typo-body;
  margin-top: 24px;

  color: $mint-1000;
}
</style>
<style lang="scss" scoped>
/* slide left */
.slide-left-enter {
  transform: translateX(60px);

  opacity: 0;
}
.slide-left-enter-to {
  transform: initial;

  opacity: 1;
}
.slide-left-leave {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.slide-left-leave-to {
  position: absolute;

  transform: translateX(-60px);

  opacity: 0;
}
.slide-left-enter-active {
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;
}
.slide-left-leave-active {
  position: absolute;

  transition: all 0.5s ease-in-out;
}

/* slide right */
.slide-right-enter {
  transform: translateX(-60px);

  opacity: 0;
}
.slide-right-enter-to {
  transform: initial;

  opacity: 1;
}
.slide-right-leave {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.slide-right-leave-to {
  position: absolute;

  transform: translateX(60px);

  opacity: 0;
}
.slide-right-enter-active {
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;
}
.slide-right-leave-active {
  position: absolute;

  transition: all 0.5s ease-in-out;
}
</style>
