const loadedScripts = []
export const loadScript = (url) => {
  const found = loadedScripts.find((script) => script.url === url)

  if (found) {
    return found.getPromise()
  }

  const scriptObject = {
    url,
    promise: null,
    getPromise() {
      if (this.promise) return this.promise

      this.promise = new Promise((resolve) => {
        const script = document.createElement('script')

        script.onload = () => resolve()
        script.src = url
        document.head.appendChild(script)
      })

      return this.promise
    },
  }

  loadedScripts.push(scriptObject)

  return scriptObject.getPromise()
}
