// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HighlightTextDefault__container___24sMu{margin:0 auto;padding:130px 60px}.HighlightTextDefault__inner___3gdhS{display:grid;grid-template-columns:1fr 3fr;grid-gap:40px;gap:40px}@media (max-width:719px){.HighlightTextDefault__inner___3gdhS{grid-template-columns:1fr}}.HighlightTextDefault__title___39re3{color:inherit;font-weight:500;font-size:28px;line-height:40px}.HighlightTextDefault__description___1bUbw{color:#6b6651}.HighlightTextDefault__cta___3MNRz{margin-top:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HighlightTextDefault__container___24sMu",
	"inner": "HighlightTextDefault__inner___3gdhS",
	"title": "HighlightTextDefault__title___39re3",
	"description": "HighlightTextDefault__description___1bUbw",
	"cta": "HighlightTextDefault__cta___3MNRz"
};
module.exports = ___CSS_LOADER_EXPORT___;
