// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoBannerDefault__container___2a0d1{background:#122182}.LogoBannerDefault__inner___3zrx_{display:flex;flex:1;align-items:center;justify-content:center;width:100%;padding:60px 24px}@media (max-width:959px){.LogoBannerDefault__inner___3zrx_{flex-direction:column;text-align:center}}.LogoBannerDefault__logo___2Pg4U{width:118px;margin-right:40px}@media (max-width:959px){.LogoBannerDefault__logo___2Pg4U{width:calc(min(30vw, 84px));margin:0 0 32px}}.LogoBannerDefault__figure___3pqlM{max-width:530px}.LogoBannerDefault__text___3ce65 h2{color:inherit;font-weight:500;font-size:20px;line-height:32px;color:#fff}.LogoBannerDefault__text___3ce65 p{margin-top:10px;color:#d6e3fd;font-weight:400;font-size:16px;line-height:24px}@media (max-width:959px){.LogoBannerDefault__text___3ce65 p{color:#acbef7;font-size:16px;line-height:24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "LogoBannerDefault__container___2a0d1",
	"inner": "LogoBannerDefault__inner___3zrx_",
	"logo": "LogoBannerDefault__logo___2Pg4U",
	"figure": "LogoBannerDefault__figure___3pqlM",
	"text": "LogoBannerDefault__text___3ce65"
};
module.exports = ___CSS_LOADER_EXPORT___;
