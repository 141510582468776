// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdviceCategory2021Q4__heroContainer___1_GZi{min-height:615px;margin-top:-64px;padding:64px 40px 46px;background-color:#fff4ee}@media (max-width:959px){.AdviceCategory2021Q4__heroContainer___1_GZi{padding:64px 24px 24px}}.AdviceCategory2021Q4__breadcrumb___3CM59{margin-bottom:16px}.AdviceCategory2021Q4__inner___13rcB{display:flex;grid-gap:90px;gap:90px;justify-content:space-between;height:100%;padding-top:56px}@media (max-width:1279px){.AdviceCategory2021Q4__inner___13rcB{grid-gap:40px;gap:40px}}@media (max-width:959px){.AdviceCategory2021Q4__inner___13rcB{flex-direction:column;grid-gap:0;gap:0}}@media (max-width:719px){.AdviceCategory2021Q4__inner___13rcB{padding-top:24px}}.AdviceCategory2021Q4__desc___2BWKP{width:50%;max-width:600px}@media (max-width:959px){.AdviceCategory2021Q4__desc___2BWKP{width:100%;max-width:none}}.AdviceCategory2021Q4__title___iMXsH{margin-top:16px;font-weight:200;font-size:48px;line-height:59px}.AdviceCategory2021Q4__title___iMXsH,.AdviceCategory2021Q4__title___iMXsH h1{display:inline}@media (max-width:719px){.AdviceCategory2021Q4__title___iMXsH{font-size:40px;line-height:56px}}.AdviceCategory2021Q4__subtitle___Eb1I5 p{margin-top:16px;color:inherit;font-weight:400;font-size:20px;line-height:32px;color:#6b6651}.AdviceCategory2021Q4__view___1bVeY{width:50%;min-height:430px;max-height:460px;margin:0;border-bottom-right-radius:300px;overflow:hidden}@media (max-width:959px){.AdviceCategory2021Q4__view___1bVeY{width:100%;max-width:none;height:263px;min-height:auto;margin-top:16px;border-bottom-right-radius:150px;border-bottom-left-radius:0}}.AdviceCategory2021Q4__image___2Wt3u{display:block;width:100%;height:100%;margin-bottom:30px;-o-object-fit:cover;object-fit:cover;-o-object-position:80%;object-position:80%}.AdviceCategory2021Q4__ctaContainer___TGwCK{margin-top:40px}@media (max-width:719px){.AdviceCategory2021Q4__ctaContainer___TGwCK a{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "AdviceCategory2021Q4__heroContainer___1_GZi",
	"breadcrumb": "AdviceCategory2021Q4__breadcrumb___3CM59",
	"inner": "AdviceCategory2021Q4__inner___13rcB",
	"desc": "AdviceCategory2021Q4__desc___2BWKP",
	"title": "AdviceCategory2021Q4__title___iMXsH",
	"subtitle": "AdviceCategory2021Q4__subtitle___Eb1I5",
	"view": "AdviceCategory2021Q4__view___1bVeY",
	"image": "AdviceCategory2021Q4__image___2Wt3u",
	"ctaContainer": "AdviceCategory2021Q4__ctaContainer___TGwCK"
};
module.exports = ___CSS_LOADER_EXPORT___;
