var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.container,style:(_vm.sectionStyle),attrs:{"data-luko-tracking":"GivebackSchema"}},[_c('Title',{class:_vm.$style.title,attrs:{"title-params":_vm.primary.title,"is-extra-title":""}}),_vm._v(" "),_c('RichText',{class:_vm.$style.sub_description,attrs:{"text-params":_vm.primary.subDescription}}),_vm._v(" "),(_vm.isAnimated)?[_c('div',{class:[[_vm.$style.medium], ( _obj = {}, _obj[_vm.$style.is_playing] = _vm.isPlaying, _obj )],attrs:{"data-aos":"fade-up"}},[_c('aside',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.visibilityChanged,
          once: false,
        }),expression:"{\n          callback: visibilityChanged,\n          once: false,\n        }"}],class:_vm.$style.videoContainer},[(_vm.primary.animationVideo)?_c('video',{ref:"video-giveback",class:_vm.$style.video,attrs:{"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.primary.animationVideo.url,"type":"video/mp4"}})]):_vm._e()]),_vm._v(" "),_c('ul',{class:_vm.$style.textContainer},[_c('li',{class:_vm.$style.thirtyDescription},[_c('LinearProgressBar',{class:_vm.$style.bar,attrs:{"sec":1.2,"is-active":_vm.currentStep === 0,"callback":_vm.onFinishTimer,"delay":0.6}}),_vm._v(" "),_c('RichText',{class:[
              _vm.$style.description,
              ( _obj$1 = {}, _obj$1[_vm.$style.active] = [0, 3].includes(_vm.currentStep), _obj$1 ) ],attrs:{"text-params":_vm.primary['30Description']}})],1),_vm._v(" "),_c('li',{class:[_vm.$style.seventyDescription]},[_c('LinearProgressBar',{class:_vm.$style.bar,attrs:{"sec":1,"is-active":_vm.currentStep === 1,"callback":_vm.onFinishTimer}}),_vm._v(" "),_c('RichText',{class:[
              _vm.$style.description,
              ( _obj$2 = {}, _obj$2[_vm.$style.active] = [1, 3].includes(_vm.currentStep), _obj$2 ) ],attrs:{"text-params":_vm.primary['70Description']}})],1),_vm._v(" "),_c('li',{class:_vm.$style.remainDescription},[_c('LinearProgressBar',{class:_vm.$style.bar,attrs:{"sec":2,"is-active":_vm.currentStep === 2,"callback":_vm.onFinishTimer}}),_vm._v(" "),_c('RichText',{class:[
              _vm.$style.description,
              ( _obj$3 = {}, _obj$3[_vm.$style.active] = [2, 3].includes(_vm.currentStep), _obj$3 ) ],attrs:{"text-params":_vm.primary.remainingDescription}})],1)])])]:_vm._e(),_vm._v(" "),_c('ul',{class:_vm.$style.small},[_c('li',{attrs:{"data-aos":"fade-up"}},[_c('RichText',{attrs:{"text-params":_vm.primary['30Description']}})],1),_vm._v(" "),_c('li',{attrs:{"data-aos":"fade-up"}},[_c('RichText',{attrs:{"text-params":_vm.primary['70Description']}})],1),_vm._v(" "),_c('li',{attrs:{"data-aos":"fade-up"}},[_c('RichText',{attrs:{"text-params":_vm.primary.remainingDescription}})],1)]),_vm._v(" "),(_vm.primary.ctaText)?_c('Wrapper',{class:_vm.$style.ctaContainer},[_c('ArrowButton',{attrs:{"is-outlined":"","href":_vm.getFormatedLink(_vm.primary.ctaLink),"target":"_blank"}},[_vm._v("\n      "+_vm._s(_vm.primary.ctaText)+"\n    ")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }