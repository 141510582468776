// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinearProgressBar__bar___38Gkc{position:relative;width:3px;max-height:100%;background-color:#dfded9}.LinearProgressBar__gage___Pcm_T{position:absolute;bottom:0;left:0;width:3px;background-color:#2d50e6;transition:height}.LinearProgressBar__counting___3xFCF{-webkit-animation-name:LinearProgressBar__tictoc___35dVm;animation-name:LinearProgressBar__tictoc___35dVm;-webkit-animation-duration:4s;animation-duration:4s;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards}@-webkit-keyframes LinearProgressBar__tictoc___35dVm{0%{height:100%}to{height:0%}}@keyframes LinearProgressBar__tictoc___35dVm{0%{height:100%}to{height:0%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"bar": "LinearProgressBar__bar___38Gkc",
	"gage": "LinearProgressBar__gage___Pcm_T",
	"counting": "LinearProgressBar__counting___3xFCF",
	"tictoc": "LinearProgressBar__tictoc___35dVm"
};
module.exports = ___CSS_LOADER_EXPORT___;
