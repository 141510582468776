// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LkInput__root___2SLZ1{position:relative;text-align:left}.LkInput__input___WHAzB,.LkInput__root___2SLZ1{display:block;width:100%}.LkInput__input___WHAzB{color:inherit;font-weight:400;font-size:16px;line-height:24px;margin:0;padding:10px 16px;border:2px solid #eae9e3;border-radius:8px;color:#2c2302;font-family:inherit;background-color:#fff;outline:none;box-shadow:0 0 0 0 #acbef7;transition-duration:.2s;transition-property:border-color,box-shadow}.LkInput__input___WHAzB:hover{border-color:#c0beb8}.LkInput__input___WHAzB.LkInput__input--success___2vP3_{border-color:#2a8c7c}.LkInput__input___WHAzB.LkInput__input--error___18t1l{border-color:#d95762}.LkInput__input___WHAzB:focus{border-color:#2d50e6;box-shadow:0 0 0 4px #d6e3fd}.LkInput__input___WHAzB:disabled{border-color:#eae9e3;color:#96948a;background-color:#f4f3ef;cursor:not-allowed}input.LkInput__input___WHAzB{height:48px}textarea.LkInput__input___WHAzB{min-height:48px;white-space:pre-wrap;resize:vertical}.LkInput__append___2lOR9{position:absolute;top:12px;right:18px;cursor:pointer}.LkInput__append--full___3_e-1{top:0;right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"root": "LkInput__root___2SLZ1",
	"input": "LkInput__input___WHAzB",
	"input--success": "LkInput__input--success___2vP3_",
	"input--error": "LkInput__input--error___18t1l",
	"append": "LkInput__append___2lOR9",
	"append--full": "LkInput__append--full___3_e-1"
};
module.exports = ___CSS_LOADER_EXPORT___;
