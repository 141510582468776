// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroMobile__container___1P-Pg{max-width:inherit;min-height:780px;margin-top:-64px;padding:0 24px 310px;background-color:#1450e6;background-repeat:no-repeat;background-position:bottom;background-size:contain}.HeroMobile__inner___2eLlW{display:flex;min-height:630px;padding-top:120px}.HeroMobile__desc___3l3WZ{max-width:720px;margin:0 auto}.HeroMobile__title___K3VCQ{max-width:725px;margin-bottom:16px;color:#fff;font-weight:500;font-size:32px;line-height:48px}.HeroMobile__subtitle___2hmkz{max-width:650px;margin:0;color:#d6e3fd}.HeroMobile__subtitle___2hmkz p{font-size:20px;line-height:32px}.HeroMobile__addressBarContainer___3r9Yr,.HeroMobile__trustpilot___12TOU{margin-top:40px}.HeroMobile__trustpilot___12TOU{color:inherit;font-weight:400;font-size:14px;line-height:20px;display:flex;grid-gap:8px;gap:8px;color:#fff}.HeroMobile__trustpilot___12TOU strong{color:#fff}.HeroMobile__tag___2ci9r{color:inherit;font-weight:400;font-size:16px;line-height:24px;display:inline-block;margin-top:10px;padding:8px 16px;border-radius:20px;background-color:#e9b857;transform:translateY(-10px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HeroMobile__container___1P-Pg",
	"inner": "HeroMobile__inner___2eLlW",
	"desc": "HeroMobile__desc___3l3WZ",
	"title": "HeroMobile__title___K3VCQ",
	"subtitle": "HeroMobile__subtitle___2hmkz",
	"addressBarContainer": "HeroMobile__addressBarContainer___3r9Yr",
	"trustpilot": "HeroMobile__trustpilot___12TOU",
	"tag": "HeroMobile__tag___2ci9r"
};
module.exports = ___CSS_LOADER_EXPORT___;
