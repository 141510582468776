<template>
  <form :class="$style.phoneContainer" @submit.prevent="onSubmit">
    <LkPhoneField
      v-model.trim="phone"
      :error.sync="error"
      :error-message="errorMessage"
      :class="$style.phoneField"
      validate
      :locale="locale"
      required
    />
    <LkButton :class="$style.button" :is-light="isLight" :is-loading="isLoading">{{ buttonText }}</LkButton>
  </form>
</template>
<script>
import { delay } from '@fxts/core'

import { COUNTRIES } from '@/utils/constants.js'
import LkPhoneField from '@/components/LkPhoneField'

export default {
  components: {
    LkPhoneField,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: null,
      locale: process.env.CURRENT_DOMAIN,
      errorMessage: '',
      error: false,
      isLoading: false,
    }
  },
  methods: {
    async onSubmit() {
      if (this.error) {
        return (this.errorMessage = this.$t('PhoneSubscription.error'))
      }
      this.errorMessage = ''

      const notification = {
        type: 'success',
        text: this.$t('PhoneSubscription.success'),
      }

      this.isLoading = true
      await delay(1000)

      try {
        window.LukoTracking.trackEvent({
          id: `Launch Page Contact Given`,
          properties: {
            subscriber: {
              phone: this.phone,
              uid: this.$route.params.uid,
              lang: this.$i18n.localeProperties.iso,
              country: COUNTRIES[process.env.CURRENT_DOMAIN],
            },
          },
        })
      } catch (e) {
        console.error(e)
        notification.type = 'danger'
        notification.text = this.$t('PhoneSubscription.fail')
      } finally {
        this.isLoading = false
        this.$store.commit('notification/SET_NOTIFICATION', notification)
      }
    },
  },
}
</script>
<style module lang="scss">
.phoneContainer {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  @include below(small) {
    flex-direction: column;
  }
}
.phoneField {
  @include below(small) {
    max-width: unset;
  }
}

.button {
  flex-grow: 1;
  flex-shrink: 0;
  @include below(small) {
    width: 100%;
  }
}
</style>
