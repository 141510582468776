<template>
  <section data-luko-tracking="RefundSchema">
    <RefundSchemaDefault :slice="slice" />
  </section>
</template>

<script>
import RefundSchemaDefault from './default/RefundSchemaDefault.vue'

export default {
  name: 'RefundSchema',
  components: {
    RefundSchemaDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
