<template>
  <div data-luko-tracking="CtaBox">
    <AddressForm
      v-if="type === 'Address'"
      :placeholder="placeholder"
      :button-text="text"
      :query-params="queryParams"
    />
    <Waitlist
      v-else-if="type === 'Waitlist'"
      :class="$style.waitlist"
      :placeholder="placeholder"
      :text="text"
      :waitlist-id="waitlistId"
    />
    <LkButton
      v-else-if="type === 'Button'"
      :class="$style.button"
      :href="ctaTarget"
      data-cta="calendly"
    >
      {{ text }}
    </LkButton>
  </div>
</template>

<script>
import AddressForm from '~/components/AddressForm'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'

import Waitlist from '../Waitlist'

export default {
  name: 'CtaBox',

  components: {
    AddressForm,
    Waitlist,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    type: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: () => ({}),
    },

    toOnboarding: {
      type: Boolean,
      default: false,
    },

    apps: {
      type: Array,
      default: () => [],
    },

    waitlistId: {
      type: String,
      default: '',
    },
  },

  computed: {
    ctaTarget() {
      if (this.toOnboarding) return this.getOnboardingLink()

      const formatedLink = new URL(this.getFormatedLink(this.link))

      // forwarding query params
      Object.entries(this.$route.query).forEach(([k, v]) => {
        if (!formatedLink.searchParams.has(k)) {
          formatedLink.searchParams.append(k, v)
        }
      })

      return formatedLink.toString()
    },

    queryParams() {
      if (this.link && this.link.url) {
        return Array.from(new URL(this.link.url).searchParams.entries())
      }
      return []
    },
  },
}
</script>

<style lang="scss" module>
.waitlist {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: 100%;

  @include below(xsmall) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
}

.appsContainer {
  display: flex;
  gap: 25px;

  @include below(small) {
    justify-content: center;
  }
}

.appImage {
  border: 1px solid white;
  border-radius: 8px;

  @include below(small) {
    width: 40vw;
    max-width: 152px;
    height: auto;
    min-height: initial;
    max-height: 48px;
  }
}
</style>
