<template>
  <section :class="$style.container">
    <aside :class="$style.contentContainer">
      <Title :class="$style.title" :title-params="primary.title" />
      <RichText v-if="primary.description" :class="$style.description" :text-params="primary.description" />
      <div :class="$style.ctaContainer">
        <ArrowButton is-outlined :href="getFormatedLink(primary.ctaLink)" :target="primary.ctaLink.target" :class="$style.buttonCta" is-white>
          {{ primary.ctaText }}
        </ArrowButton>
      </div>
    </aside>
    <div :class="$style.cardContainer">
      <div
        ref="carousel"
        :class="[$style.carousel]"
        @mouseenter="onMouseenter"
        @mouseleave="onMouseleave"
        @mousemove="onMousemove"
        @mousedown="onMousedown"
        @click="onClick"
        @touchstart="onTouchstart"
        @touchmove="onTouchmove"
        @touchend="onTouchend"
      >
        <!-- card group 1-->
        <div v-for="(item, index) in items" :key="'card' + index" :class="$style.card">
          <TrustpilotScore :score="item.stars" :class="$style.score" />
          <div :class="$style.cardContent">
            {{ item.text }}
          </div>
          <div :class="$style.cardInfo">
            <ProfileThumbnail
              :image="item.consumer.profileImage ? item.consumer.profileImage.href : ''"
              :name="item.consumer.displayName"
              :class="$style.profileImage"
              :size="32"
            />
            <div>
              <div :class="$style.displayName">
                {{ item.consumer.displayName }}
              </div>
              <div :class="$style.detail">
                {{ item.createdAt }}
              </div>
            </div>
          </div>
        </div>
        <!-- card group 2-->
        <div v-for="(item, index) in items" :key="'card-second' + index" :class="[$style.card]">
          <TrustpilotScore :score="item.stars" :class="$style.score" />
          <div :class="$style.cardContent">
            {{ item.text }}
          </div>
          <div :class="$style.cardInfo">
            <ProfileThumbnail
              :image="item.consumer.profileImage ? item.consumer.profileImage.href : ''"
              :name="item.consumer.displayName"
              :class="$style.profileImage"
              :size="32"
            />
            <div>
              <div :class="$style.displayName">
                {{ item.consumer.displayName }}
              </div>
              <div :class="$style.detail">
                {{ item.createdAt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import score from '@@/script/trustpilot/score.json'
import { take, filter, toArray, pipe, map } from '@fxts/core'

import ArrowButton from '~/components/ArrowButton'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import ProfileThumbnail from '~/components/ProfileThumbnail'
import TrustpilotScore from '~/components/TrustpilotScore'

export default {
  name: 'TrustpilotCarousel2021Q4',
  components: {
    Title,
    RichText,
    ProfileThumbnail,
    TrustpilotScore,
    ArrowButton,
  },
  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const items = require(`@@/script/trustpilot/reviews-${this.$i18n.locale}.json`)

    return {
      score,
      primary: this.slice.primary,
      items: pipe(
        items,
        filter((i) => i.text.length < 200),
        map((i) => ({
          ...i,
          createdAt: new Date(i.createdAt).toLocaleDateString(this.$i18n.localeProperties.prismicIso),
        })),
        take(5),
        toArray
      ),
      isTouchDevice: false,
      startDrag: false,
      startX: null,
      animationID: null,
      speed: -1,
      timeStamp: null,
    }
  },
  mounted() {
    this.startAnimation()
  },
  methods: {
    startAnimation() {
      this.animationID = requestAnimationFrame(this.loop)
    },
    stopAnimation() {
      this.animationID = cancelAnimationFrame(this.animationID)
    },
    loop() {
      if (!this.animationID) return
      this.moveCarousel()
      this.animationID = requestAnimationFrame(this.loop)
    },
    moveCarousel() {
      const el = this.$refs.carousel
      const currentTranslateX = parseInt(window.getComputedStyle(el).transform.split(',')[4])
      let newTranslateX = (currentTranslateX + this.speed) % (340 * 5)
      if (newTranslateX > 0) {
        newTranslateX = 0
        this.speed = -1
      }

      el.style.transform = `translateX(${newTranslateX}px)`

      // Friction when user scroll right
      if (this.speed > 0) return (this.speed -= 0.2)

      // Friction when user scroll left
      if (this.speed < 0) {
        this.speed += 0.2
        if (this.speed > -1) return (this.speed = -1)
      }
    },
    onMouseenter() {
      if (this.isTouchDevice) return
      this.speed = 0
    },
    onMouseleave() {
      if (this.isTouchDevice) return
      this.startDrag = false

      if (this.speed === 0) this.speed = -1
    },
    onMousemove(e) {
      if (this.isTouchDevice) return
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.pageX
    },
    onMousedown(e) {
      if (this.isTouchDevice) return
      this.startDrag = true
      this.startX = e.pageX
      this.timeStamp = e.timeStamp
    },
    onClick() {
      if (this.isTouchDevice) return
      this.startDrag = false
    },
    onTouchstart(e) {
      this.isTouchDevice = true
      this.startDrag = true
      this.startX = e.targetTouches[0].pageX
      this.timeStamp = e.timeStamp
      this.speed = 0
    },
    onTouchend() {
      this.isTouchDevice = true
      this.startDrag = false
      if (this.speed === 0) this.speed = -1
    },
    onTouchmove(e) {
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.targetTouches[0].pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.targetTouches[0].pageX
    },
  },
}
</script>

<style lang="scss" module>
.container {
  position: relative;

  min-height: 500px;
  margin: 0;

  background-color: #ffede3;

  @include below(medium) {
    background-color: #122182;
  }
}
.contentContainer {
  position: absolute;
  left: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  height: 100%;
  padding: 100px 80px;

  color: white;

  background-color: #122182;

  @include below(medium) {
    position: static;

    max-width: none;
    padding: 48px 24px 0;
  }
}

.title p {
  color: white;
  font-weight: 200;
  font-size: 42px;
  line-height: 54px;
  @include below(medium) {
    text-align: center;
  }
  @include below(small) {
    font-size: 32px;
    line-height: 42px;
  }
}
.description {
  @include typo-body;
  margin-top: 32px;

  color: white;
  @include below(medium) {
    display: none;
  }
}
.ctaContainer {
  margin-top: 60px;

  @include below(medium) {
    margin-top: 42px;

    text-align: center;
  }
  @include below(small) {
    margin-top: 34px;
  }
}
.buttonCta {
  @include below(small) {
    width: 100%;
  }
}

.cardContainer {
  height: 100%;
  padding: 100px 0;
  overflow-x: hidden;
  @include below(medium) {
    padding: 82px 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--carousel-item-width);
  min-height: 380px;
  margin-left: 40px;
  padding: 24px;
  border-radius: 8px;

  background-color: white;
  cursor: pointer;

  user-select: none;
}
.cardContent {
  font-size: 20px;
  line-height: 30px;
}
.carousel {
  --carousel-items: 5;
  --carousel-item-width: 300px;

  display: flex;
  width: calc((var(--carousel-item-width) + 40px) * var(--carousel-items) * 2);

  transform: translateX(0);
}

.cardInfo {
  display: flex;
  align-items: center;
}
.displayName {
  margin-left: 10px;

  color: $gray-800;
  font-size: 12px;
  line-height: 16px;
}
.detail {
  margin-left: 10px;

  color: $gray-500;
  font-size: 12px;
  line-height: 16px;
}
.score {
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
