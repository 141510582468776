<template>
  <div v-observe-visibility="isVisible" data-luko-tracking="Hero">
    <HeroHome2021Q4 v-if="slice.variation === 'home2021Q4'" :slice="slice" />
    <HeroProduct2021Q4 v-else-if="slice.variation === 'product2021Q4'" :slice="slice" />
    <HeroPartner2021Q4 v-else-if="slice.variation === 'partner2021Q4'" :slice="slice" />
    <HeroTestimonials2021Q4 v-else-if="slice.variation === 'testimonials2021Q4'" :slice="slice" />
    <HeroConfirm2021Q4 v-else-if="slice.variation === 'confirm2021Q4'" :slice="slice" />
    <HeroPressHub2021Q4 v-else-if="slice.variation === 'pressHub2021Q4'" :slice="slice" />
    <HeroAdviceHub2021Q4 v-else-if="slice.variation === 'adviceHub2021Q4'" :slice="slice" />
    <HeroAdviceCategory2021Q4
      v-else-if="slice.variation === 'adviceCategory2021Q4'"
      :slice="slice"
      :category-name="categoryName"
      :category-uid="categoryUid"
    />
    <HeroDefault v-else :slice="slice" />
  </div>
</template>

<script>
import HeroDefault from './default-slice'
import HeroHome2021Q4 from './home2021Q4'
import HeroProduct2021Q4 from './product2021Q4'
import HeroPartner2021Q4 from './partner2021Q4'
import HeroTestimonials2021Q4 from './testimonials2021Q4'
import HeroConfirm2021Q4 from './confirm2021Q4'
import HeroPressHub2021Q4 from './pressHub2021Q4'
import HeroAdviceHub2021Q4 from './adviceHub2021Q4'
import HeroAdviceCategory2021Q4 from './adviceCategory2021Q4'

export default {
  name: 'Hero',
  components: {
    HeroDefault,
    HeroHome2021Q4,
    HeroProduct2021Q4,
    HeroPartner2021Q4,
    HeroTestimonials2021Q4,
    HeroConfirm2021Q4,
    HeroPressHub2021Q4,
    HeroAdviceHub2021Q4,
    HeroAdviceCategory2021Q4,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
    categoryName: {
      type: String,
      default: '',
    },
    categoryUid: {
      type: String,
      default: '',
    },
  },
  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>
