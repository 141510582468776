<template>
  <section :class="$style.container">
    <div
      v-if="primary.image && primary.image.url"
      :class="$style.imageContainer"
      :style="backgroundStyles(primary.image.url, 'lg:100vw')"
    ></div>
    <div :class="$style.contentContainer">
      <div :class="$style.content">
        <Title :title-params="primary.title" :class="$style.title" />
        <RichText
          :text-params="primary.description"
          :class="$style.description"
        />
        <div :class="$style.ctaContainer">
          <PLink :link-object="primary.ctaAppStore">
            <PImage
              :image-object="primary.imageAppStore"
              sizes="xs:120px"
              :class="$style.appIcon"
            />
          </PLink>
          <PLink :link-object="primary.ctaGooglePlay">
            <PImage
              :image-object="primary.imageGooglePlay"
              sizes="xs:120px"
              :class="$style.appIcon"
            />
          </PLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundStyles } from '~/mixins/backgroundStyles'
import PLink from '~/components/PLink'

export default {
  name: 'AppBlockDefault',
  components: {
    PLink,
  },
  mixins: [backgroundStyles],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  position: relative;

  display: flex;
  align-items: stretch;
  height: 544px;

  @include below(large) {
    height: 750px;
  }
  @include below(medium) {
    flex-direction: column;
  }
}
.contentContainer {
  display: flex;
  flex: 1;
  align-items: center;

  background-color: $bluko-800;

  @include below(medium) {
    width: 100%;
    padding: 60px 0px;

    text-align: center;
  }
}
.content {
  padding: 0 65px;
  @include below(medium) {
    width: 100%;
    padding: 0 32px;
  }
}

.imageContainer {
  flex: 1;
  height: 100%;

  background-color: #2864da;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include below(medium) {
    flex: initial;
    width: 100%;
    height: 600px;
  }
  @include below(small) {
    height: 350px;
  }
}

.title {
  color: white;
  font-weight: 200;
  font-size: 48px;
  line-height: 55px;

  @include below(small) {
    font-size: 32px;
    line-height: 42px;
  }
}

.description {
  color: $bluko-200;
}
.ctaContainer {
  margin-top: 48px;
}
.appIcon {
  height: 40px;
  &:last-of-type {
    margin-left: 8px;
  }
  @include below(small) {
    height: calc(min(40px, 11vw));
  }
}
</style>
