<template>
  <form @submit.prevent="onSubmit">
    <LkEmailField
      v-if="isValidWaitlistId"
      v-model="email"
      :placeholder="placeholder"
      :class="$style.emailField"
      :error="errorMessage"
      @error="onEmailError"
    />
    <LkButton
      v-if="isValidWaitlistId"
      :class="$style.button"
      :is-loading="isLoading"
      type="submit"
    >
      {{ text }}
    </LkButton>
    <span v-else-if="!isValidWaitlistId" :class="$style.warning">
      WaitlistID should be `Waitlist XXX` format
    </span>
    <span v-else :class="$style.warning">
      WaitlistID is missing, please fill this field in prismic
    </span>
  </form>
</template>
<script>
import { delay } from '@fxts/core'

import LkEmailField from '~/components/LkEmailField'
export default {
  components: {
    LkEmailField,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    waitlistId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasError: false,
      errorMessage: '',
      email: '',
      isLoading: false,
    }
  },
  computed: {
    isValidWaitlistId() {
      return !!this.waitlistId.match(/Waitlist .+/)
    },
  },
  methods: {
    onEmailError(hasError) {
      this.hasError = hasError
    },
    onSubmit() {
      if (this.hasError || !this.email)
        return (this.errorMessage = this.$t('Error.invalidEmail'))
      this.errorMessage = ''

      this.sendWaitlistToBraze(this.email, this.waitlistId)
    },
    async sendWaitlistToBraze(email, waitlistId) {
      const notification = {
        type: 'success',
        text: this.$t('Waitlist.success'),
      }
      try {
        this.isLoading = true
        await delay(1000)

        window.LukoTracking.trackEvent({
          id: waitlistId,
          properties: { subscriber: { email } },
        })
      } catch (_) {
        notification.type = 'danger'
        notification.text = this.$t('Waitlist.fail')
      } finally {
        this.isLoading = false
        this.$store.commit('notification/SET_NOTIFICATION', notification)
      }
    },
  },
}
</script>
<style lang="scss" module>
.warning {
  @include typo-headline-bold;

  color: red;
}
</style>
