<template>
  <div>
    <HighlightSectionDarkblue
      v-if="slice.variation === 'darkblue'"
      :slice="slice"
    />
    <HighlightSectionDefault v-else :slice="slice" />
  </div>
</template>

<script>
import HighlightSectionDefault from './default-slice/'
import HighlightSectionDarkblue from './darkblue'

export default {
  name: 'HighlightSection',
  components: {
    HighlightSectionDefault,
    HighlightSectionDarkblue,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
