<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="primary.title" :class="$style.title" />
      <RichText :text-params="primary.description" :class="$style.description" />
      <div :class="$style.buttonContainer">
        <ArrowButton
          :class="$style.button"
          :is-outlined="primary.isOutlinedButton"
          :is-arrow="primary.isArrowButton"
          :href="getFormatedLink(primary.ctaLink)"
        >
          {{ primary.ctaText }}
        </ArrowButton>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import ArrowButton from '~/components/ArrowButton/ArrowButton.vue'
export default {
  name: 'SimpleCtaWhite',
  components: { ArrowButton },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 128px 32px;

  background-color: white;
  @include below(small) {
    padding: 80px 24px;
  }
}
.title p {
  max-width: 670px;
  margin: 0 auto;

  font-weight: 200;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  @include below(small) {
    font-size: 28px;
    line-height: 34px;
  }
}
.description {
  margin-top: 15px;

  text-align: center;
}
.buttonContainer {
  text-align: center;
}
.button {
  margin: 52px 0 0;
  @include below(small) {
    width: 100%;
  }
}
</style>
