// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaCarouselMobile__container___3prxH{justify-content:center;padding:60px 20px 80px}.MediaCarouselMobile__inner___1w3j5{display:flex;flex-direction:column}.MediaCarouselMobile__imageContainer___1trt2{position:relative;width:100%;height:calc(min(300px, 40vw));text-align:center}.MediaCarouselMobile__imageFront___2z4EP{z-index:2}.MediaCarouselMobile__imageBack___15UFP,.MediaCarouselMobile__imageFront___2z4EP{position:absolute;width:calc(min(300px, 40vw));height:calc(min(300px, 40vw));transform:translateX(-50%)}.MediaCarouselMobile__description___3Ud30{max-width:400px;padding-top:40px;text-align:center}.MediaCarouselMobile__description___3Ud30 h3{font-size:20px;line-height:28px}.MediaCarouselMobile__description___3Ud30 p{color:#6b6651;font-size:16px;line-height:24px}.MediaCarouselMobile__item___1vnIE{display:flex;flex-direction:column;align-items:center;margin-bottom:40px}.MediaCarouselMobile__item___1vnIE:last-child{margin-bottom:0}.MediaCarouselMobile__ctaContainer___P87Nx{display:flex;justify-content:center;padding-top:60px}@media (max-width:719px){.MediaCarouselMobile__ctaButton___2V-__{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "MediaCarouselMobile__container___3prxH",
	"inner": "MediaCarouselMobile__inner___1w3j5",
	"imageContainer": "MediaCarouselMobile__imageContainer___1trt2",
	"imageFront": "MediaCarouselMobile__imageFront___2z4EP",
	"imageBack": "MediaCarouselMobile__imageBack___15UFP",
	"description": "MediaCarouselMobile__description___3Ud30",
	"item": "MediaCarouselMobile__item___1vnIE",
	"ctaContainer": "MediaCarouselMobile__ctaContainer___P87Nx",
	"ctaButton": "MediaCarouselMobile__ctaButton___2V-__"
};
module.exports = ___CSS_LOADER_EXPORT___;
