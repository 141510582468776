<template>
  <section
    v-if="items.length"
    :class="$style.container"
    :style="{ backgroundColor: primary.backgroundColor || '#2d50e5' }"
  >
    <Wrapper :class="$style.inner">
      <PImage
        v-for="(item, index) in items"
        :key="index"
        :image-object="item.logo"
        :class="$style.image"
      />
    </Wrapper>
  </section>
</template>

<script>
import PImage from '~/components/PImage'

export default {
  name: 'LogoList',

  components: {
    PImage,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  display: none;

  background-color: $bluko-500;

  @include above(small) {
    display: block;
  }
}

.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 75px;
  justify-content: center;
  margin: 0 auto;
  padding: 8px 24px;
}

.image {
  height: 64px;
  object-fit: contain;
}
</style>
