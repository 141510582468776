<template>
  <div>
    <ul
      :class="[$style.path, { [$style.isDark]: isDark }]"
      data-luko-tracking="Breadcrumb"
    >
      <li v-for="(crumb, crumbIndex) in crumbs" :key="crumbIndex">
        <a :href="crumb.url">{{ crumb.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { pipe, map, filter, toArray } from '@fxts/core'
const GUIDE_STRINGS = ['guide', 'guia', 'tipps']

export default {
  name: 'Breadcrumb',
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: this.crumbs
        .map(({ name, url }, index) => {
          return {
            '@type': 'ListItem',
            position: index,
            name,
            item: url,
          }
        })
        .filter((e, index) => e && index),
    }
  },
  props: {
    categoryUid: {
      type: String,
      default: '',
    },
    excludedPath: {
      type: String,
      default: '',
    },
    customName: {
      type: String,
      default: '',
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      categoryName:
        Object.values(this.$store.state.adviceCategoryData).find(
          (c) => c.uid === this.categoryUid
        )?.category_name || '',
    }
  },

  computed: {
    crumbs() {
      const defaultLocale = process.env.CURRENT_DOMAIN
      const locale = this.$i18n.locale
      const params = this.$route.path.split('/').filter((e) => e)
      if (params[0] !== locale) params.unshift(locale)

      let path = '/'
      const crumbs = params.map((param, idx) => {
        path += `${param}/`
        if (path.startsWith(`/${defaultLocale}/`)) {
          path = path.replace(`/${defaultLocale}/`, '/')
        }

        let name = param === locale ? this.$t('home') : param

        if (idx === params.length - 1 && this.customName) {
          name = this.customName
        }

        return {
          name: GUIDE_STRINGS.includes(name) ? this.categoryName : name,
          url: process.env.URL + path.replace(/\/\//g, '/'),
        }
      })

      return pipe(
        crumbs,
        map((crumb) => ({
          name: crumb.name.replace(this.categoryUid, this.categoryName),
          url: crumb.url.replace(
            new RegExp(
              `(${GUIDE_STRINGS.map((str) => `/${str}/`).join('|')})$`
            ),
            `/${this.categoryUid}/`
          ),
        })),
        map((crumb) => ({
          ...crumb,
          name: crumb.name.charAt(0).toUpperCase() + crumb.name.slice(1),
        })),
        filter((crumb) => !crumb.url.endsWith('/release/')),
        filter((crumb) => !crumb.url.endsWith('/article/')),
        filter((crumb) => !crumb.url.includes('/p/')),
        toArray
      )
    },
  },

  methods: {
    formatPathName(pathName) {
      if (pathName === this.$i18n.locale) return this.$t('home')
      if (GUIDE_STRINGS.includes(pathName)) {
        return Object.values(this.$store.state.adviceCategoryData).find(
          (c) => c.uid === this.categoryUid
        ).category_name
      }
      return pathName
    },
  },
}
</script>

<style lang="scss" module>
.path {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;

  li {
    position: relative;

    margin-left: 4px;

    @include typo-body;

    &:not(:last-child)::after {
      color: $gray-700;

      content: ' /';
    }

    a {
      color: $gray-700;

      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child a {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;

      color: $gray-1000;
      font-weight: 500;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }
  }
}

.isDark {
  li {
    &:not(:last-child)::after {
      color: $bluko-300;
    }

    a {
      color: $bluko-300;
    }

    &:last-child a {
      color: white;
    }
  }
}
</style>
