<template>
  <div>
    <AppBlockDefault :slice="slice" />
  </div>
</template>

<script>
import AppBlockDefault from './default-slice'

export default {
  name: 'AppBlock',
  components: {
    AppBlockDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
