// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___29I02 .CtaBox__emailField___1l1cQ input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___29I02 .CtaBox__emailField___1l1cQ input::-moz-placeholder{color:#fff}.CtaBox__isDark___29I02 .CtaBox__emailField___1l1cQ input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___29I02 .CtaBox__emailField___1l1cQ input::placeholder{color:#fff}.CtaBox__isDark___29I02 .CtaBox__emailField___1l1cQ input:hover{border-color:#829af2}.CtaBox__isDark___29I02 .CtaBox__emailField___1l1cQ input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___PJuMT{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___PJuMT .CtaBox__button___3aYt-{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___PJuMT{flex-direction:column;max-width:300px}.CtaBox__emailContainer___PJuMT .CtaBox__emailField___1l1cQ{width:100%}}.CtaBox__appsContainer___eLU6w{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___eLU6w{justify-content:center}}.CtaBox__appImage___1VS6Q{border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___1VS6Q{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___29I02",
	"emailField": "CtaBox__emailField___1l1cQ",
	"emailContainer": "CtaBox__emailContainer___PJuMT",
	"button": "CtaBox__button___3aYt-",
	"appsContainer": "CtaBox__appsContainer___eLU6w",
	"appImage": "CtaBox__appImage___1VS6Q"
};
module.exports = ___CSS_LOADER_EXPORT___;
